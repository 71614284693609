import { API } from "../../Services";

export const units = (query='') => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_UNITS",
    });

    API.units(query)
      .then((res) => {
        console.log(res);
        let data = JSON.parse(JSON.stringify(res)).data.units;

        data.map((product) => {
          product.value = product.ID;
          product.label = `${product.name}`;
        });
        setTimeout(() => {
          dispatch({
            type: "RECEIVE_UNITS",
            payload: data,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: "ERROR_UNITS",
        });
      });
  };
};
