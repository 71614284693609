import React, { useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import "./CustomOrderDetails.scss";
import { actionCreators } from "../../../Redux";
import { Loader } from "../../../Components";
import { Card, Tag, Input, Button } from "antd";
import { Approver } from "../";
import { Requestor } from "../Requestor";

const CustomOrderDetails = (props) => {
  const { TextArea } = Input;
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const history = useHistory();
  const { customOrder } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    customOrder(id);
  }, []);

  const getProductName = () => {
    let pname = "";
    state.products2.products2.map((product, productIndex) => {
      if (product.id === state.customOrder.customOrder?.productID) {
        pname = product.name;
      }
    });
    return pname;
  };

  const checkApproverOrRequestor = () => {
    let status = "";
    let isApprover = Number(JSON.parse(localStorage.getItem("adminDetails")).adminId) ===
    state.customOrder.customOrder?.approver?.Admin_id 
    let isRequester = Number(JSON.parse(localStorage.getItem("adminDetails")).adminId) ===
    state.customOrder.customOrder?.createdBy?.Admin_id
   
    if(isApprover && isRequester )
    {
      status = state.customOrder.customOrder?.orderStatus !== 'approved'?"approver":"requestor";
    }else if(isApprover)
    {
      status = "approver";
    }
    else if(isRequester)
    {
      status = "requestor";
    }
    return status;
  };

  return state.customOrder.loading && state.customOrder.customOrder == null ? (
    <Loader />
  ) : (
    <div className="ap-flex ap-flex-center-center ap-w100">
      <Card
        size="small"
        style={{ width: "100%" }}
        title={
          <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
            <div className="ap-flex">
              <span>Custom Order Details</span>
              <span style={{ marginLeft: "10px" }}>
                <Tag color={" var( --primary-green)"}>
                  {[
                    state.customOrder.customOrder?.approver?.Admin_id,
                    state.customOrder.customOrder?.createdBy?.Admin_id,
                  ].every(
                    (val, i, arr) =>
                      val ===
                      Number(
                        JSON.parse(localStorage.getItem("adminDetails")).adminId
                      )
                  )
                    ? "Approver and Requestor"
                    : Number(
                        JSON.parse(localStorage.getItem("adminDetails")).adminId
                      ) === state.customOrder.customOrder?.approver?.Admin_id
                    ? "Approver"
                    : "Requestor"}
                </Tag>
              </span>
            </div>
            <span
              className="ap-fesm ap-fwmd"
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              {"<< go back"}
            </span>
          </div>
        }
      >
        <div className="ap-flex ap-w100">
          <div className="ap-flex  ap-w50">
            <Card size="small" style={{ width: "98%" }}>
              <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Order Id
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {state.customOrder.customOrder?.orderid}
                </div>
              </div>
              {state.customOrder.customOrder?.genrateOrder?.orderCode && (
                <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                  <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                    Order Code
                  </div>
                  <div className="ap-flex ap-flex-center-center ap-fsm">
                    {state.customOrder.customOrder?.genrateOrder?.orderCode}
                  </div>
                </div>
              )}
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Project Code
                </div>
                <Link
                  className="ap-flex ap-flex-center-center ap-fsm"
                  to={`/home/ap/project/details/${state.customOrder.customOrder?.project_id}/${state.customOrder.customOrder?.customer?.id}/${state.customOrder.customOrder?.projectCode}`}
                  style={{ textDecoration: "underline" }}
                >
                  {state.customOrder.customOrder?.projectCode}
                </Link>
              </div>
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Created At
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {state.customOrder.customOrder?.orderat}
                </div>
              </div>
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Customer
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {state.customOrder.customOrder?.customer?.firstName}{" "}
                  {state.customOrder.customOrder?.customer?.lastName}
                </div>
              </div>
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Customer Contact
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  +{state.customOrder.customOrder?.customer?.isd_code}{" "}
                  {state.customOrder.customOrder?.customer?.phoneNumber}
                </div>
              </div>
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Customer Email
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {state.customOrder.customOrder?.customer?.email}
                </div>
              </div>
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Requestor
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  {state.customOrder.customOrder?.createdBy?.name}{" "}
                  {state.customOrder.customOrder?.createdBy?.lastname}
                </div>
              </div>
              {/* <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                                        <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">Estimated Budget</div>
                                        <div className="ap-flex ap-flex-center-center ap-fsm">{state.customOrder.customOrder?.estimatedBudget}</div>
                                    </div> */}
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Status
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  <Tag
                    color={
                      state.customOrder.customOrder?.orderStatus === "approved"
                        ? " var( --primary-green)"
                        : state.customOrder.customOrder?.orderStatus ===
                          "cancelled"
                        ? "red"
                        : "yellow"
                    }
                  >
                    <div className="ap-fsm">
                      {state.customOrder.customOrder?.orderStatus ===
                      "cancelled"
                        ? "declined"
                        : state.customOrder.customOrder?.orderStatus}
                    </div>
                  </Tag>
                </div>
              </div>
              <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                  Order Generated
                </div>
                <div className="ap-flex ap-flex-center-center ap-fsm">
                  <Tag
                    color={
                      state.customOrder.customOrder?.ispublish === 1
                        ? " var( --primary-green)"
                        : "yellow"
                    }
                  >
                    <div className="ap-fsm">
                      {state.customOrder.customOrder?.ispublish === 1
                        ? "Yes"
                        : "No"}
                    </div>
                  </Tag>
                </div>
              </div>
              {state.customOrder.customOrder?.ispublish === 1 && (
                <div className="ap-flex ap-w100 ap-flex-end ap-p5">
                  <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                    <Button
                      type="primary"
                      className="ap-btn-c"
                      shape="round"
                      size="large"
                      onClick={() => {
                        localStorage.setItem(
                          "orderid",
                          state.customOrder.customOrder?.genrateOrder?.orderCode
                        );
                        history.push(`/home/ap/order/details/${state.customOrder.customOrder?.genrateOrder?.orderCode}`);
                      }}
                    >
                      <span className="ap-fsm ap-fwmd">
                        Go to Order Details
                      </span>
                    </Button>
                  </div>
                </div>
              )}
            </Card>
          </div>
          <div className="ap-flex ap-flex-end ap-w50">
            <Card size="small" style={{ width: "98%" }}>
              {/* <div className="ap-flex ap-w100 ap-flex-center-center  ap-p5">
                                        <span className="ap-w100 ap-fsm ap-fwmd" style={{marginBottom:"5px"}}>Contract Details</span>
                                        <TextArea rows={4} value={state.customOrder.customOrder?.contractDetails} readOnly/>  
                                    </div> */}
              <div className="ap-flex ap-w100 ap-flex-center-center ap-p5">
                <span
                  className="ap-w100 ap-fsm ap-fwmd"
                  style={{ marginBottom: "5px" }}
                >
                  {Number(
                    JSON.parse(localStorage.getItem("adminDetails")).adminId
                  ) === state.customOrder.customOrder?.approver?.Admin_id
                    ? "Message"
                    : "Message for approver"}
                </span>
                <TextArea
                  autoSize={{ minRows: 4 }}
                  value={state.customOrder.customOrder?.messageForApprover}
                  readOnly
                />
              </div>
              {["approved", "cancelled"].includes(
                state.customOrder.customOrder?.orderStatus
              ) && (
                <div className="ap-flex ap-w100 ap-flex-center-center  ap-p5">
                  <span
                    className="ap-w100 ap-fsm ap-fwmd"
                    style={{ marginBottom: "5px" }}
                  >
                    Remark
                  </span>
                  <TextArea
                    autoSize={{ minRows: 4 }}
                    value={state.customOrder.customOrder?.approverRemark}
                    readOnly
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
      </Card>
      {checkApproverOrRequestor() === "approver" ? (
        <Approver />
      ) : (
        checkApproverOrRequestor() === "requestor" && <Requestor />
      )}
    </div>
  );
};

export default CustomOrderDetails;
