import React, { useEffect, useState } from "react";
import "../../Styles/Master.scss";
import { Card, Select, Spin, Button, Table, Tag, Space, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import { Icons } from "../../Utils";
import { useHistory } from "react-router-dom";
import {
  ModalHeader,
  ModalBody,
  Grid,
  GridItem,
  CloseButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import AddNewSubCategory from "./AddNewSubCategory";
const AttachmentCategoryList = () => {
  const { Option } = Select;
  const state = useSelector((state) => state);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const params = new URLSearchParams(window.location.search);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { projectAttachementCategories, projectAttachementSubCategories } =
    bindActionCreators(actionCreators, dispatch);

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Sub Category Id</div>,
      dataIndex: "subCategoryId",
      key: "subCategoryId",
      width: "90px",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Sub Category Name</div>,
      dataIndex: "subCategoryName",
      key: "subCategoryName",
      render: (text) => <div className="ap-fsm">{text}</div>,
      width: "90px",
      // fixed:"left",
    },
    {
      title: <div className="ap-fsm ap-fwmd">Category Name</div>,
      dataIndex: "Category",
      key: "Category",
      width: "90px",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    projectAttachementCategories();
    projectAttachementSubCategories();
  }, []);
  // useEffect(() => {
  //     console.log("formData?.orderID", formData?.category);
  //     if (formData?.category) {
  //       projectAttachementSubCategories(formData?.category);
  //     }
  //   }, [formData?.category]);
  const handelFormData = (event) => {
    setFormData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));

      prev[`${event.target.name}`] = event.target.value;

      return prev;
    });
  };
  useEffect(() => {
    if (state.projectAttachementSubCategories?.subCategories.length > 0) {
      setTableData((prev) => {
        prev = [];
        state.projectAttachementSubCategories.subCategories.map(
          (cat, adminIndex) => {
            prev.push({
              key: cat.ID,
              subCategoryId: cat.ID,
              subCategoryName: cat.name,
              subCategoryParentId: cat.parentId,
              Category: cat.parentName,
            });
          }
        );
        return prev;
      });
    } else {
      setTableData([]);
    }
  }, [state]);

  const callRecords = (event) => {
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      if (formData?.category) {
        projectAttachementSubCategories(
          `${formData?.category}?page=${current?.page}&limit=${current?.size}`
        );
      }
    }
  }, [current]);

  return (
    <>
      <div className="ap-flex ap-flex-center-center ap-w100">
        <div className="ap-flex ap-w100">
          <Card style={{ width: "100%" }}>
            <div className="ap-flex ap-w100">
              <div className="ap-flex ap-flex-center-center ap-w33">
                <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Category</div>

                <Select
                  size="large"
                  showSearch
                  value={formData?.category}
                  labelInValue
                  placeholder="Select Category"
                  style={{ width: "100%" }}
                  loading={state.projectAttachementCategories.loading}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(data) =>
                    handelFormData({
                      target: {
                        name: "category",
                        ...data,
                      },
                    })
                  }
                >
                  {state.projectAttachementCategories?.categories?.map(
                    (cat, adminIndex) => (
                      <Option value={cat.ID} key={adminIndex}>
                        {cat.name}
                      </Option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
              <div className="ap-flex ap-flex-center-center search-btn">
                <Button
                  type="primary"
                  className="ap-btn-c"
                  shape="round"
                  loading={
                    state.projectAttachementSubCategories.loading ? (
                      <Spin />
                    ) : (
                      false
                    )
                  }
                  disabled={Object.values(formData).length === 0}
                  icon={<Icons.Search />}
                  size="middle"
                  onClick={() => {
                    callRecords({ payload: { page: 1, size: 10 } });
                  }}
                >
                  <span className="ap-fesm ap-fwmd">Search</span>
                </Button>
              </div>
              <div
                className="ap-flex ap-flex-center-center search-btn"
                style={{ marginLeft: "10px" }}
              >
                <Button
                  type="primary"
                  shape="round"
                  className="ap-btn-c"
                  loading={
                    state.projectAttachementSubCategories.loading ? (
                      <Spin />
                    ) : (
                      false
                    )
                  }
                  disabled={Object.values(formData).length === 0}
                  icon={<Icons.Sweep />}
                  size="middle"
                  onClick={() => {
                    setFormData({});
                    setCurrent({
                      page: 1,
                      size: 10,
                    });
                    setTableData([]);
                  }}
                >
                  <span className="ap-fesm ap-fwmd">Clear</span>
                </Button>
              </div>
            </div>
          </Card>
        </div>

        {!state.projectAttachementSubCategories.loading ? (
          <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
            <Card
              style={{ width: "100%" }}
              title={
                <div className="ap-w100 ap-flex ap-flex-end ap-fmd ap-fwmd">
                  <Button
                    loading={state.projectAttachementSubCategories.loading}
                    type="primary"
                    className="ap-btn-c"
                    shape="round"
                    icon={<Icons.Plus />}
                    size="middle"
                    onClick={() => {
                      onOpen();
                    }}
                  >
                    <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">
                      Add New Attachement Sub Category
                    </span>
                  </Button>
                </div>
              }
            >
              <Table
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 1300 }}
                pagination={{
                  current: current.page,
                  pageSize: current.size,
                  position: ["bottomLeft"],
                  total: state.projectAttachementSubCategories.totalRecords,
                  onChange: (data, size) =>
                    callRecords({
                      type: "page",
                      payload: { page: data, size: size },
                    }),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </Card>
          </div>
        ) : (
          <div
            className="ap-flex ap-w100 ap-flex-center-center"
            style={{ height: "300px" }}
          >
            <Spin tip="Fetching Records....." />
          </div>
        )}
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxH="38rem" minH="15rem">
          <ModalHeader
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            <Text color="gray.600">Add New Sub Category</Text>{" "}
            <div>
              <CloseButton onClick={onClose} />
            </div>
          </ModalHeader>{" "}
          <ModalBody>
            <AddNewSubCategory onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AttachmentCategoryList;
