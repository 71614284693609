const trelloBoardsReducer = (state = {
    loading:false,
    boards:[]
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_BOARDS":
            return { ...state ,loading:true,boards:[]}
        case "RECEIVE_TRELLO_BOARDS":
            return {...state, boards:[...action.payload],loading:false,}
        case "ERROR_TRELLO_BOARDS":
            return { ...state ,boards:[],loading:false}
        default:
            return { ...state }
    }
}

export default trelloBoardsReducer;