const trelloMembersListReducer = (state = {
    loading:false,
    members:[],
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_MEMBERS":
            return { ...state ,loading:true,members:[]}
        case "RECEIVE_TRELLO_MEMBERS":
            return {...state, members:[...action.payload],loading:false}
        case "ERROR_TRELLO_MEMBERS":
            return { ...state ,members:[],loading:false}
        default:
            return { ...state }
    }
}

export default trelloMembersListReducer;