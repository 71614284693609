import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Card, Input, Spin, Button, Table, Space, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { API } from "../../../Services";
import toast from "react-hot-toast";
import moment from "moment";


export default function Partnerleadlist() {
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const history = useHistory();
  const { confirm } = Modal;
  const { partnerleadlist } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    partnerleadlist(id);
  }, []);
  
  const showConfirm = (leadId) => {
    confirm({
      title: "Are you sure you want to revoke the lead ?",
      icon: <ExclamationCircleFilled />,
      content: (
        <div style={{ display: "flex", flexFlow: "column" }}>
          <span>
            <b>Customer Name: </b> {leadId?.customerName}
          </span>
          <span>
            <b>Manager: </b> {leadId?.manager}
          </span>
        </div>
      ),
      onOk() {
        console.log("OK", leadId.assignLeadId);
        let data = {
          assignLeadId: leadId.assignLeadId,
          partnerId: id,
        };
        API.partnerRevokeLead(data)
          .then((res) => {
            toast.success(res.data.message, {
              position: "top-right",
            });
            partnerleadlist(id);
          })
          .catch((err) => {
            toast.error("Error! ", err, {
              position: "top-right",
            });
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });

  
  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Client ID</div>,
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <div className="ap-fsm">{text}</div>,
      width: "80px",
    },
    {
      title: <div className="ap-fsm ap-fwmd">Name</div>,
      dataIndex: "customerName",
      key: "customerName",
      render: (text) => <div className="ap-fsm">{text}</div>,

    },

    {
      title: <div className="ap-fsm ap-fwmd">Contact No.</div>,
      dataIndex: "contactNo",
      key: "contactNo",
      render: (text) => <div className="ap-fsm">{text}</div>,

    },
    {
      title: <div className="ap-fsm ap-fwmd">City</div>,
      dataIndex: "city",
      key: "city",
      render: (text) => <div className="ap-fsm">{text}</div>,

    },
    {
      title: <div className="ap-fsm ap-fwmd">State</div>,
      dataIndex: "state",
      key: "state",
      render: (text) => <div className="ap-fsm">{text}</div>,

    },
    {
      title: <div className="ap-fsm ap-fwmd">Country</div>,
      dataIndex: "country",
      key: "country",
      render: (text) => <div className="ap-fsm">{text}</div>,

    },
    {
      title: <div className="ap-fsm ap-fwmd">Manager</div>,
      dataIndex: "manager",
      key: "manager",
      render: (text) => <div className="ap-fsm">{text}</div>,

    },
    {
      title: <div className="ap-fsm ap-fwmd">Lead Assign Date</div>,
      dataIndex: "assignLeadDate",
      key: "assignLeadDate",
      render: (text) => <div className="ap-fsm">{moment.unix(text).format("MMM D, YYYY [at] h:mm A z")}</div>,

    },
    {
      title: <div className="ap-fsm ap-fwmd">Transfer Mode</div>,
      dataIndex: "transferMode",
      key: "transferMode",
      render: (text) => <div className="ap-fsm">{text}</div>,

    },
    {
      title: <div className="ap-fsm ap-fwmd">Claim Status</div>,
      dataIndex: "claimStatue",
      key: "claimStatue",
      render: (text) => <div className="ap-fsm">{text}</div>,

    },
    {
      title: <div className="ap-fsm ap-fwmd">Action</div>,
      key: "assignLeadId",

      render: (text, record) => (
        <Space size="small" className="table-btn">
          <Button
            type="primary"
            className="ap-btn-c"
            shape="round"
            disabled={text?.claimStatue !== 'UnClaimed'}
            size="middle"
            onClick={() => {
              showConfirm(text);
            }}
          >
            <span className="ap-fesm ap-fwmd">Revoke lead </span>
          </Button>
        </Space>
      ),
      width: "fit-content",
      fixed: "right",
    },
  ].filter((item) => !item.hidden);
  const callRecords = (event) => {
    console.log(event);
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };
  useEffect(() => {
    if (state.partnerleadlist.leads.length > 0) {
      console.log(state.partnerleadlist.leads);
      setTableData((prev) => {
        prev = [];
        state.partnerleadlist.leads.map((lead, leadIndex) => {
          prev.push({
            customerId: lead.customer_id,
            customerName: `${lead.customer_name}`,
            contactNo: lead?.phone_number || "NA",
            city: lead?.city || "NA",
            state: lead?.state || "NA",
            country: lead.country || "NA",
            manager: lead?.managerDetails?.manager_name || "NA",
            transferMode: lead?.transfer_mode == 1 ? "Manual" : "Auto",
            assignLeadDate:lead?.assignLeadDate,
            claimStatue:
              lead?.claim_statue == 0
                ? "UnClaimed"
                : lead?.claim_statue == 1
                ? "Claimed"
                : "Revoked",
            assignLeadId: lead?.assignLeadId,
          });
        });
        return prev;
      });
    } else {
      setTableData((prev) => {
        console.log(prev);
      });
    }
  }, [state]);
  return (
    <div style={{ marginTop: "15px" }}>
      <Card
        style={{ width: "100%" }}
        title={
          <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
            <div className="ap-flex">
              <span>Partner lead list</span>
            </div>
          </div>
        }
      >
        {!state.partnerleadlist.loading ? (
          <>
          {state?.partnerleadlist?.msg !== ""? (
        <>
          <div
            style={{
              fontSize: "large",
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            <h3>{state?.partnerleadlist?.msg }</h3>
          </div>
        </>
      ) :
          <div className="ap-flex ap-w100">
            <Table
              columns={columns}
              dataSource={tableData}
               scroll={{ x: 1300 }}
              pagination={{
                current: current.page,
                pageSize: current.size,
                position: ["bottomLeft"],
                total: state.partnerleadlist?.totalRecords,
                onChange: (data, size) =>
                  callRecords({
                    type: "page",
                    payload: { page: data, size: size },
                  }),
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />{" "}
          </div>}
          </>
        ) : (
          <div
            className="ap-flex ap-w100 ap-flex-center-center"
            style={{ height: "300px" }}
          >
            <Spin tip="Fetching Records....." />
          </div>
        )}
      </Card>
    </div>
  );
}
