const sitesListReducer = (
  state = {
    loading: false,
    sitesList: [],
    totalRecords: 0,
  },
  action
) => {
  switch (action.type) {
    case "FETCH_SITES":
      return { ...state, loading: true, sitesList: [], totalRecords: 0 };
    case "RECEIVE_SITES":
      return {
        ...state,
        sitesList: action?.payload?.sites?[...action?.payload?.sites]:[],
        loading: false,
        totalRecords: action.payload.totalRecords,
      };
    case "ERROR_SITES":
      return { ...state, sitesList: [], loading: true, totalRecords: 0 };
    default:
      return { ...state };
  }
};

export default sitesListReducer;