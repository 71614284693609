import React, { useState, useEffect } from "react";
import "./EditDetails.scss";
import { 
    Button, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Input,
} from '@chakra-ui/react';
import { 
    Select,
} from 'antd';
import { Icons } from "../../../../Utils"; 
import { PhoneInput } from "../../../../Components";
import { COUNTRIES } from "../../../../Utils";
import { API } from "../../../../Services";
import { useParams,useLocation } from "react-router-dom";
import toast from 'react-hot-toast';

const EditDetails = (props) =>{

    const { isOpen, onOpen, onClose } = useDisclosure();
    const countryOptions = COUNTRIES.map(({ name, iso2 }) => ({
        label: name,
        value: iso2
    }));
    const { Option } = Select;
    const [ formData ,setFormData ] = useState({});
    const [ errorList,setErrorList ] = useState([]);
    const { client_id } = useParams();
    const location = useLocation();
    const [load,setLoad] = useState(false);

    const setInitialData = () =>{
        props.states(props.state.customer.customer?.Country)
        setFormData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.firstName = props.state.customer?.customer?.firstName
            prev.lastName = props.state.customer?.customer?.lastName 
            prev.email = props.state.customer?.customer?.email
            prev.phoneNumber = props.state.customer?.customer?.phoneNumber !== '' ?
            `${props.state.customer?.customer?.phoneNumber}`:''
            prev.isdCode = props.state.customer?.customer?.isdCode
            prev.country = {value:'',label:'Please select country'}
            props.state.countries.countries.map((country,countryIndex)=>{
                if(country.value===props.state.customer.customer?.Country){
                    prev.country = country
                }
            })
            prev.city = props.state.customer?.customer?.City
            prev.manager = {value:'',label:'Please select manager'}
            props.state.admins.admins.map((admin,adminIndex)=>{
                if(admin.value === props.state.customer.customer?.managerId?.Admin_id){
                    prev.manager = admin
                }
            })    
            return prev
        })
    }

    useEffect(()=>{
        if(props.state.states.states.length > 0){
            setFormData((prev)=>{
                prev = JSON.parse(JSON.stringify(prev))
                prev.state = {value:'',label:'Please select state'}
                props.state.states.states.map((s,sIndex)=>{
                    if(s.value === props.state.customer.customer?.State ){
                        prev.state = s
                    }
                })
                return prev
            })
        }
    },[props.state])

    const handleFormData = (data) =>{
        setFormData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev[`${data.target.name}`] = data.target.value
            if(data.target.name==='country'){
                prev.state = {value:'',label:'Please select state'}
            }
            return prev
        })
    }

    const setPhoneValue = (value) =>{
        setFormData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.isdCode = value?.code.split("+")[1]
            prev.phoneNumber = value?.number
            return prev
        })
    }

    useEffect(()=>{
        let re = /\S+@\S+\.\S+/;
        setErrorList((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            Object.entries(formData).map(([key,value],index)=>{
                if(key==='email'){
                    if(!re.test(value) && !prev.includes(key)){
                        prev.push(key)
                        return prev
                    }else if(re.test(value) && prev.includes(key)){
                        prev.splice(prev.indexOf(key),1)
                    }
                }else if(['isdCode','phoneNumber'].includes(key)){
                    if(value === undefined && !prev.includes(key)){
                        prev.push(key)
                    }else if(value !== undefined && prev.includes(key)){
                        prev.splice(prev.indexOf(key),1)
                    }
                }else if(['manager','state','country'].includes(key)){
                    if(value?.value==='' && !prev.includes(key)){
                        prev.push(key)
                    }else if(value?.value!=='' && prev.includes(key)){
                        prev.splice(prev.indexOf(key),1)
                    }
                }else{
                    if(value===''&& !prev.includes(key)){
                        prev.push(key)
                    }else if(value!==''&& prev.includes(key)){
                        prev.splice(prev.indexOf(key),1)
                    }
                }
            })
            return prev
        })
    },[formData])

    const updateClientDetails = () =>{
        let d = {
            city: formData?.city,
            email: formData?.email,
            firstName: formData?.firstName,
            id: client_id,
            isdCode: formData?.isdCode,
            lastName: formData?.lastName,
            phoneNumber: formData?.phoneNumber,
            state: formData?.state?.value,
            country: formData?.country?.value,
            managerid: formData?.manager?.value,
        }
        setLoad((prev)=>!prev)
        API.clientUpdate(client_id,d).then((res)=>{
            setLoad((prev)=>!prev)
            toast.success(
                "Client details are updated successfully.",
                {position:"top-right"}
            )
            props.customer(client_id)
        }).catch((err)=>{
            //console.log(err?.response?.data )
            const error = (err?.response?.data?.email||err?.response?.data?.city||err?.response?.data?.firstName ||err?.response?.data?.phoneNumber)

            setLoad((prev)=>!prev)
            toast.error(
                `Oops!,${error||"Client details are not updated."}!`,
                {position:"top-right"}
            )
        })
    }

    return (
        <div className="edit-client-details">
            <Button
                size='sm'
                className="submit_right1"
                leftIcon={<Icons.Edit/>}
                isLoading={props.mainLoad}
                onClick={()=>{
                    onOpen();
                    setInitialData()
                }}
            >
                Edit
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size='2xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update client details</ModalHeader>
                    <ModalBody pb={6}>
                        {/* row */}
                        <div className="ap-flex ap-flex-space-bw">
                            <div className="ap-flex ap-flex-center-center ap-w48">
                                <span className="ap-flex ap-fsm ap-fwmd ap-w100">First name</span>
                                <Input
                                    variant={'filled'}
                                    value={formData?.firstName}
                                    size='sm'
                                    placeholder="Please enter firstname"
                                    name = 'firstName'
                                    onChange={(event)=>handleFormData(event)}
                                    errorBorderColor='crimson'
                                    isInvalid={errorList.includes('firstName')}
                                    isDisabled={load}
                                />
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-w48">
                                <span className="ap-flex ap-fsm ap-fwmd ap-w100">Last name</span>
                                <Input
                                    variant={'filled'}
                                    value={formData?.lastName}
                                    size='sm'
                                    placeholder="Please enter lastname"
                                    name = 'lastName'
                                    onChange={(event)=>handleFormData(event)}
                                    errorBorderColor='crimson'
                                    isInvalid={errorList.includes('lastName')}
                                    isDisabled={load}
                                />
                            </div>
                        </div>
                        {/* row */}
                        <div className="ap-flex ap-flex-space-bw" style={{marginTop:'10px'}}>
                            <div className="ap-flex ap-flex-center-center ap-w48">
                                <span className="ap-flex ap-fsm ap-fwmd ap-w100">Email</span>
                                <Input
                                    variant={'filled'}
                                    size='sm'
                                    value={formData?.email}
                                    placeholder="Please enter email"
                                    name = 'email'
                                    onChange={(event)=>handleFormData(event)}
                                    errorBorderColor='crimson'
                                    isInvalid={errorList.includes("email")}
                                    isDisabled={load}
                                />
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-w48">
                                <span className="ap-flex ap-fsm ap-fwmd ap-w100">Contact no</span>
                                <PhoneInput 
                                    value = {formData.phoneNumber?formData.phoneNumber:''}
                                    country = {formData.country?formData?.country?.value:''}
                                    placeholder="Please enter contact number"
                                    options={countryOptions}
                                    onChange={(value)=>{
                                        setPhoneValue(value);
                                    }}
                                    errorBorderColor='crimson'
                                    isDisabled={load}
                                    isInvalid={errorList.includes("isdCode") || errorList.includes("phoneNumber")}
                                />
                            </div>
                        </div>
                        {/* row */}
                        <div className="ap-flex ap-flex-space-bw" style={{marginTop:'10px'}}>
                            <div className="ap-flex ap-flex-center-center ap-w48">
                                <span className="ap-flex ap-fsm ap-fwmd ap-w100">Country</span>
                                <Select 
                                    showSearch
                                    size="middle" 
                                    value={formData?.country}
                                    labelInValue 
                                    placeholder="Please select country" 
                                    onChange={(data)=>{
                                        handleFormData(
                                            {
                                                target:{name:'country',value:data}
                                            }
                                        );
                                        props.states(data?.value)
                                    }}
                                    style={{width:"100%",border:`${errorList.includes('country')?"1px solid red":''}`}}
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0    
                                    }
                                    disabled={load}
                                    loading={props.state.countries?.isLoading}
                                >
                                    <Option value={''}>Please select country</Option>
                                    {props.state.countries.countries.map((country,countryIndex)=>(
                                        <Option value={country.value} index={countryIndex}>{country.label}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-w48">
                                <span className="ap-flex ap-fsm ap-fwmd ap-w100">State</span>
                                <Select 
                                    showSearch
                                    size="middle" 
                                    style={{width:"100%",border:`${errorList.includes('state')?"1px solid red":''}`}}
                                    value={formData?.state}
                                    labelInValue 
                                    placeholder="Select State" 
                                    onChange={(data)=>{
                                        handleFormData(
                                            {
                                                target:{name:'state',value:data}
                                            }
                                        )
                                    }}
                                    disabled={props.state.states.loading || formData?.country?.value==='' || load}
                                    loading={props.state.states.loading}
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0  
                                    }
                                >
                                    <Option value={''}>Please select state</Option>
                                    {props.state.states.states.map((state,stateIndex)=>(
                                        <Option value={state.value} index={stateIndex}>{state.label}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        {/* row */}
                        <div className="ap-flex ap-flex-space-bw" style={{marginTop:'10px'}}>
                            <div className="ap-flex ap-flex-center-center ap-w48">
                                <span className="ap-flex ap-fsm ap-fwmd ap-w100">City</span>
                                <Input
                                    variant={'filled'}
                                    value={formData?.city}
                                    size='sm'
                                    placeholder="Please enter email"
                                    name='city'
                                    onChange={(event)=>{handleFormData(event)}}
                                    errorBorderColor='crimson'
                                    isInvalid={errorList.includes('city')}
                                    isDisabled={load}
                                />
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-w48">
                                <span className="ap-flex ap-fsm ap-fwmd ap-w100">Manager</span>
                                <Select 
                                    showSearch
                                    size="middle" 
                                    value={formData?.manager}
                                    labelInValue 
                                    placeholder="Please select manager" 
                                    onChange={(data)=>{
                                        handleFormData(
                                            {
                                                target:{name:'manager',value:data}
                                            }
                                        )
                                    }}
                                    disabled={load}
                                    style={{width:"100%",border:`${errorList.includes('manager')?"1px solid red":''}`}}
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                    }
                                >
                                    <Option value={''}>Please select manager</Option>
                                    {props.state.admins.admins.map((admin,adminIndex)=>(
                                        <Option value={admin.value} index={adminIndex}>{admin.label}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
            className="submit_right1"
            size='sm' 
                            mr={3}
                            isDisabled={errorList.length !== 0}
                            isLoading={load}
                            onClick={()=>updateClientDetails()}
                        >
                            Save
                        </Button>
                        <Button 
                            size='sm'
                            onClick={onClose}
                            colorScheme="red"
                            leftIcon={<Icons.Close/>}
                            isLoading={load}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
        </Modal>
      </div>
    )
}

export default EditDetails;