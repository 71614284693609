import { Card } from "antd";
import React from "react";

export default function DisplayBillingInfo({ billingInfoData }) {
  return (
    <div className="ap-flex w100 ap-flex-space-bw">
      <Card
        size="small"
        style={{ width: "100%", height: "fit-content", minHeight: "365px" }}
      >
        <div className="ap-flex ap-flex-center-center ap-fsm ap-fwbo">
          Primary Information
        </div>
        <div
          className="ap-flex w100 ap-flex-space-bw"
          style={{ marginBottom: "10px" }}
        >
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Name
          </div>
          <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
            {billingInfoData[0]?.firstName} {billingInfoData[0]?.lastName}
          </div>
        </div>
        {Object.entries(billingInfoData[0]).map(([key, value], index) => {
          let formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
          let displayValue = value;

          if (key === "phoneNumber") {
            formattedKey = "Phone No";
          } else if (key === "businessPhoneNumber") {
            formattedKey = "Business Phone No";
          } else if (key === "email") {
            formattedKey = "Email";
          } else if (key === "businessName") {
            formattedKey = "Business Name";
          } else if (key === "panNo") {
            formattedKey = "PAN No";
          } else if (key === "gstNo") {
            formattedKey = "GST No";
          }

          return [
            "phoneNumber",
            "email",
          ].includes(key) && value ? (
            <div
              className="ap-flex w100 ap-flex-space-bw"
              key={index}
              style={{ marginBottom: "10px" }}
            >
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                {formattedKey}
              </div>
              <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
                {displayValue}
              </div>
            </div>
          ) : null;
        })}
        <br />
      { ( billingInfoData[0].businessName || billingInfoData[0].businessPhoneNumber||billingInfoData[0].gstNo||billingInfoData[0].panNo)
       && <>
         <div className="ap-flex ap-flex-center-center ap-fsm ap-fwbo">
         Business Information
        </div> <br />
        {Object.entries(billingInfoData[0]).map(([key, value], index) => {
          let formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
          let displayValue = value;

     if (key === "businessPhoneNumber") {
            formattedKey = "Business Phone No";
          } else  if (key === "businessName") {
            formattedKey = "Business Name";
          } else if (key === "panNo") {
            formattedKey = "PAN No";
          } else if (key === "gstNo") {
            formattedKey = "GST No";
          }

          return [
            "businessName",
            "businessPhoneNumber",
            "panNo",
            "gstNo",
          ].includes(key) && value ? (
            <div
              className="ap-flex w100 ap-flex-space-bw"
              key={index}
              style={{ marginBottom: "10px" }}
            >
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                {formattedKey}
              </div>
              <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
                {displayValue}
              </div>
            </div>
          ) : null;
        })}</>}
      </Card>
      <Card size="small" style={{ width: "100%", height: "fit-content" }}>
        <div className="ap-flex ap-flex-center-center ap-fsm ap-fwbo">
          Billing Information
        </div>
        <div
          className="ap-flex w100 ap-flex-space-bw"
          style={{ marginBottom: "10px" }}
        >
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Name
          </div>
          <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
            {billingInfoData[0]?.billingAddress?.billing_first_name}{" "}
            {billingInfoData[0]?.billingAddress?.billing_last_name}
          </div>
        </div>
        {Object.entries(billingInfoData[0]?.billingAddress).map(
          ([key, value], index) => {
            let formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
            let displayValue = value;

            if (key === "billing_phone_number") {
              formattedKey = "Phone No";
            } else if (key === "billing_email") {
              formattedKey = "Email";
            }

            return (
              [
                "billing_phone_number",
                "billing_email",
                "addressLine_1",
                "addressLine_2",
                "country",
                "state",
                "city",
                "pincode",
              ].includes(key) && (
                <div
                  className="ap-flex w100 ap-flex-space-bw"
                  key={index}
                  style={{ marginBottom: "10px" }}
                >
                  <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                    {formattedKey}
                  </div>
                  <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
                    {displayValue}
                  </div>
                </div>
              )
            );
          }
        )}
      </Card>
      <Card size="small" style={{ width: "100%", height: "fit-content" }}>
        <div className="ap-flex ap-flex-center-center ap-fsm ap-fwbo">
          Shipping Information{" "}
        </div>
        <div
          className="ap-flex w100 ap-flex-space-bw"
          style={{ marginBottom: "10px" }}
        >
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Name
          </div>
          <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
            {billingInfoData[0]?.shippingAddress.shipping_first_name}{" "}
            {billingInfoData[0]?.shippingAddress.shipping_last_name}
          </div>
        </div>
        {Object.entries(billingInfoData[0].shippingAddress).map(
          ([key, value], index) => {
            let formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
            let displayValue = value;

            if (key === "shipping_phone_number") {
              formattedKey = "Phone No";
            } else if (key === "shipping_email") {
              formattedKey = "Email";
            }

            return (
              [
                "shipping_phone_number",
                "shipping_email",
                "addressLine_1",
                "addressLine_2",
                "country",
                "state",
                "city",
                "pincode",
              ].includes(key) && (
                <div
                  className="ap-flex w100 ap-flex-space-bw"
                  key={index}
                  style={{ marginBottom: "10px" }}
                >
                  <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                    {formattedKey}
                  </div>
                  <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">
                    {displayValue}
                  </div>
                </div>
              )
            );
          }
        )}
      </Card>
    </div>
  );
}
