import React from "react";
import { Card,Tooltip,Skeleton } from 'antd';
import 'antd/dist/antd.css';
import "./CustomerDetails.scss"
import "../../Styles/Master.scss"
import { Icons } from "../../Utils";
import { useSelector } from "react-redux";

const CustomerDetails = (props) =>{
    const state = useSelector((state)=>state)

    return <>
            <Card 
                style={{width:"100%"}}  
                title={<div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd edit-btn">
                            <div className="ap-flex ap-flex-center-center">
                                Customer Details
                            </div>
                            <div className="ap-flex ap-flex-center-center" onClick={()=>{
                                props.editFunction && props.editFunction()
                            }}>
                                <Tooltip title="Edit" color="#000" placement="bottomLeft">
                                    <Icons.Edit/>
                                </Tooltip>
                            </div>
                        </div>} 
            >
            {state.customer?.customer && !state.customer?.loading ? <>
                {Object.entries(state.customer?.customer).map(([key,value],index)=>(
                    ["Id","Name","Email","Contact Number","Currency","City","State","Country"].includes(key) && <div className="ap-flex w100 ap-flex-space-bw" key={index} style={{marginBottom:"10px"}}>
                        <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">{key}</div>
                        <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">{value}</div>
                    </div>
                ))}
              
                {props?.showWallet&&
                <div className="ap-flex w100 ap-flex-space-bw" style={{marginBottom:"10px"}}>
                        <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">Wallet Balance</div>
                        <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd">{props?.walletBalance||'-'}</div>
                    </div>}
                </>
            : <Skeleton active/>}
        </Card>
    </>
}

export default CustomerDetails;