import { Card, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { actionCreators } from "../../../Redux";
import { bindActionCreators } from "redux";
import { Icons } from "../../../Utils";

export default function SiteDetails() {
  const history = useHistory();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { sitesList } = bindActionCreators(actionCreators, dispatch);
  const [siteDetail, setSiteDetail] = useState([]);

  useEffect(() => {
    sitesList(`siteId=${id}`);
  }, [id]);

  useEffect(() => {
    if (state.sitesList?.sitesList[0]) {
      console.log("state.sitesList?.sitesList", state.sitesList?.sitesList[0]);
      setSiteDetail(state.sitesList?.sitesList[0]);
    }
  }, [state.sitesList?.sitesList]);

  const basicDetailsKeys = [
    "ID",
    "name",
    "created_by",
    "purpose",
    "type",
    "stage",
    "status",
    "width",
    "depth",
    "floors",
    "plot_area",
    "direction",
  ];

  const addressDetailsKeys = [
    "address",
    "city",
    "state",
    "pincode",
    "lattitude",
    "longitude",
  ];

  const renderKeyValue = (key, value) => {
    if (key === "created_by") {
      return `${value?.first_name} ${value?.last_name || ""}`;
    }
    return typeof value === "object" && value !== null
      ? JSON.stringify(value)
      : value;
  };

  return (
    <div>
      {state.sitesList?.loading? (
      <div
        className="ap-flex ap-w100 ap-flex-center-center"
        style={{ height: "300px" }}
      >
        <Spin tip="Fetching Records....." />
      </div>
      ):(
      <div className="ap-flex ap-flex-center-center ap-w100 prj-details">
        <div className="ap-flex ap-w100">
          <Card
            size="small"
            style={{ width: "100%" }}
            title={
              <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
                <div className="ap-flex  ap-w100 ap-fmd ap-fwmd edit-btn">
                  <div className="ap-flex ap-flex-center-center">
                    Site Details
                  </div>
                  <div
                    className="ap-flex ap-flex-center-center"
                    onClick={() => {}}
                  >
                    <Tooltip title="Edit" color="#000" placement="bottomLeft">
                      <Icons.Edit />
                    </Tooltip>
                  </div>
                </div>
                <span
                  className="ap-fesm ap-fwmd"
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => history.goBack()}
                >
                  {"<< go back"}
                </span>
              </div>
            }
          >
            <div className="ap-w100">
              <div className="ap-flex ap-flex-space-bw ap-w100">
                <Card size="small" style={{ width: "80%" }}>
                  {siteDetail && (
                    <>
                      <h3
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Basic Details
                      </h3>
                      {basicDetailsKeys.map((key) => (
                        <div
                          className="ap-flex ap-w100 ap-flex-space-bw ap-p5"
                          key={key}
                        >
                          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                            {key}
                          </div>
                          <div className="ap-flex ap-flex-center-center ap-fsm">
                            {renderKeyValue(key, siteDetail[key])}
                          </div>
                        </div>
                      ))}
                      <hr />
                      <h3
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Address Details
                      </h3>
                      {addressDetailsKeys.map((key) => (
                        <div
                          className="ap-flex ap-w100 ap-flex-space-bw ap-p5"
                          key={key}
                        >
                          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                            {key}
                          </div>
                          <div className="ap-flex ap-flex-center-center ap-fsm">
                            {siteDetail[key]}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </Card>

                <Card>
                  {siteDetail.customer && (
                    <div className="customer-details">
                      <h3
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Customer Details
                      </h3>
                      {Object.entries(siteDetail.customer).map(
                        ([key, value]) => (
                          <div
                            className="ap-flex ap-w100 ap-flex-space-bw ap-p5"
                            key={key}
                          >
                            <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                              {key}
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-fsm">
                              {value}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </Card>
        </div>
      </div>
      )}
    </div>
  );
}
