import React, { useEffect, useState } from 'react';
import "./CustomOrderList.scss";
import { Card, Select,Spin,Button,Table, Tag, Space,Input,DatePicker  } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Icons } from '../../../Utils';
import moment from "moment";

import { useHistory } from 'react-router-dom';

const CustomOrderList = (props) =>{

    const state = useSelector(state=>state)
    const dispatch = useDispatch();
    const [current,setCurrent] =useState({
        page:1,
        size:10,
    });
    const history = useHistory();
    const [data,setData] = useState({});
    const [tableData,setTableData] = useState([]);
    const { customOrders,customersList,admins } = bindActionCreators(actionCreators,dispatch)

    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const getFormatedDate = (createdDate) => {
        let dateHere = createdDate.split(' ')
        let dateHere1 = dateHere[0].split("-")
        return `${dateHere1[2]}/${dateHere1[1]}/${dateHere1[0]}`
    }

    const callRecords = (event) =>{
        setCurrent((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.page = prev.size === event.payload.size ? event.payload.page:1
            prev.size = event.payload.size
            return prev
        })
        console.log("customOrderFilter",data);

            localStorage.setItem("customOrderFilter",JSON.stringify(data))
          
    }

    useEffect(()=>{
        if(state.customOrders.customOrders.length > 0){

  if(localStorage?.getItem("customOrderFilter"))
 {       
    
    var customOrderFilter= JSON.parse(localStorage?.getItem("customOrderFilter"));
    setData(customOrderFilter);
 }
            setTableData((prev)=>{
                prev = []
                state.customOrders.customOrders.map((order,orderIndex)=>{
                    prev.push({
                        key:orderIndex,
                        orderid:order.orderid,
                        customer:`${order.customer.firstName} ${order.customer.lastName}`,
                        createdBy:`${JSON.parse(localStorage.getItem("adminDetails")).adminId === `${order.createdBy.Admin_id}` ? "You":`${order.createdBy.name} ${order.createdBy.lastname}`}`,
                        approver:`${JSON.parse(localStorage.getItem("adminDetails")).adminId === `${order.approver.Admin_id}` ? "You":`${order.approver.name} ${order.approver.lastname}`}`,
                        createdAt:getFormatedDate(order.orderat),
                        orderStatus: order.orderStatus,
                        orderGenerated:order.ispublish
                    })
                })
                return prev
            })
        }
    },[state])

   useEffect(() => {
             admins();

   }, [])
   
    useEffect(()=>{
        setTableData([])

        var customOrderFilter= JSON.parse(localStorage?.getItem("customOrderFilter"));
        console.log("filterdata---",customOrderFilter)
        if(localStorage?.getItem("customOrderFilter"))
 {       
    customOrders(`page=${current.page}&limit=${current.size}&assignedTo=${customOrderFilter?.approver?.value ||""}&CreatedBy=${customOrderFilter?.createdBy?.value || ""}&customerID=${customOrderFilter?.customer?.value ||''}&quotationID=${customOrderFilter?.quotationID || ""}&createdDateFrom=${customOrderFilter?.from||''}&createdDateTo=${customOrderFilter?.to||''}${customOrderFilter?.generated ?`&ordergenerated=${customOrderFilter?.generated?.value}`:""}${customOrderFilter?.status ?`&orderStatus=${customOrderFilter?.status?.value}`:""}`)
 }    else   
 {    
        customOrders(`page=${current.page}&limit=${current.size}&assignedTo=${data.approver ? data.approver.value :""}&CreatedBy=${data.createdBy ? data.createdBy.value : ""}&customerID=${data.customer ? data.customer.value :''}&quotationID=${data.quotationID ? data.quotationID : ""}&createdDateFrom=${data.from?data.from:''}&createdDateTo=${data.to?data.to:''}${data.generated?`&ordergenerated=${data.generated?.value}`:""}${data.status?`&orderStatus=${data.status?.value}`:""}`)
 }
    },[current])

    const setFormData =(event)=>{
        setData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            if(event.target.name === "date"){
                prev[`from`] = event.target.value[0]
                prev[`to`] = event.target.value[1]
               // prev.moment = event.target.moment

            } else{
                prev[`${event.target.name}`] = event.target.value
            }
            return prev
        })
    }

    useEffect(()=>{
        console.log(data)
    },[data])


    const columns = [
        {
            title:<div className="ap-fsm ap-fwmd">Order Id</div>,
            dataIndex:"orderid",
            key:"orderid",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Customer</div>,
            dataIndex:"customer",
            key:"customer",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Created By</div>,
            dataIndex:"createdBy",
            key:"createdBy",
            render:text=><div className="ap-fsm">{text}</div>,
           
        },
        {
            title:<div className="ap-fsm ap-fwmd">Approver</div>,
            dataIndex:"approver",
            key:"approver",
            render:text=><div className="ap-fsm">{text}</div>

        },
        {
            title:<div className="ap-fsm ap-fwmd">Created At</div>,
            dataIndex:"createdAt",
            key:"createdAt",
            render:text=><div className="ap-fsm">{text}</div>
            
        },
        {
            title:<div className="ap-fsm ap-fwmd">Status</div>,
            dataIndex:"orderStatus",
            key:"orderStatus",
            render:status=>(
                <Tag color={status === "approved" ? " var( --primary-green)":status === "cancelled"?"red":"yellow"}>
                    <div className="ap-fsm">{status === "cancelled"?"declined":status}</div>
                </Tag>
            ),
            width:"fit-content",
        },
        {
            title:<div className="ap-fsm ap-fwmd">Generated</div>,
            dataIndex:"orderGenerated",
            key:"orderGenerated",
            render:isPublish=>(
                <Tag color={isPublish === 1 ? " var( --primary-green)":"yellow"}>
                    <div className="ap-fsm">{isPublish === 1 ?"Yes":"No"}</div>
                </Tag>
            ),
            width:"fit-content",
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="small" className="table-btn">
                <Button type="primary" className="ap-btn-c" shape="round"  icon={<Icons.Details />} size="middle" onClick={()=>history.push(`/home/ap/custom/orders/details/${record.orderid}`)}>
                    <span className="ap-fesm ap-fwmd">Show Details</span> 
                </Button>
              </Space>
            ),
            width:"fit-content",
            fixed:"right",
          },
    ]

   
    
    return <div className='ap-flex ap-flex-center-center ap-w100'>
            
            <div className="ap-flex ap-w100">
                <Card style={{width:"100%"}}>
                    <div className="ap-flex ap-w100 ">
                        <div className="ap-flex ap-flex-center-center ap-w33">
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Order Id</div>
                            <Input 
                                size="large"
                                style={{width:"100%"}}
                                placeholder="Enter Order Id"
                                value={data?.quotationID}
                                name="quotationID"
                                onChange={(e)=>setFormData(e)}
                            />
                        </div>
                        <div className="ap-flex ap-flex-center-center ap-w33" style={{margin:"0 10px"}}>
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Customer</div>
                            <Select 
                                size="large" 
                                showSearch
                                onSearch={(data)=>customersList(`q=${data}`)} 
                                labelInValue
                                 placeholder="Select customer" 
                                style={{ width: "100%" }}
                                value={data?.customer} 
                                loading={state.customers.loading} 
                                onChange={(d)=>setFormData({target:{name:"customer",value:d}})}
                                notFoundContent={state.customers.loading && <div className="ap-w100 ap-flex ap-flex-center">
                                    <Spin size="small"/>
                                </div>}
                                filterOption={false}
                            >
                                {state.customers.customers.map((customer,customerIndex)=>(
                                    <Option value={customer.value} key={customerIndex}>{customer.label}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="ap-flex ap-flex-center-center ap-w33">
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Created By</div>
                                <Select 
                                    showSearch
                                    size="large" 
                                    value={data?.createdBy}
                                    labelInValue placeholder="Select created by" 
                                    style={{ width: "100%" }} 
                                    loading={state.admins.loading}
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                        
                                        }
                                    onChange={(data)=>{setFormData({target:{name:"createdBy",value:data}});console.log(data)}}
                                >
                                    {state.admins.admins.map((admin,adminIndex)=>(
                                        <Option value={admin.value} key={admin.value}>{admin.label}</Option>
                                    ))}
                                </Select>
                        </div>
                    </div>

                    <div className="ap-flex ap-w100 " style={{marginTop:"10px"}}>
                        <div className="ap-flex ap-flex-center-center ap-w33">
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Approver</div>
                                <Select 
                                    showSearch
                                    size="large" 
                                    value={data?.approver}
                                    labelInValue placeholder="Select Approver" 
                                    style={{ width: "100%" }} 
                                    loading={state.admins.loading}
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                        
                                        }
                                    onChange={(data)=>{setFormData({target:{name:"approver",value:data}})}}
                                >
                                    {state.admins.admins.map((admin,adminIndex)=>(
                                        <Option value={admin.value} key={admin.value}>{admin.label}</Option>
                                    ))}
                                </Select>
                        </div>
                        <div className="ap-flex ap-flex-center-center ap-w33" style={{margin:"0 10px"}}>
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Created from to</div>
                            <RangePicker size="large" 
                           // value={data?.moment} 
                           value={data?.from ?[moment(data?.from), moment(data?.to)]:""}
                            onChange={(data,dateString)=>{setFormData({target:{name:"date",value:dateString,moment:data}})}}/>
                        </div>
                        <div className="ap-flex ap-flex-center-center ap-w33" style={{margin:"0 10px"}}>
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Order Generated</div>
                                <Select 
                                    showSearch
                                    size="large" 
                                    value={data?.generated}
                                    labelInValue placeholder="Select yes/no" 
                                    style={{ width: "100%" }} 
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                        
                                        }
                                    onChange={(data)=>{setFormData({target:{name:"generated",value:data}})}}
                                >
                                   <Option value={1}>Yes</Option>
                                   <Option value={0}>No</Option>
                                </Select>
                        </div>
                    </div>

                    <div className="ap-flex ap-w100 " style={{marginTop:"10px"}}>
                        <div className="ap-flex ap-flex-center-center ap-w33">
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Order Status</div>
                                <Select 
                                    showSearch
                                    size="large" 
                                    value={data?.status}
                                    labelInValue placeholder="Select order status" 
                                    style={{ width: "100%" }} 
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                        
                                        }
                                    onChange={(data)=>{setFormData({target:{name:"status",value:data}})}}
                                >
                                   <Option value={0}>Pending</Option>
                                   <Option value={1}>Approved</Option>
                                   <Option value={4}>Declined</Option>
                                </Select>
                        </div>
                    </div>

                    <div className="ap-flex ap-w100 " style={{marginTop:"10px"}}>
                        <div className="ap-flex ap-flex-center-center search-btn">
                            <Button type="primary" className="ap-btn-c" shape="round" loading={state.customOrders.loading ?<Spin/>:false} disabled={Object.values(data).length===0} icon={<Icons.Search />} size="middle" onClick={()=>callRecords({payload:{page:1,size:10}})}>
                                <span className="ap-fesm ap-fwmd">Search</span>
                            </Button>
                        </div>
                        <div className="ap-flex ap-flex-center-center search-btn" style={{marginLeft:"10px"}}>
                            <Button type="primary" shape="round"  className="ap-btn-c" loading={state.customOrders.loading ?<Spin/>:false} disabled={Object.values(data).length === 0} icon={<Icons.Sweep />} size="middle" onClick={()=>{
                                setData({});
                                setCurrent({
                                    page:1,
                                    size:10,
                                });
                                localStorage.removeItem("customOrderFilter")
                                customOrders(`limit=10&page=1`)      
                            }}>
                                <span className="ap-fesm ap-fwmd">Clear</span> 
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>

            {!state.customOrders.loading ? <div className="ap-flex ap-w100" style={{marginTop:"20px"}}>
                    <Card style={{width:"100%"}}>
                        <Table 
                            columns={columns} 
                            dataSource={tableData} 
                            scroll={{ x: 1200 }} 
                            pagination={
                                {
                                    current:current.page,
                                    pageSize:current.size,
                                    position:["bottomLeft"],
                                    total:state.customOrders.totalRecords,
                                    onChange:(data,size)=>callRecords({type:"page",payload:{page:data,size:size}}),
                                    showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                }
                            }
                        />
                    </Card>
                </div>:
                    <div className="ap-flex ap-w100 ap-flex-center-center" style={{height:"300px"}}>
                        <Spin tip="Fetching Records....."/>
                    </div>
                }
    </div>
}

export default CustomOrderList;