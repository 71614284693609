const requestFormsReducer = (state = {
    loading:false,
    forms:[],
    totalRecords:0,
}, action) => {
    switch (action.type) {
        case "FETCH_RFORMS":
            return { ...state ,loading:true,forms:[],totalRecords:0}
        case "RECEIVE_RFORMS":
            return {...state, forms:[...action.payload],loading:false,totalRecords:0}
        case "ERROR_RFORMS":
            return { ...state ,forms:[],loading:true,totalRecords:0}
        default:
            return { ...state }
    }
}

export default requestFormsReducer;