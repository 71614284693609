import { axiosInstance } from ".."

const syncTrello = async (prjCode) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.put(`internal/pm/trellosync/${prjCode}`).then((res) => { 
            console.log(res)
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default syncTrello;