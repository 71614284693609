import { axiosInstance } from "..";

const browserFileActionLogs = async (projectID) => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .get(`/internal/OfficeData/logs/${projectID}`)
      // .get(
      //   `internal/OfficeData/PRJ202301300001/?path=65/65-107-WW-PRJ202202180177-UMESH KUMAR GUPTA&subfolder=2d`
      // )
      .then((res) => {
        //  console.log(page)
        if (res.status === 200) {
          return resolve(res);
        }
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export default browserFileActionLogs;
