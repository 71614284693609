// SplitPopUp.js
import React, { useEffect, useState } from "react";
import { ModalBody, Button, ModalFooter } from "@chakra-ui/react";
import { AddressDiv } from "./invoiceStyle";
import { Input } from "antd";
import toast from "react-hot-toast";

const SplitPopUp = ({
  onClose,
  onSplitAmountEntered,
  setNumOfSplits,
  mainItemAmount,
}) => {
  const [splitAmount, setSplitAmount] = useState(0);
  const [numSplits, setNumSplits] = useState(0);
  const [isAnySplitAmountEmpty, setIsAnySplitAmountEmpty] = useState(true);

  const handleSplitAmountSubmit = () => {
    onSplitAmountEntered(splitAmount);
    setNumOfSplits(numSplits);
    onClose();
  };
  useEffect(() => {
    if (splitAmount) {
      const totalSplitAmount = splitAmount?.reduce(
        (total, amount) => total + parseFloat(amount || 0),
        0
      );
      if (totalSplitAmount > mainItemAmount) {
        toast.error(
          "Oops!Entered split amount is more then main items gross amount",
          {
            position: "top-right",
          }
        );
      }
      console.log(
        "Total Split Amount:",
        totalSplitAmount,
        " mainItemAmount",
        mainItemAmount
      );
    }
  }, [splitAmount]);

  const handleNumSplitsChange = (event) => {
    const value = parseInt(event.target.value);
    if (value > 5) {
      toast.error("Please enter Number of splits not more then 5.", {
        position: "top-right",
      });
      return;
    } else {
      setNumSplits(value);
      if (value) {
        setSplitAmount(Array(value).fill(""));
      }
    }
  };
  const handleInputChange = (event, index) => {
    const newInputValues = [...splitAmount];
    newInputValues[index] = event.target.value;
    setSplitAmount(newInputValues);
    const isEmpty = newInputValues.some(value => value === "");
    setIsAnySplitAmountEmpty(isEmpty);
  };
  return (
    <>
      <ModalBody>
        <AddressDiv>
          <div className="item-field">
            <label>Number of Splits:</label>
            <Input
              type="number"
              // defaultValue={numSplits}
              value={numSplits}
              onChange={handleNumSplitsChange}
              placeholder="Enter the number of splits (max 5)"
            />
          </div>
          <br />
          <hr /> <br />
          {numSplits > 0 && (
            <>
              <div>
                {splitAmount.map((value, index) => (
                  <div className="item-field">
                    <label>Split Amount {index + 1}:</label>
                    <Input
                      type="number"
                      key={index}
                      value={value}
                      onChange={(event) => handleInputChange(event, index)}
                      placeholder="Enter split amount"
                    />
                  </div>
                ))}{" "}
              </div>{" "}
            </>
          )}
        </AddressDiv>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" mr={3} 
          isDisabled={isAnySplitAmountEmpty}
        onClick={handleSplitAmountSubmit}>
          Split
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default SplitPopUp;
