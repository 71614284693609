import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import toast from "react-hot-toast";

import { Icons } from "../../Utils";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import { Select } from "antd";
import { API } from "../../Services";

export default function AddMember(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { Option } = Select;
  const state = useSelector((state) => state);
  const { trelloCardInfo, admins, trelloMembersList, trelloCommentList } =
    bindActionCreators(actionCreators, dispatch);
  const [selectedMemberId, setSelectedMemberId] = useState([]);

  useEffect(() => {
    admins();
  }, []);

  const AddSelectedMember = () => {
    // setLoad((prev)=>!prev)
    console.log(selectedMemberId);
    const IDs = selectedMemberId.map((d) => {
      return d.value;
    });
    console.log(IDs);
    var data = {
      // value:selectedMemberId.value
      memberID: IDs,
    };
    API.addMemberToList(props.id, data)
      .then((res) => {
        // setLoad((prev)=>!prev)
        console.log(res);
        toast.success(res.data.message || "Member Added successfully.", {
          position: "top-right",
        });
        trelloCardInfo(props.id);
        trelloCommentList(props.id, 20, 1);
        onClose();
        setSelectedMemberId("");
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        toast.error("Oops! Failed to Add Member.", {
          position: "top-right",
        });
        onClose();
      });
  };

  return (
    <>
      {" "}
      {/* <IconButton
        // colorScheme="green"
        size="sm"
        style={{
          background: "white",
          color: "var( --primary-dark-background)",
        }}
        icon={<Icons.Add />}
        onClick={() => {
          onOpen();
        }}
      /> */}
      <div
        // style={{display:"flex",justifyContent:"center",alignItems:"center"}}
        onClick={() => {
          onOpen();
        }}
      >
        <Icons.Add />
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Member To Card</ModalHeader>

          <ModalBody>
            <span className="ap-flex ap-fsm ap-fwmd ap-w100">
              Select Member
            </span>

            <Select
              showSearch
              size="middle"
              //  value={selectedMemberId}
              mode="multiple"
              labelInValue
              placeholder="Please Select Member name"
              //onSelect={(data) => {setSelectedMemberId(data)}}
              onChange={(data) => {
                setSelectedMemberId(data);
              }}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* <Option value={""}>Please select Member name</Option> */}
              {state.admins.admins.map((member) => (
                <Option value={member?.admin_id} key={member?.admin_id}>
                  {member?.admin_firstname + " " + member?.admin_lastname}
                </Option>
              ))}
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button
              className="submit_right1"
              onClick={() => {
                if (!props.isOrderList) {
                  props.SetCommentPage(1);
                }
                AddSelectedMember();
              }}
              size="sm"
              style={{ margin: "0 5px" }}
            >
              Add
            </Button>
            <Button
              className="submit_right1"
              onClick={onClose}
              size="sm"
              style={{ margin: "0 5px" }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
