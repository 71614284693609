import { axiosInstance } from ".."

const products = async (query='') =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`public/productsv2?${query}`).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default products;