import { API } from "../../Services";
import customers from "../../Services/Apis/customers";

export const partnersList = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PARTNER_LIST",
        })
    
        API.partnersList(query).then((res)=>{
            console.log(res);
            let data = JSON.parse(JSON.stringify(res)).data.customers
            data.map((customer,customerIndex)=>{
                customer.value = customer.id
                customer.label = `${customer.firstName} ${customer.lastName}`
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PARTNER_LIST",
                    payload:{data:data,totalRecords:res.data.totalRecords},
                })
            },1000)
        }).catch((err)=>{
            let data = JSON.parse(JSON.stringify(err?.response?.data?.message))

            dispatch({
                type:"ERROR_PARTNER_LIST",
                payload:data

            })
        })
    }
}

