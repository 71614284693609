const interiorDesignSubCategoriesListReducer = (
  state = {
    loading: false,
    data: null,
    totalRecords: 0,
    msg: "",
  },
  action
) => {
  switch (action.type) {
    case "FETCH_INTERIOR_DESIGN_SUB_CATEGORIES_LIST":
      return {
        ...state,
        loading: true,
        data: null,
        totalRecords: 0,
        msg: "",
      };
    case "RECEIVE_INTERIOR_DESIGN_SUB_CATEGORIES_LIST":
      return {
        ...state,
        data: [...action.payload?.data],
        loading: false,
        totalRecords: action.payload.recordsFiltered,
        msg: action.payload.msg,
      };
    case "ERROR_INTERIOR_DESIGN_SUB_CATEGORIES_LIST":
      return {
        ...state,
        data: null,
        loading: true,
        totalRecords: 0,
        msg: action.payload,
      };
    case "ERROR_INTERIOR_DESIGN_SUB_CATEGORIES_LIST_BLANK":
      return {
        ...state,
        data: null,
        loading: false,
        totalRecords: 0,
        msg: "",
      };
    default:
      return { ...state };
  }
};

export default interiorDesignSubCategoriesListReducer;