import {
  EditFilled,
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  DashboardOutlined,
  ProjectOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  WechatOutlined,
  UsergroupAddOutlined,
  TransactionOutlined,
  MessageOutlined,
  SearchOutlined,
  ClearOutlined,
  CloseOutlined,
  InboxOutlined,
  ClockCircleFilled,
  CopyOutlined,
  ScissorOutlined,
} from "@ant-design/icons";
import Orders from "../Assets/Icons/orders.png";
import CustomOrders from "../Assets/Icons/custom_orders.png";
import Update from "../Assets/Icons/update.png";
import Task from "../Assets/Icons/task.png";
import Logout from "../Assets/Icons/logout.png";
import Discount from "../Assets/Icons/discount.png";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import DetailsIcon from "@material-ui/icons/Details";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import {
  CheckBox,
  Notifications,
  SupervisedUserCircle,
  ArrowDropDown,
  Add,
  Attachment,
  Delete,
  MoveToInbox,
  PlaylistAddCheck,
  ArrowUpward,
  Person,
  Cloud,
  AccountBalanceWallet,
  Share,
  Ballot,
  CloudDownload,
  Send,
  WhatsApp,
  Check,
  Settings,
  Sync,
  MoreHoriz,
} from "@material-ui/icons";
import Loading from "../Assets/Images/circle_loader.gif";
const Icons = {
  Edit: EditFilled,
  Desktop: DesktopOutlined,
  PieChart: PieChartOutlined,
  File: FileOutlined,
  Team: TeamOutlined,
  User: UserOutlined,
  Dashboard: DashboardOutlined,
  Project: ProjectOutlined,
  List: UnorderedListOutlined,
  Orders: Orders,
  Plus: PlusOutlined,
  Chat: WechatOutlined,
  Draft: DraftsOutlinedIcon,
  Custom: CustomOrders,
  UserGroup: UsergroupAddOutlined,
  Payment: PaymentOutlinedIcon,
  Discount: Discount,
  Task: Task,
  CheckBox: CheckBox,
  Notifications: Notifications,
  Users: SupervisedUserCircle,
  ArrowDropDown: ArrowDropDown,
  Attachment: Attachment,
  Add: Add,
  Transaction: TransactionOutlined,
  Message: MessageOutlined,
  Logout: Logout,
  Search: SearchOutlined,
  Sweep: ClearOutlined,
  Details: DetailsIcon,
  Close: CloseOutlined,
  Update: Update,
  Inbox: InboxOutlined,
  Clock: ClockCircleFilled,
  MoveToInbox: MoveToInbox,
  Delete: Delete,
  Loading: Loading,
  PlaylistAddCheck: PlaylistAddCheck,
  ArrowUpward: ArrowUpward,
  UserFilled: Person,
  Cloud: Cloud,
  AccountBalanceWallet: AccountBalanceWallet,
  Share: Share,
  CopyOutlined: CopyOutlined,
  Catalog: Ballot,
  Download: CloudDownload,
  Send: Send,
  WhatsApp: WhatsApp,
  Check: Check,
  Settings: Settings,
  Split: ScissorOutlined,
  SyncIcon: Sync,
  DotIcon: MoreHoriz,
};

export default Icons;
