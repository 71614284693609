import styled from "styled-components";

export const MainContainerDiv = styled.div`
  display: flex;
  @media (max-width: 940px) {
    display: block; }
  padding: 2px;
`;
export const FilterDiv = styled.div`
  margin: 0 2px;
  // margin-top: 2%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #b4b3b3;
  height: 100%;
  width: 28%;
  @media (max-width: 940px) {
    width: 100%;  }
`;
export const ListDiv = styled.div`

width: 72%;
padding: 5px;
  margin: 0 2px;
  height: 100%;
  @media (max-width: 940px) {
    width: 100%;  }
`;
export const ListFooter = styled.div`
 margin-top:5px;

`;
export const ActionDiv = styled.div`
display:flex;

`;
export const FHeading = styled.h2`
  font-size: 18px;
  font-weight: 600;
  display: flex;
    justify-content: space-between;
    padding: 0 5px;
}
`;
export const FDiv = styled.div`
 margin-top:8px;
`;
export const FItem= styled.div`
margin-top:4px;
.f-lable{
  font-weight: 500;
    color: #73818f;
}
.p-check-group{
  .ant-checkbox-group {
    flex-wrap: wrap;
    flex-direction: row !important;
    .ant-checkbox-group-item {
      min-width: 150px;    }  
}
}
.f-check-group{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.f-radio-button{
  width:30%;
  .chakra-radio__label{
    font-size:15px !important;
  }
  @media (max-width: 940px) {
    width: 100%;  }
}
.f-input-group{
  width:50%;
 
    flex-flow: row;
  @media (max-width: 940px) {
    width: 100%;  }}
}
.f-sub-group{
  padding:0 2px;
  display: flex;
  flex-flow: row;
  .f-sub-input-group{
  .f-inline-field{
    display: flex;
    padding: 0 2px;
    .f-lable{
      margin: 0 2px;
    }
  }
  }
}
.ant-checkbox-group{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .ant-checkbox-group-item{
    min-width: 48% !important;
    margin-right: 0 !important;
  }
}
`;
export const PList = styled.div`
  display: flex;

  flex-wrap: wrap;
  flex-direction: row;

`;
export const LHeading = styled.h2`
  font-size: 18px;
  font-weight: 700;
`;
export const ProjectDiv = styled.div`
flex-direction: column;
  padding: 10px 2px;
  width: 32%;
 display: flex;
 margin: 2px 5px;
  @media (max-width: 1250px) {
    width: 45%;
  }
    @media (max-width: 760px) {
    width: 100%; }

    
`;
export const ProjectSlider = styled.div`
flex-grow: unset !important;
.MuiSvgIcon-root {
  fill: #39a037;
  margin-top: -0.2em;}
  img {
      max-height: 320px !important;
      object-fit: cover;
    }
`
export const PSliderImageDiv =styled.div`
width:95%;
.outer-img-div{
      height: 500px !important;
      overflow:auto !important;

      img{
        margin: 0 auto !important;

      }
}
//   .slick-dots {
//     top: -1rem !important;
// position:absolute !important;
// }
    .slick-dots li {
      width: 15px !important;
      height: 15px!important;}
      .slick-prev:before,
      .slick-next:before {
        color: black;
      }
    }
`
export const PImageDiv = styled.div`
min-height: 150px;

  // min-width: 200px;
  display: flex;
    justify-content: center;
  //width: 38%;
  max-height: 320px;
    .js-image-zoom__zoomed-area{
      background:#0f1010 !important
    }
  .js-image-zoom__zoomed-image{
    z-index: 1;
    border: 2px solid #181918e3;
    @media (min-width: 1150px) {
      left: 1.5rem !important;
    transform: translateY(28%) !important;
      position: fixed !important;}
  }
 
  padding: 5px;
    background: #e6e6e6;
  img {
  //   min-width: 180px;
  // min-height: 142px;
    // max-width: 300px !important;
    // max-height: 182px !important;
    object-fit: cover;
  }
  
`;

export const PInfoDiv = styled.div`
padding:0 5px;
  .p-name {
    padding: 2px;
    font-weight: 650;
    color: gray;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .p-id {
    padding: 2px;
    font-weight: 500;
    //color: gray;
  }
  .p-buttons {
    // padding:2px 0;
    display: block;
    .ap-btn-c {
      margin: 2px;
    }
  }

  .p-basic-details{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: gray;
    padding: 0 8px;
}
  }
`;
