import { axiosInstance } from "..";

const units = async (query='') => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .get(`/internal/interiordesign/units?${query}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res);
        }
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export default units;
