import { API } from "../../Services";

export const products = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PRODUCTS",
        })
         API.products(query).then((res)=>{
            console.log(res.data)
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PRODUCTS",
                    payload:res.data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_PRODUCTS",
            })
        })
    }
}