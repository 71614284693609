import { axiosInstance } from ".."

const downlineAdmins = async (querry="") =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`internal/user/admins?showDownline=1${querry}`).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default downlineAdmins;