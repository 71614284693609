import React, { useState, useEffect } from "react";
import "./Projects.scss";
import { 
    Card,
    Spin,
    Table,
    Tag
} from 'antd';
import {
    Button
} from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";


const Projects = (props) =>{

    const [ current,setCurrent ] = useState({
        page:1,
        size:10,
    })
    const [ tableData, setTableData ] = useState([]) 
    const { client_id } = useParams();
    const history = useHistory();

    const columns = [
        {
            title:<div className="ap-fsm ap-fwmd">Project id</div>,
            dataIndex:"projectId",
            key:"projectId",
            render:text=><div className="ap-fsm">{text}</div>,
            hidden:true
        },
        {
            title:<div className="ap-fsm ap-fwmd">Project name</div>,
            dataIndex:"projectName",
            key:"projectName",
            render:text=><div className="ap-fsm">{text}</div>,
            width:"300px",
            fixed:"left",
        },
        {
            title:<div className="ap-fsm ap-fwmd">Project code</div>,
            dataIndex:"projectCode",
            key:"projectCode",
            render:text=><div className="ap-fsm">{text}</div>,
        },
        {
            title:<div className="ap-fsm ap-fwmd">Created by</div>,
            dataIndex:"createdBy",
            key:"createdBy",
            render:text=><div className="ap-fsm">{text}</div>
        },
        {
            title:<div className="ap-fsm ap-fwmd">Assigned to</div>,
            dataIndex:"assignedTo",
            key:"assignedTo",
            render:text=><div className="ap-fsm">{text}</div>
        },
        {
            title:<div className="ap-fsm ap-fwmd">Mode</div>,
            dataIndex:"mode",
            key:"mode",
            render:text=><div className="ap-fsm">{text}</div>
        },
        {
            title:<div className="ap-fsm ap-fwmd">Status</div>,
            dataIndex:"status",
            key:"status",
            render:status=>(
                <Tag size='sm'color={status === "Active" ? " var( --primary-green)":"yellow"}>
                    <div className="ap-fsm">{status}</div>
                </Tag>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button 
                    size="sm"
                    className="submit_right1"
                    onClick={()=>
                        history.push(`/home/ap/project/details/${record.projectId}/${record.customerId}/${record?.projectCode}`)
                    }
                >
                    Show details 
                </Button>
            ),
            width:"fit-content",
            fixed:"right",
          },
    ].filter(item => !item.hidden);

    useEffect(()=>{
        if(props.state.projectsList.projectsList.length > 0){
            setTableData((prev)=>{
                prev = []
                props.state.projectsList.projectsList.map((project,projectIndex)=>{
                    prev.push({
                        key:projectIndex,
                        projectId:project.ID,
                        projectCode:project.projectCode,
                        projectName:project.name,
                        createdBy:project.createdBy?.name,
                        assignedTo:project.assignedTo?.name,
                        mode:project?.mode,
                        status:project.status,
                        customerId:project.customer?.ID,
                        projectCode:project?.projectCode
                    })
                })
                return prev
            })
        }else{
            setTableData([])
        }
    },[props.state])

    useEffect(()=>{
        props.projectsList(`customerID=${client_id}&limit=${current.size}&page=${current.page}`)
    },[current])

    return(
        <div className="ap-flex ap-w100" style={{marginTop:"10px"}}>
            <Card 
                size="small" 
                style={{width:"100%"}}
            >
                <div 
                    className="ap-flex ap-w100 ap-fmd ap-fwmd" 
                    style={{marginBottom:'5px'}}
                >
                    Client projects
                </div>
                {!props.state.projectsList.loading ? <Table 
                    columns={columns} 
                    dataSource={tableData} 
                    scroll={{ x: 1000 }}
                    size='small' 
                    pagination={
                        {
                            current:current.page,
                            pageSize:current.size,
                            position:["bottomRight"],
                            total:props.state.projectsList.totalRecords,
                            onChange:(data,size)=>{setCurrent({page:data,size:size})},
                            showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }
                    }
                />:
                <div 
                    className="ap-flex ap-w100 ap-flex-center-center" 
                    style={{height:"300px"}}
                >
                    <Spin tip="Fetching Records....."/>
                </div>}
            </Card>
        </div>    
    ) 
}

export default Projects;