
const invoicesListReducer = (state = {
    loading:false,
    invoices:[],totalRecords:0,msg:''
}, action) => {
    switch (action.type) {
        case "FETCH_INVOICES_LIST":
            return { ...state ,loading:true,invoices:[],totalRecords:0,msg:''}
        case "RECEIVE_INVOICES_LIST":
            return {...state, invoices:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords,msg:''}
        case "ERROR_INVOICES_LIST":
            return { ...state ,invoices:[],loading:true,totalRecords:0,msg:action.payload}
        default:
            return { ...state }
    }
}

export default invoicesListReducer;