import { API } from "../../Services";
import customers from "../../Services/Apis/customers";

export const paymentLinkList = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PAYMENT_LINK_LIST",
        })
    
        API.paymentLinkList(query).then((res)=>{
            console.log(res);
            let data = JSON.parse(JSON.stringify(res)).data.linklist
          
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PAYMENT_LINK_LIST",
                    payload:{data:data,totalRecords:res.data.totalPaymentLink
                    },
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_PAYMENT_LINK_LIST",
            })
        })
    }
}

