import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Spin } from "antd";
import ZoomImage from "./ZoomImage";
import { ZoomSliderDiv } from "./interioriDesignListingStyle";

export default function PreviewSlider({ BaseUrl, imageList }) {
  const [processedImages, setProcessedImages] = useState([]);
  const [isLoader, setLoader] = useState(true);

  useEffect(() => {
    const createImageUrls = () => {
      const urlImages = imageList.map((item) => ({
        ...item,
        thumbnailUrl: `${BaseUrl}/internal/preview/rimage/100/${item}`,
        previewUrl: `${BaseUrl}/internal/preview/rimage/1024/${item}`,
      }));
      return urlImages;
    };

    setProcessedImages(createImageUrls());
  }, [BaseUrl, imageList]);

  useEffect(() => {
    if (processedImages?.length > 0) {
      setLoader(false);
    }
  }, [processedImages]);

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            src={processedImages[i]?.thumbnailUrl}
            // src={`${BaseUrl}/internal/preview/rimage/100/${imageList[i]?.filePath}`}
            alt=""
            style={{ height: "45px", width: "50px", objectFit: "cover" }}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {isLoader ? (
        <div className="slider-container flex flex-center-center">
          <Spin />
          <span>Loading...</span>
        </div>
      ) : (
        <ZoomSliderDiv>
          <div className="slider-container">
            <Slider {...settings}>
              {processedImages.map((item, index) => (
                <div key={index}>
                  <ZoomImage
                    url={item.previewUrl}
                    // url={`${BaseUrl}/internal/preview/rimage/1024/${item?.filePath}`}
                  />
                
                </div>
              ))}
            </Slider>
          </div>
        </ZoomSliderDiv>
      )}
    </div>
  );
}
