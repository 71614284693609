import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Card, DatePicker, Input, Select, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FTIcon from "react-file-type-icons";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { BaseUrl } from "../../../chatapp/apis/Networks";
import { SpinLoader } from "../../../Components";
import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";
import moment from "moment";
import "moment-timezone";
import CadIcon from "../../../Assets/Icons/cad.png";
import UploadAttachment from "./UploadAttachment";
import {
  ActionButons,
  AttachementListDiv,
  FilterDiv,
  Status,
  TextHighlight,
} from "./attachementStyle";
import { API } from "../../../Services";
import toast from "react-hot-toast";
import SetApprovalStatus from "./SetApprovalStatus";
import axios from "axios";

export default function Attachments(props) {
  const [attachementList, setAttachementList] = useState([]);
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isClientApprovalOpen,
    onOpen: onClientApprovalOpen,
    onClose: onClientApprovalClose,
  } = useDisclosure();
  const {
    isOpen: isApprovalOpen,
    onOpen: onApprovalOpen,
    onClose: onApprovalClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const [checkedList, setCheckedList] = useState([]);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState("");
  const [sendToClientActive, setSendToClientActive] = useState(false);
  const [isCurrent, setCurrentChange] = useState(false);
  const [clientResponseActive, setClientResponseActive] = useState(false);
  const [setApprovalStatusActive, setSetApprovalStatusActive] = useState(false);
  const [setDeliveredActive, setSetDeliveredActive] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [urlOpenLoader, setUrlOpenLoader] = useState(false);
  const [urlOpenId, setUrlOpenId] = useState("");

  const [ServiceList, setServiceList] = useState([]);
  const { RangePicker } = DatePicker;
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { Option } = Select;
  const [current, setCurrent] = useState({
    page: 1,
    size: 5,
  });
  const {
    trelloAttachmentList,
    trelloCommentList,
    projectAttachementCategories,
    projectAttachementSubCategories,
  } = bindActionCreators(actionCreators, dispatch);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  useEffect(() => {
    projectAttachementCategories();
  }, []);

  const handleLoadMore = () => {
    let data = current.page + 1;
    setCurrent({ page: data, size: 5 });
  };
  const renderFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {hasMore ? (
          <Button type="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        ) : (
          <span>No more data</span>
        )}
      </div>
    );
  };
  const getUrl = (value, urlId) => {
    setUrlOpenLoader(true);
    setUrlOpenId(urlId);
    console.log("url", value);
    const config = {
      headers: {
        "X-API-Key": localStorage.getItem("token"),
      },
    };
    axios
      .get(value, config)
      .then((response) => {
        console.log("response.data", response);
        setUrlOpenLoader(false);
        setUrlOpenId("");
        window.open(response?.data?.data?.url, "_blank");
      })
      .catch((error) => {
        setUrlOpenLoader(false);
        setUrlOpenId("");

        console.error("Error fetching data:", error);
        toast.error("Error", error, {
          position: "top-right",
        });
      });
  };
  const handelOnClickAction = (buttonType, data) => {
    setLoader(buttonType);
    switch (buttonType) {
      case "sendToClient":
        {
          let data = {
            attachmentIds: checkedList,
            command: "approval",
            description: "",
            ordercode: id,
            projectId: props.projectId,
            title: "",
          };
          API.sendToClient(data)
            .then((res) => {
              setCheckedList([]);
              setSelectedRowKeys([]);
              callRecords({ payload: { page: 1, size: 5 } });
              trelloCommentList(id, 20, 1);
              setLoader("");
              toast.success(res?.data?.message, {
                position: "top-right",
              });
            })
            .catch((err) => {
              setLoader("");
              toast.error("Error", err, {
                position: "top-right",
              });
            });
        }
        break;
      case "markDeliverable":
        {
          // let formData = new FormData();
          // formData.append("attachmentIDs[]", checkedList);
          let formData = {
            attachmentIDs: checkedList,
          };
          API.markDeliverable(id, formData)
            .then((res) => {
              setCheckedList([]);
              setSelectedRowKeys([]);
              callRecords({ payload: { page: 1, size: 5 } });
              trelloCommentList(id, 20, 1);
              // console.log(res);
              setLoader("");

              toast.success(res?.data?.message, {
                position: "top-right",
              });
            })
            .catch((err) => {
              setLoader("");

              toast.error("Error", err, {
                position: "top-right",
              });
            });
        }
        break;
      case "setApprovalStatus":
        {
          console.log(data);
          // let formData = new FormData();
          // formData.append("approvalStatus", data?.approvalStatus);
          // formData.append("approvalComment", data?.approvalComment);
          // formData.append("attachmentIDs[]", checkedList);
          let formData = {
            attachmentIDs: checkedList,
            approvalStatus: data?.approvalStatus,
            approvalComment: data?.approvalComment,
          };

          API.setApprovalStatus(id, formData)
            .then((res) => {
              setCheckedList([]);
              setSelectedRowKeys([]);
              callRecords({ payload: { page: 1, size: 5 } });
              trelloCommentList(id, 20, 1);
              // console.log(res);
              setLoader("");
              toast.success(res?.data?.message, {
                position: "top-right",
              });
            })
            .catch((err) => {
              setLoader("");
              toast.error("Error", err, {
                position: "top-right",
              });
            });
        }
        break;
      case "setClientApprovalStatus":
        {
          console.log(data);
          // let formData = new FormData();
          // formData.append(
          //   "clientApprovalStatus",
          //   data?.approvalStatus == "approved" ? "approved" : "declined"
          // );
          // formData.append("clientApprovalComment", data?.approvalComment);
          // formData.append("attachmentIDs", JSON.stringify(checkedList));
          let formData = {
            attachmentIDs: checkedList,
            clientApprovalComment: data?.approvalComment,
            clientApprovalStatus:
              data?.approvalStatus == "approved" ? "approved" : "declined",
          };

          API.setClientApprovalStatus(id, formData)
            .then((res) => {
              setCheckedList([]);
              setSelectedRowKeys([]);
              callRecords({ payload: { page: 1, size: 5 } });
              trelloCommentList(id, 20, 1);
              // console.log(res);
              setLoader("");
              toast.success(res?.data?.message, {
                position: "top-right",
              });
            })
            .catch((err) => {
              setLoader("");
              toast.error("Error", err, {
                position: "top-right",
              });
            });
        }
        break;
      default:
        return "";
    }
  };
  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      const needApprovalYesAndApproved = selectedRowKeys.every(
        (row) => row.needAproval === "Yes" && row.approvalStatus === "approved"
      );
      console.log("needApprovalYesAndApproved", needApprovalYesAndApproved);
      setSendToClientActive(needApprovalYesAndApproved);

      const needApprovalYesAndDelivered = selectedRowKeys.every(
        (row) => row.needAproval === "Yes" && row.sendToClint === "Yes"
      );
      setClientResponseActive(needApprovalYesAndDelivered);

      const needApprovalYes = selectedRowKeys.every(
        (row) => row.needAproval === "Yes" && row.approvalStatus === "pending"
      );
      setSetApprovalStatusActive(needApprovalYes);

      const needApproval = selectedRowKeys.every(
        (row) => row.needAproval === "Yes"
      );
      const markDelivrable = selectedRowKeys.every(
        (row) => row.needAproval === "No"
      );
      setSetDeliveredActive(markDelivrable);
      if (sendToClientActive && !needApprovalYesAndApproved) {
        setSendToClientActive(false);
      }
      if (clientResponseActive && !needApprovalYesAndDelivered) {
        setClientResponseActive(false);
      }
      if (setApprovalStatusActive && !needApprovalYes) {
        setSetApprovalStatusActive(false);
      }
    } else {
      setSendToClientActive(false);
      setSetApprovalStatusActive(false);
      setClientResponseActive(false);
      setSetDeliveredActive(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    if (state.trelloCheckList?.list) {
      let list = state.trelloCheckList?.list?.map((item) => ({
        name: item.name,
        value: item.ID,
      }));
      setOrderList(list);
    }
  }, [state.trelloCheckList]);
  useEffect(() => {
    console.log("formData?.orderID", formData?.orderID);
    if (formData?.orderID) {
      let list = state.trelloCheckList?.list
        ?.filter((item) => item?.ID == formData?.orderID)
        .map((order) =>
          order.services.map((service) => ({
            name: service.name,
            value: service.ID,
          }))
        );
      console.log("list", list[0]);
      setServiceList(list[0]);
    }
  }, [formData?.orderID]);
  useEffect(() => {
    console.log("formData?.orderID", formData?.category);
    if (formData?.category) {
      projectAttachementSubCategories(formData?.category);
    }
  }, [formData?.category]);
  const onSelectChange = (checkedList, selectedRowKeys) => {
    setCheckedList(checkedList);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    checkedList,
    onChange: onSelectChange,
  };
  const handelFormData = (event) => {
    setFormData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));

      prev[`${event.target.name}`] = event.target.value;

      return prev;
    });
  };
  const callRecords = (event) => {
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      let querry = "";
      Object.entries(formData).map(([key, value], formDataIndex) => {
        querry = querry + `&${key}=${value}`;
      });
      querry = `page=${current?.page}&limit=${current?.size}` + querry;
      console.log(querry);
      trelloAttachmentList(id, querry);
      setCurrentChange(true);
    } else {
      let querry = "";
      querry = `page=${current?.page}&limit=${current?.size}`;
      trelloAttachmentList(id, querry);
      setCurrentChange(true);
    }
  }, [current]);

  useEffect(() => {
    if (state.trelloAttachmentList?.attachmentList.length > 0) {
      // if (current.page > 1 && isCurrent) {
      //   let newDataList = [
      //     ...data,
      //     ...state.trelloAttachmentList?.attachmentList,
      //   ];
      //   const Items = newDataList.filter(
      //     (item, index, self) =>
      //       index === self.findIndex((t) => t._id === item._id)
      //   );
      //   setCurrentChange(false);
      //   setData(Items);
      //   setHasMore(state.trelloAttachmentList.totalRecords > Items.length);
      //   setAttachementList((prev) => {
      //     prev = [];
      //     Items.map((attachment, index) => {
      //       prev.push({
      //         fileName: attachment?.originalName || "N/A",
      //         fileUrl:attachment?.fileName|| "N/A",
      //         createdBy: attachment?.uploadedBy?.name || "N/A",
      //         createdOn: attachment?.datetime || "N/A",
      //         clientApprovalComment: attachment?.clientApprovalComment,
      //         clientApprovalDate: attachment?.clientApprovalDate,
      //         approvedOnBehalfOfClientBy:
      //           attachment?.approvedOnBehalfOfClientBy,
      //         clientApprovalStatus:
      //           attachment?.needApproval == "No"
      //             ? "-"
      //             : attachment?.clientApprovalStatus || "N/A",
      //         needAproval: attachment?.needApproval || "N/A",
      //         sendToClint:
      //           attachment?.needApproval == "No"
      //             ? "-"
      //             : attachment?.isSendToClient || "N/A",
      //         clintAproval:
      //           attachment?.needApproval == "No"
      //             ? "-"
      //             : attachment?.needClientApproval || "N/A",
      //         delieverd:
      //           attachment?.needApproval == "No"
      //             ? "-"
      //             : attachment?.delivered || "N/A",
      //         filePath: attachment?.filePath || "",
      //         type: attachment?.type || "",
      //         key: attachment?._id,
      //         approvalStatus:
      //           attachment?.needApproval == "No"
      //             ? "-"
      //             : attachment?.approvalStatus || "N/A",
      //         category: attachment?.categoryDetails?.category || "N/A",
      //         serviceName: attachment?.serviceDetails?.serviceName || "-",
      //       });
      //     });
      //     return prev;
      //   });
      // } else {
      let newData = state.trelloAttachmentList.attachmentList;
      setData(newData);
      setAttachementList((prev) => {
        prev = [];
        newData.map((attachment, index) => {
          prev.push({
            fileName: attachment?.originalName || "N/A",
            fileUrl: attachment?.fileName || "N/A",
            approvalComment: attachment?.approvalComment,
            approvalDate: attachment?.approvalDate,
            approvedBy: attachment?.approvedBy,
            clientApprovalStatus:
              attachment?.needApproval == "No"
                ? "-"
                : attachment?.clientApprovalStatus || "N/A",
            createdBy: attachment?.uploadedBy?.name || "N/A",
            clientApprovalComment: attachment?.clientApprovalComment,
            clientApprovalDate: attachment?.clientApprovalDate,
            approvedOnBehalfOfClientBy: attachment?.approvedOnBehalfOfClientBy,
            createdOn: attachment?.uploadedAt || "N/A",
            needAproval: attachment?.needApproval || "N/A",
            customerDetails: attachment?.customerDetails || "N/A",
            sendToClint:
              attachment?.needApproval == "No"
                ? "-"
                : attachment?.isSendToClient || "N/A",
            clintAproval:
              attachment?.needApproval == "No"
                ? "-"
                : attachment?.needClientApproval || "N/A",
            delieverd:
              attachment?.needApproval == "No"
                ? "-"
                : attachment?.delivered || "N/A",
            filePath: attachment?.filePath || "",
            type: attachment?.type || "",
            key: attachment?._id,
            approvalStatus:
              attachment?.needApproval == "No"
                ? "-"
                : attachment?.approvalStatus || "N/A",
            category: attachment?.categoryDetails?.category || "N/A",
            serviceName: attachment?.serviceDetails?.serviceName || "-",
          });
        });
        return prev;
      });
      setHasMore(state.trelloAttachmentList.totalRecords > newData.length);
      // }
    } else {
      setAttachementList([]);
    }
  }, [state]);

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">File name</div>,
      dataIndex: "fileName",
      key: "fileName",
      fixed: "left",
      width: 200,
      render: (text, record) => (
        <div className="ap-fsm">
          {" "}
          <div
            className="ap-flex-center  ap-flex "
            style={{
              alignItems: "center",
              padding: "15px",
              margin: "0rem 0rem 0rem -1.9rem",
            }}
          >
            {record?.filePath?.split(".").pop() !== ("cad" || "CAD") ? (
              <span className="file-icon">
                <FTIcon
                  fileName={record?.filePath?.split(".")?.pop() || "url"}
                  style={{ fontSize: "40px" }}
                  color="var(--primary-green)"
                  fileType={"url"}
                />
              </span>
            ) : (
              <img src={CadIcon} style={{ width: "25px" }} alt="" />
            )}
           { record?.fileUrl.includes("https")?
            <a
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
              href={
                record?.type == "url"
                  ? record?.filePath
                  : record?.fileUrl.includes("https")
                  ? record?.fileUrl
                  : BaseUrl +
                    "internal/storage/" +
                    record?.fileUrl +
                    "?mediaUrl=1"
              }
              target="_blank"
            >
              {text}
            </a>:
            <div
              className="attach-link"
              onClick={() => {
                const url =BaseUrl +
                      "internal/storage/" +
                      record?.fileUrl +
                      "?mediaUrl=1&type=url";
                getUrl(url, record.key);
              }}
            >
              {urlOpenLoader && urlOpenId == record.key ? (
                <span style={{ padding: "0 1rem" }}>
                  <Spin />
                </span>
              ) : (
                text
              )}
            </div>}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div
          className="ap-fsm ap-fwmd"
          style={{ width: "6.9vw", margin: "0rem 0rem 0rem -0.8rem" }}
        >
          Uploaded By{" "}
        </div>
      ),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text) => (
        <div
          className="ap-fsm"
          style={{ width: "6.4vw", margin: "0 0 0 -0.5rem" }}
        >
          {text}
        </div>
      ),
    },
    {
      title: (
        <div className="ap-fsm ap-fwmd" style={{ width: "4.1rem" }}>
          Created On{" "}
        </div>
      ),
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => (
        <div className="ap-fsm" style={{ width: "6vw", margin: "0 -4px" }}>
          {moment.unix(text).format("MMM D, YYYY [at] h:mm A z")}
        </div>
      ),
    },
    {
      title: (
        <div
          className="ap-fsm ap-fwmd"
          style={{ width: "4.6rem", margin: "0 0 0 -8px" }}
        >
          Category{" "}
        </div>
      ),
      dataIndex: "category",
      key: "category",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: (
        <div className="ap-fsm ap-fwmd" style={{ width: "5.1vw" }}>
          Service Name{" "}
        </div>
      ),
      dataIndex: "serviceName",
      key: "serviceName",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: (
        <div
          className="ap-fsm ap-fwmd"
          style={{ width: "4.8vw", margin: "0 -6px" }}
        >
          Need Approval
        </div>
      ),
      dataIndex: "needAproval",
      key: "needAproval",
      render: (text) => (
        <div className="ap-fsm">
          <TextHighlight>
            <span className={text}>{text}</span>
          </TextHighlight>
        </div>
      ),
    },
    {
      title: (
        <div
          className="ap-fsm ap-fwmd"
          style={{ width: "5vw", margin: "0 -8px" }}
        >
          Internal Approval Status
        </div>
      ),
      dataIndex: "approvalStatus",
      key: "approvalStatus",
      render: (text, record) => (
        <div className="ap-fsm">
          <Status
            title={
              text === "approved"
                ? record?.approvedBy.length == 0
                  ? text
                  : `Status: Approved \nApprovers Name: ${
                      record?.approvedBy?.name
                    }\nApprovers No: ${
                      record?.approvedBy?.mobile
                    }\nApprovers email: ${
                      record?.approvedBy?.email
                    }\nApprovers Comment: ${
                      record?.approvalComment
                    } \nApproval Date: ${moment
                      .unix(record?.approvalDate)
                      .format("MMM D, YYYY [at] h:mm A z")} \n`
                : text
            }
          >
            <div className={`${text}-icon icon-div`}></div>
          </Status>
          {/* <Status title={text}>
            <div className={`${text}-icon icon-div`}></div>
          </Status> */}

          {record.needAproval == "No" ? "-" : ""}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">Send To Client</div>,
      dataIndex: "sendToClint",
      key: "sendToClint",
      render: (text) => (
        <div className="ap-fsm">
          <TextHighlight>
            <span className={text}>{text}</span>
          </TextHighlight>
        </div>
      ),
    },

    {
      title: (
        <div
          className="ap-fsm ap-fwmd"
          style={{ width: "5vw", margin: "0 -5px" }}
        >
          Client Approval Status
        </div>
      ),
      dataIndex: "clientApprovalStatus",
      key: "clientApprovalStatus",
      // render: (text) => <div className="ap-fsm" >{text} </div>,

      render: (text, record) => (
        <div
          className="ap-fsm"
          onClick={() => {
            console.log("text", text, " ", "record", record);
          }}
        >
          <Status
            title={
              text === "approved"
                ? record?.customerDetails.length != 0
                  ? `Status: Approved \nApprovers Name: ${
                      record?.customerDetails?.name
                    }-(Customer)\nApprovers No: ${
                      record?.customerDetails?.mobile
                    }\nApprovers email: ${
                      record?.customerDetails?.email
                    }\nApprovers Comment: ${
                      record?.clientApprovalComment
                    } \nApproval Date: ${moment
                      .unix(record?.clientApprovalDate)
                      .format("MMM D, YYYY [at] h:mm A z")} \n`
                  : `Status: Approved \nApproved By: ${
                      record?.approvedOnBehalfOfClientBy?.name
                    }\nApprovers No: ${
                      record?.approvedOnBehalfOfClientBy?.mobile
                    }\nApprovers email: ${
                      record?.approvedOnBehalfOfClientBy?.email
                    }\nApprovers Comment: ${
                      record?.clientApprovalComment
                    } \nApproval Date: ${moment
                      .unix(record?.clientApprovalDate)
                      .format("MMM D, YYYY [at] h:mm A z")} \n`
                : text
            }
          >
            <div className={`${text}-icon icon-div`}></div>
          </Status>
          {/* <Status title={`${text=='approved'?text:text}` }>
            <div className={`${text}-icon icon-div`}></div>
          </Status> */}
          {record.needAproval == "No" ? "-" : ""}
        </div>
      ),
    },

    // {
    //   title: (
    //     <div
    //       className="ap-fsm ap-fwmd"
    //       style={{
    //         width: "7.8vw",
    //         margin: "0 -14px",
    //         padding: "0 1px",
    //         fontSize: "14px",
    //       }}
    //     >
    //       Delievered
    //     </div>
    //   ),
    //   dataIndex: "delieverd",
    //   key: "delieverd",
    //   render: (text) => <div className="ap-fsm"><TextHighlight><span className={text}>{text}</span></TextHighlight></div>,
    // },
  ].filter((item) => !item.hidden);

  return (
    <div>
      <span style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>
          <Icons.Attachment />
          <span style={{ padding: "0 8px" }}>
            Attachments
            <Button
              className="submit_right1"
              style={{
                color: "white",
                marginTop: 13,
                marginLeft: 17,
                marginBottom: 13,
              }}
              onClick={() => {
                window.open(
                  `${BaseUrl}/initsession?token=${localStorage.getItem(
                    "token"
                  )}`
                );
              }}
            >
              Initiate session
            </Button>
          </span>
        </h2>
      </span>
      <div
        style={{
          padding: "12px 25px",
          overflow: "auto",
          width: "100%",
        }}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton
              style={{
                backgroundColor: " var(--primary-dark-background)",
                color: "white",
              }}
            >
              <Box flex="1" textAlign="left">
                Filter
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={2}>
              <FilterDiv>
                <Card style={{ width: "100%" }}>
                  <div className="ap-flex ap-w100">
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Order
                      </div>
                      <Select
                        size="large"
                        showSearch
                        value={formData?.orderID}
                        labelInValue
                        placeholder="Select Order"
                        style={{ width: "100%" }}
                        loading={state.admins.loading}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: {
                              name: "orderID",
                              ...data,
                            },
                          })
                        }
                      >
                        {orderList.map((order, adminIndex) => (
                          <Option value={order.value} key={adminIndex}>
                            {order.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Service
                      </div>

                      <Select
                        size="large"
                        showSearch
                        value={formData?.serviceID}
                        labelInValue
                        disabled={!formData?.orderID}
                        placeholder="Select service"
                        style={{ width: "100%" }}
                        loading={state.admins.loading}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: {
                              name: "serviceID",
                              ...data,
                            },
                          })
                        }
                      >
                        {ServiceList?.map((service, adminIndex) => (
                          <Option value={service.value} key={adminIndex}>
                            {service.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Category
                      </div>

                      <Select
                        size="large"
                        showSearch
                        value={formData?.category}
                        labelInValue
                        placeholder="Select Category"
                        style={{ width: "100%" }}
                        loading={state.admins.loading}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: {
                              name: "category",
                              ...data,
                            },
                          })
                        }
                      >
                        {state.projectAttachementCategories?.categories?.map(
                          (cat, adminIndex) => (
                            <Option value={cat.ID} key={adminIndex}>
                              {cat.name}
                            </Option>
                          )
                        )}
                      </Select>
                    </div>
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Sub Category
                      </div>

                      <Select
                        size="large"
                        showSearch
                        disabled={!formData?.category}
                        value={formData?.subCategory}
                        labelInValue
                        placeholder="Select Sub Category"
                        style={{ width: "100%" }}
                        loading={state.admins.loading}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: {
                              name: "subCategory",
                              ...data,
                            },
                          })
                        }
                      >
                        {state.projectAttachementSubCategories?.subCategories?.map(
                          (cat, adminIndex) => (
                            <Option value={cat.ID} key={adminIndex}>
                              {cat.name}
                            </Option>
                          )
                        )}
                      </Select>
                    </div>
                  </div>
                  <div className="ap-flex ap-w100">
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Uploaded By
                      </div>
                      <Select
                        size="large"
                        showSearch
                        value={formData?.uploadedBy}
                        labelInValue
                        placeholder="Select uploadedBy"
                        style={{ width: "100%" }}
                        loading={state.admins.loading}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: { name: "uploadedBy", ...data },
                          })
                        }
                      >
                        {state.admins.admins.map((admin, adminIndex) => (
                          <Option value={admin.value} key={adminIndex}>
                            {admin.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Approved By
                      </div>
                      <Select
                        showSearch
                        size="large"
                        value={formData?.approvedBy}
                        labelInValue
                        placeholder="Select approvedBy"
                        style={{ width: "100%" }}
                        loading={state.admins.loading}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) => {
                          handelFormData({
                            target: { name: "approvedBy", ...data },
                          });
                        }}
                      >
                        {state.admins.admins.map((admin, adminIndex) => (
                          <Option value={admin.value} key={admin.value}>
                            {admin.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="ap-flex ap-flex-center-center ap-w33">
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Approved On Behalf Of Client By
                      </div>
                      <Select
                        size="large"
                        showSearch
                        value={formData?.approvedOnBehalfOfClientBy}
                        labelInValue
                        placeholder="Select approvedOnBehalfOfClientBy"
                        style={{ width: "100%" }}
                        loading={state.admins.loading}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: {
                              name: "approvedOnBehalfOfClientBy",
                              ...data,
                            },
                          })
                        }
                      >
                        {state.admins.admins.map((admin, adminIndex) => (
                          <Option value={admin.value} key={adminIndex}>
                            {admin.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="ap-flex ap-w100">
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Approved On Behalf Of Client
                      </div>
                      <Select
                        size="large"
                        showSearch
                        value={formData?.approvedOnBehalfOfClient}
                        labelInValue
                        placeholder="Select"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: {
                              name: "approvedOnBehalfOfClient",
                              ...data,
                            },
                          })
                        }
                      >
                        <Option value={1}>Yes</Option>
                        <Option value={0}>No</Option>
                      </Select>
                    </div>

                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Internal Approval Status
                      </div>
                      <Select
                        size="large"
                        showSearch
                        value={formData?.approvalStatus}
                        labelInValue
                        placeholder="Select"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: { name: "approvalStatus", ...data },
                          })
                        }
                      >
                        <Option value={"approved"}>Approved</Option>
                        <Option value={"pending"}>Pending</Option>
                        <Option value={"declined"}>Declined</Option>
                      </Select>
                    </div>
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Delivered
                      </div>
                      <Select
                        size="large"
                        showSearch
                        value={formData?.delivered}
                        labelInValue
                        placeholder="Select"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(data) =>
                          handelFormData({
                            target: { name: "delivered", ...data },
                          })
                        }
                      >
                        <Option value={1}>Yes</Option>
                        <Option value={0}>No</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="ap-flex ap-w100">
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Upload date Range
                      </div>
                      <span
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-start",
                        }}
                      >
                        <RangePicker
                          value={
                            formData?.uploadedStartAt
                              ? [
                                  moment(formData?.uploadedStartAt),
                                  moment(formData?.uploadedEndAt),
                                ]
                              : ""
                          }
                          onChange={(momentData, Date) => {
                            handelFormData({
                              target: {
                                name: "uploadedStartAt",
                                value: moment(momentData[0]._d)?.format(
                                  "YYYY-MM-DD"
                                ),
                              },
                            });
                            handelFormData({
                              target: {
                                name: "uploadedEndAt",
                                value: moment(momentData[1]._d)?.format(
                                  "YYYY-MM-DD"
                                ),
                              },
                            });
                          }}
                          //format={dateFormat}
                        />
                      </span>
                    </div>
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Send To Client date Range
                      </div>
                      <span
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-start",
                        }}
                      >
                        <RangePicker
                          value={
                            formData?.sendToClientStartDate
                              ? [
                                  moment(formData?.sendToClientStartDate),
                                  moment(formData?.sendToClientEndDate),
                                ]
                              : ""
                          }
                          onChange={(momentData, Date) => {
                            handelFormData({
                              target: {
                                name: "sendToClientStartDate",
                                value: moment(momentData[0]._d)?.format(
                                  "YYYY-MM-DD"
                                ),
                              },
                            });
                            handelFormData({
                              target: {
                                name: "sendToClientEndDate",
                                value: moment(momentData[1]._d)?.format(
                                  "YYYY-MM-DD"
                                ),
                              },
                            });
                          }}
                          //format={dateFormat}
                        />
                      </span>
                    </div>
                    <div
                      className="ap-flex ap-flex-center-center ap-w33"
                      style={{ margin: "0 10px" }}
                    >
                      <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                        Client Approval date Range
                      </div>
                      <span
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-start",
                        }}
                      >
                        <RangePicker
                          value={
                            formData?.clientApprovalStartDate
                              ? [
                                  moment(formData?.clientApprovalStartDate),
                                  moment(formData?.clientApprovalEndDate),
                                ]
                              : ""
                          }
                          onChange={(momentData, Date) => {
                            handelFormData({
                              target: {
                                name: "clientApprovalStartDate",
                                value: moment(momentData[0]._d)?.format(
                                  "YYYY-MM-DD"
                                ),
                              },
                            });
                            handelFormData({
                              target: {
                                name: "clientApprovalEndDate",
                                value: moment(momentData[1]._d)?.format(
                                  "YYYY-MM-DD"
                                ),
                              },
                            });
                          }}
                          //format={dateFormat}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    className="ap-flex ap-w100"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="ap-flex ap-flex-center-center search-btn">
                      <Button
                        type="primary"
                        className="ap-btn-c submit_right1"
                        shape="round"
                        loading={state.projectsList.loading ? <Spin /> : false}
                        disabled={Object.values(formData).length === 0}
                        icon={<Icons.Search />}
                        size="middle"
                        onClick={() =>
                          callRecords({ payload: { page: 1, size: 5 } })
                        }
                      >
                        <span className="ap-fesm ap-fwmd">Search</span>
                      </Button>
                    </div>
                    <div
                      className="ap-flex ap-flex-center-center search-btn"
                      style={{ marginLeft: "10px" }}
                    >
                      <Button
                        type="primary"
                        shape="round"
                        className="ap-btn-c submit_right1"
                        loading={state.projectsList.loading ? <Spin /> : false}
                        disabled={Object.values(formData).length === 0}
                        icon={<Icons.Sweep />}
                        size="middle"
                        onClick={() => {
                          setFormData({});
                          setCurrent({
                            page: 1,
                            size: 5,
                          });
                          trelloAttachmentList(id, `limit=10&page=1`);
                        }}
                      >
                        <span className="ap-fesm ap-fwmd">Clear</span>
                      </Button>
                    </div>
                  </div>
                </Card>
              </FilterDiv>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <ActionButons>
        <Button className="buttons submit_right1" onClick={onUploadOpen}>
          Upload
        </Button>
        <Button
          className="buttons submit_right1"
          onClick={() => {
            // handelOnClickAction("sendToClient", "");
            onConfirmOpen();
          }}
          isDisabled={!sendToClientActive}
          isLoading={loader == "sendToClient" ? true : false}
        >
          Send To Client
        </Button>
        <Button
          className="buttons submit_right1"
          isDisabled={!clientResponseActive}
          onClick={onClientApprovalOpen}
          isLoading={loader == "setClientApprovalStatus" ? true : false}
        >
          Set Client Response
        </Button>
        <Button
          className="buttons submit_right1"
          isDisabled={!setApprovalStatusActive}
          onClick={onApprovalOpen}
          isLoading={loader == "setApprovalStatus" ? true : false}
        >
          Set Internal Approval Status
        </Button>
        <Button
          className="buttons submit_right1"
          isDisabled={!setDeliveredActive}
          onClick={() => {
            handelOnClickAction("markDeliverable", "");
          }}
          isLoading={loader == "markDeliverable" ? true : false}
        >
          Make Deliverable
        </Button>
        {/* <Button
          className="buttons submit_right1"
          isDisabled={!setDeliveredActive}
          onClick={() => {
            handelOnClickAction("markDelieverd", "");
          }}
          isLoading={loader == "markDelieverd" ? true : false}
        >
          Mark Delievered
        </Button> */}
      </ActionButons>
      <AttachementListDiv>
        {state?.trelloAttachmentList?.loading == true ? (
          <div className="spinn">
            <SpinLoader />
          </div>
        ) : (
          <Card>
            <Table
              columns={columns}
              rowSelection={rowSelection}
              dataSource={attachementList}
              rowKey={(record) => record.key}
              scroll={{ y: 550, x: 900 }}
              // pagination={false}
              // scroll={{ x: 900 }}
              pagination={{
                current: current.page,
                pageSize: current.size,
                position: ["bottomLeft"],
                total: state.trelloAttachmentList.totalRecords,
                onChange: (data, size) =>
                  callRecords({
                    type: "page",
                    payload: { page: data, size: size },
                  }),
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              // footer={renderFooter}
            />
          </Card>
        )}
      </AttachementListDiv>
      {/* modals */}
      <Modal isOpen={isUploadOpen} onClose={onUploadClose} size="4xl">
        <ModalOverlay />
        <ModalContent maxH="38rem" minH="30rem" minW="30rem">
          <UploadAttachment onClose={onUploadClose} />
        </ModalContent>
      </Modal>
      <Modal isOpen={isApprovalOpen} onClose={onApprovalClose} size="2xl">
        <ModalOverlay />
        <ModalContent maxH="30rem" minH="20rem" minW="20rem">
          <SetApprovalStatus
            onClose={onApprovalClose}
            setApprovalStatus={(data) =>
              handelOnClickAction("setApprovalStatus", data)
            }
          />
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isClientApprovalOpen}
        onClose={onClientApprovalClose}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent maxH="30rem" minH="20rem" minW="20rem">
          <SetApprovalStatus
            onClose={onClientApprovalClose}
            setApprovalStatus={(data) =>
              handelOnClickAction("setClientApprovalStatus", data)
            }
          />
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={isConfirmOpen} onClose={onConfirmClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              send To Client
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You Want to Send Selected Attachements To Client!
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                className="submit_right1"
                size="sm"
                onClick={() => {
                  handelOnClickAction("sendToClient", "");
                  onConfirmClose();
                }}
                ml={3}
              >
                Yes
              </Button>
              <Button
                onClick={onConfirmClose}
                size="sm"
                className="submit_right1"
                ml={3}
              >
                NO
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}
