import React, { useEffect, useRef } from "react";
import {
  ModalHeader,
  ModalBody,
  CloseButton,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import { Select, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { AddressDiv, FormHeading } from "./billingStyle";
import { API } from "../../../Services";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-international";
import "react-phone-input-international/lib/style.css";
export function EditBillingInfo(props) {
  const [formData, setFormData] = useState({});
  const { TextArea } = Input;
  const [isloading, setLoader] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { Option } = Select;
  const { states } = bindActionCreators(actionCreators, dispatch);
  function validateEmail(value) {
    let error;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }
  useEffect(() => {
    if (props.isbillingInfo == true) {
      console.log("billingInfoData", props.billingInfoData);
    }
  }, [props]);
  const AddBillingInfo = (value) => {
    setLoader(true);
    API.addCustomerBillingInfo(value)
      .then((res) => {
        // setLoad((prev)=>!prev)
        console.log(res);
        toast.success(
          res.data.message || "Added Billing Information successfully.",
          {
            position: "top-right",
          }
        );
        setLoader(false);
        props.onClose();
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        toast.error("Oops! Failed to Add Billing Information.", {
          position: "top-right",
        });
        setLoader(false);
        // props.onClose();
      });
  };

  return (
    <div style={{ height: "30rem", overflowY: "scroll" }}>
      <div>
        <ModalBody>
          <Formik
            initialValues={{
              first_name:props?.customerDetail?.firstName || props?.billingInfoData?.firstName|| "",
              last_name:props?.customerDetail?.lastName ||props?.billingInfoData?.lastName || "",
              email: props?.customerDetail?.email || props?.billingInfoData?.email || "",
              phone_number: props?.customerDetail?.phoneNumber||props?.billingInfoData?.phoneNumber || "",
              business_name: props?.billingInfoData?.businessName || "",
              business_phone_number:
                props?.billingInfoData?.businessPhoneNumber || "",
              pan_number: props?.billingInfoData?.panNo || "",
              is_gst: props?.billingInfoData?.isGst || false,
              gst_no: props?.billingInfoData?.gstNo || "",
              baddress:
                props?.billingInfoData?.billingAddress?.addressLine_1 || "",
              baddress2:
                props?.billingInfoData?.billingAddress?.addressLine_2 || "",
              bcountry: props?.billingInfoData?.billingAddress?.country || "",
              bstate: props?.billingInfoData?.billingAddress?.state || "",
              bcity: props?.billingInfoData?.billingAddress?.city || "",
              bzip: props?.billingInfoData?.billingAddress?.pincode || "",
              is_same_as_billing:
                props?.billingInfoData?.isSameAddress || false,
              saddress:
                props?.billingInfoData?.shippingAddress?.addressLine_1 || "",
              saddress2:
                props?.billingInfoData?.shippingAddress?.addressLine_2 || "",
              scountry: props?.billingInfoData?.shippingAddress?.country || "",
              sstate: props?.billingInfoData?.shippingAddress?.state || "",
              scity: props?.billingInfoData?.shippingAddress?.city || "",
              szip: props?.billingInfoData?.shippingAddress?.pincode || "",
              customer_id: props.CustomerID,
              spnumber:
                props?.billingInfoData?.shippingAddress
                  ?.shipping_phone_number || "",
              bpnumber:
                props?.billingInfoData?.billingAddress?.billing_phone_number ||
                "",
              sfname:
                props?.billingInfoData?.shippingAddress?.shipping_first_name ||
                "",
              bfname:
                props?.billingInfoData?.billingAddress?.billing_first_name ||
                "",
              blname:
                props?.billingInfoData?.billingAddress?.billing_last_name || "",
              slname:
                props?.billingInfoData?.shippingAddress?.shipping_last_name ||
                "",
              semail:
                props?.billingInfoData?.shippingAddress?.shipping_email || "",
              bemail:
                props?.billingInfoData?.billingAddress?.billing_email || "",
              isd_bpnumber: props?.billingInfoData?.BusinessPhoneNumber || "",
              isd_phone_number: props?.billingInfoData?.PhoneNumber || "",
              isd_bipnumber:
                props?.billingInfoData?.billingAddress?.billing_phone_number ||
                "",
              isd_spnumber:
                props?.billingInfoData?.shippingAddress
                  ?.shipping_phone_number || "",
            }}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
              AddBillingInfo(values);
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div style={{ overFlow: "scroll" }}>
                  <AddressDiv>
                    <FormHeading>Primary Information</FormHeading>

                    <div className="bill-row">
                      <Field name="first_name">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">
                              First Name
                            </FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="first_name"
                              name="first_name"
                              placeholder="Enter First Name..."
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("first_name", e.target.value);
                              }}
                              // defaultValue={
                              //   formData[0]?.billingAddress?.first_name
                              // }
                              // required
                              disabled={true}
                            />
                            <FormErrorMessage>
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="last_name">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">
                              Last Name
                            </FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="last_name"
                              name="last_name"
                              placeholder="Enter Last Name..."
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("last_name", e.target.value);
                              }}
                              disabled={true}

                              // defaultValue={
                              //   formData[0]?.billingAddress?.last_name
                              // }
                              // required
                            />
                            {/* <FormErrorMessage>
                              {form.errors.last_name}
                            </FormErrorMessage> */}
                          </FormControl>
                        )}
                      </Field>
                    </div>

                    <div className="bill-row">
                      <Field name="email" validate={validateEmail}>
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            isInvalid={
                              form.errors?.email && form.touched?.email
                            }
                            className="primary-info"
                          >
                            <FormLabel htmlFor="text-Input">email</FormLabel>

                            <Input
                              placeholder="Enter Email..."
                              {...field}
                              type="text"
                              id="email"
                              name="email"
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("email", e.target.value);
                              }}
                              disabled={true}

                              // defaultValue={formData[0]?.billingAddress?.email}
                              // required
                            />
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="phone_number">
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            // isInvalid={
                            //   form.errors.phone_number &&
                            //   form.touched.phone_number
                            // }
                            className="primary-info"
                          >
                            <FormLabel htmlFor="text-Input">
                              Phone Number
                            </FormLabel>

                            <PhoneInput
                              {...field}
                              id="phone_number"
                              name="phone_number"
                              placeholder="Enter Phone Number..."
                              enableSearch={true}
                              disableSearchIcon={true}
                              country={"in"}
                              onChange={(value) => {
                                console.log("Phone number:", `+${value}`);
                                setFieldValue("phone_number", `+${value}`);
                                setFieldValue("isd_phone_number", `+${value}`);
                              }}
                              disabled={true}

                            />
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <div>
                      <br />
                      <Field name="is_gst">
                        {({ field, form }) => (
                          <FormControl>
                            <Checkbox
                              {...field}
                              size="lg"
                              colorScheme="green"
                              defaultChecked={
                                props?.billingInfoData?.isGst || false
                              }
                              // isChecked={}
                              mr={2}
                              mb={2}
                              onChange={(e) => {
                                setFieldValue("is_gst", e.target.checked);
                                console.log("checked", e.target.checked);
                                // setFormData((prev) => {
                                //   prev = JSON.parse(JSON.stringify(prev));
                                //   prev[`is_gst`] = e?.target?.checked;
                                //   return prev;
                                // });
                              }}
                            >
                              Is GST required?
                            </Checkbox>
                          </FormControl>
                        )}
                      </Field>
                      {values.is_gst ? (
                        <>
                          <div className="bill-row">
                            <Field name="business_name">
                              {({ field, form }) => (
                                <FormControl
                                  isRequired
                                  className="primary-info"
                                >
                                  <FormLabel htmlFor="text-Input">
                                    Business Name
                                  </FormLabel>

                                  <Input
                                    {...field}
                                    type="text"
                                    id="business_name"
                                    name="business_name"
                                    placeholder="pin code"
                                    onChange={(e) => {
                                      // console.log(e.target.value);
                                      setFieldValue(
                                        "business_name",
                                        e.target.value
                                      );
                                    }}
                                    // defaultValue={
                                    //   formData[0]?.billingAddress?.business_name
                                    // }
                                    required
                                  />
                                  {/* <FormErrorMessage>
                                    {form.errors.name}
                                  </FormErrorMessage> */}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="business_phone_number">
                              {({ field, form }) => (
                                <FormControl
                                  {...field}
                                  isRequired
                                  className="primary-info"
                                >
                                  <FormLabel htmlFor="text-Input">
                                    Business Phone Number
                                  </FormLabel>
                                  <PhoneInput
                                    {...field}
                                    id="business_phone_number"
                                    name="business_phone_number"
                                    placeholder="Enter Phone Number..."
                                    enableSearch={true}
                                    disableSearchIcon={true}
                                    country={"in"}
                                    onChange={(value) => {
                                      console.log("Phone number:", `+${value}`);
                                      setFieldValue(
                                        "business_phone_number",
                                        `+${value}`
                                      );
                                      setFieldValue(
                                        "isd_bpnumber",
                                        `+${value}`
                                      );
                                    }}
                                  />
                                </FormControl>
                              )}
                            </Field>
                          </div>
                          <div className="bill-row">
                            <Field name="gst_no">
                              {({ field, form }) => (
                                <FormControl
                                  isRequired
                                  className="primary-info"
                                >
                                  <FormLabel htmlFor="text-Input">
                                    gst_no
                                  </FormLabel>

                                  <Input
                                    {...field}
                                    type="text"
                                    id="gst_no"
                                    name="gst_no"
                                    placeholder="Enter GST No..."
                                    onChange={(e) => {
                                      setFieldValue("gst_no", e.target.value);

                                      console.log(e.target.value);
                                    }}
                                    // defaultValue={
                                    //   formData[0]?.shippingAddress?.gst_no
                                    // }
                                    required
                                  />
                                  {/* <FormErrorMessage>
                                    {form.errors.name}
                                  </FormErrorMessage> */}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="pan_number">
                              {({ field, form }) => (
                                <FormControl
                                  isRequired
                                  className="primary-info"
                                >
                                  <FormLabel htmlFor="text-Input">
                                    PAN Number
                                  </FormLabel>

                                  <Input
                                    {...field}
                                    type="text"
                                    id="pan_number"
                                    name="pan_number"
                                    placeholder="Enter Pan Number..."
                                    onChange={(e) => {
                                      // console.log(e.target.value);
                                      setFieldValue(
                                        "pan_number",
                                        e.target.value
                                      );
                                    }}
                                    // defaultValue={
                                    //   formData[0]?.shippingAddress?.pan_number
                                    // }
                                    required
                                  />
                                  {/* <FormErrorMessage>
                                    {form.errors.name}
                                  </FormErrorMessage> */}
                                </FormControl>
                              )}
                            </Field>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />

                    <FormHeading>Billing Information</FormHeading>
                    <div className="bill-row">
                      <Field name="bfname">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">
                              First Name
                            </FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="bfname"
                              name="bfname"
                              placeholder="Enter First Name..."
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("bfname", e.target.value);
                              }}
                              // defaultValue={
                              //   formData[0]?.billingAddress?.first_name
                              // }
                              required
                            />
                            {/* <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage> */}
                          </FormControl>
                        )}
                      </Field>
                      <Field name="blname">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">
                              Last Name
                            </FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="blname"
                              name="blname"
                              placeholder="Enter Last Name..."
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("blname", e.target.value);
                              }}
                              // defaultValue={
                              //   formData[0]?.billingAddress?.last_name
                              // }
                              required
                            />
                            {/* <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage> */}
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <div className="bill-row">
                      <Field name="bemail" validate={validateEmail}>
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            className="primary-info"
                            isInvalid={
                              form.errors?.bemail && form.touched?.bemail
                            }
                          >
                            <FormLabel htmlFor="text-Input">email</FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="bemail"
                              name="bemail"
                              placeholder="Enter Email..."
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("bemail", e.target.value);
                              }}
                              // defaultValue={formData[0]?.billingAddress?.email}
                              required
                            />
                            <FormErrorMessage>
                              {form.errors.bemail}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="isd_bipnumber">
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            // isInvalid={
                            //   form.errors?.bpnumber && form.touched?.bpnumber
                            // }
                            className="primary-info"
                          >
                            <FormLabel htmlFor="text-Input">
                              Phone Number
                            </FormLabel>
                            <PhoneInput
                              {...field}
                              id="isd_bipnumber"
                              name="isd_bipnumber"
                              placeholder="Enter Phone Number..."
                              enableSearch={true}
                              disableSearchIcon={true}
                              country={"in"}
                              onChange={(value) => {
                                console.log("Phone number:", `+${value}`);
                                setFieldValue("bpnumber", `+${value}`);
                                setFieldValue("isd_bipnumber", `+${value}`);
                              }}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <div className="address">
                      <Field name="baddress">
                        {({ field, form }) => (
                          <FormControl isRequired>
                            <FormLabel htmlFor="text-Input"> Address</FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="baddress"
                              name="baddress"
                              placeholder="Address Line 1"
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("baddress", e.target.value);
                              }}
                              // defaultValue={
                              //   formData[0]?.billingAddress?.baddress
                              // }
                              required
                            />
                            {/* <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage> */}
                          </FormControl>
                        )}
                      </Field>
                      <Field name="baddress2">
                        {({ field, form }) => (
                          <FormControl isRequired>
                            <FormLabel htmlFor="text-Input">
                              {" "}
                              Address 2
                            </FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="baddress2"
                              name="baddress2"
                              placeholder="Address Line 2"
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("baddress2", e.target.value);
                              }}
                              required
                              // defaultValue={
                              //   formData[0]?.billingAddress?.baddress2
                              // }
                            />
                            {/* <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage> */}
                          </FormControl>
                        )}
                      </Field>
                    </div>

                    <div className="bill-row">
                      <Field name="bcountry">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">Country</FormLabel>

                            <Select
                              {...field}
                              showSearch
                              size="middle"
                              // value={formData[0]?.billingAddress?.bcountry}
                              labelInValue
                              placeholder="Select Country"
                              onChange={(data) => {
                                setFieldValue("bcountry", data.value);
                                console.log("data.code",data.value)
                                states(data.value);
                                // setFormData({
                                //   target: { ...formData, name: "bcountry" },
                                // })
                              }}
                              style={{ width: "100%" }}
                              filterOption={(Input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(Input.toLowerCase()) >= 0
                              }
                              required
                            >
                              {state.countries.countries.map(
                                (country, countryIndex) => (
                                  <Option
                                    value={country.code}
                                    index={countryIndex}
                                  >
                                    {country.label}
                                  </Option>
                                )
                              )}
                            </Select>
                            {/* <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage> */}
                          </FormControl>
                        )}
                      </Field>
                      <Field name="bstate">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">State</FormLabel>

                            <Select
                              {...field}
                              showSearch
                              size="middle"
                              // value={formData[0]?.billingAddress?.bstate}
                              labelInValue
                              placeholder="Select State"
                              onChange={
                                (data) => setFieldValue("bstate", data.value)
                                // setFormData({
                                //   target: { ...formData, name: "bstate" },
                                // })
                              }
                              style={{ width: "100%" }}
                              disabled={state.states.loading}
                              loading={state.states.loading}
                              filterOption={(Input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(Input.toLowerCase()) >= 0
                              }
                              required
                            >
                              {state.states.states.map((state, stateIndex) => (
                                <Option value={state.code} index={stateIndex}>
                                  {state.label}
                                </Option>
                              ))}
                            </Select>
                            {/* <FormErrorMessage>
                              {form.errors.name}
                            </FormErrorMessage> */}
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <div className="bill-row">
                      <Field name="bcity">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">City</FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="bcity"
                              name="bcity"
                              placeholder="city"
                              onChange={(e) => {
                                setFieldValue("bcity", e.target.value);
                              }}
                              required
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="bzip">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">Zip</FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="bzip"
                              name="bzip"
                              placeholder="Zip code"
                              onChange={(e) => {
                                setFieldValue("bzip", e.target.value);
                              }}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <br />
                    <Field name="bstate">
                      {({ field, form }) => (
                        <FormControl>
                          <Checkbox
                            {...field}
                            size="lg"
                            colorScheme="green"
                            // isChecked={true}
                            defaultChecked={
                              props?.billingInfoData?.isSameAddress || false
                            }
                            mr={2}
                            mb={2}
                            onChange={(e) => {
                              setFieldValue(
                                "is_same_as_billing",
                                e?.target?.checked
                              );
                              if (e?.target?.checked) {
                                setFieldValue("sfname", values.bfname);
                                setFieldValue("slname", values.blname);
                                setFieldValue("semail", values.bemail);
                                setFieldValue("saddress", values.baddress);
                                setFieldValue("saddress2", values.baddress2);
                                setFieldValue("scountry", values.bcountry);
                                setFieldValue("sstate", values.bstate);
                                setFieldValue("scity", values.bcity);
                                setFieldValue("szip", values.bzip);
                                setFieldValue("spnumber", values.bpnumber);
                              }
                            }}
                          >
                            Is shipping address same as billing address?
                          </Checkbox>
                        </FormControl>
                      )}
                    </Field>
                    {values.is_same_as_billing ? (
                      " "
                    ) : (
                      <>
                        <br />
                        <FormHeading>Shipping Information</FormHeading>
                        <div className="bill-row">
                          <Field name="sfname">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">
                                  First Name
                                </FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="sfname"
                                  name="sfname"
                                  placeholder="Enter First Name..."
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setFieldValue("sfname", e.target.value);
                                  }}
                                  required
                                />
                              </FormControl>
                            )}
                          </Field>
                          <Field name="slname">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">
                                  Last Name
                                </FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="slname"
                                  name="slname"
                                  placeholder="Enter Last Name..."
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setFieldValue("slname", e.target.value);
                                  }}
                                  required
                                />
                              </FormControl>
                            )}
                          </Field>
                        </div>
                        <div className="bill-row">
                          <Field name="semail" validate={validateEmail}>
                            {({ field, form }) => (
                              <FormControl
                                isRequired
                                className="primary-info"
                                isInvalid={
                                  form.errors?.semail && form.touched?.semail
                                }
                              >
                                <FormLabel htmlFor="text-Input">
                                  email
                                </FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="semail"
                                  name="semail"
                                  placeholder="Enter Email..."
                                  onChange={(e) => {
                                    // console.log(e.target.value);
                                    setFieldValue("semail", e.target.value);
                                  }}
                                  // defaultValue={formData[0]?.billingAddress?.email}
                                  required
                                />
                                <FormErrorMessage>
                                  {form.errors.semail}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="isd_spnumber">
                            {({ field, form }) => (
                              <FormControl
                                isRequired
                                className="primary-info"
                                // isInvalid={
                                //   form.errors?.spnumber && form.touched?.spnumber
                                // }
                              >
                                <FormLabel htmlFor="text-Input">
                                  Phone Number
                                </FormLabel>
                                <PhoneInput
                                  {...field}
                                  id="isd_spnumber"
                                  name="isd_spnumber"
                                  placeholder="Enter Phone Number..."
                                  enableSearch={true}
                                  disableSearchIcon={true}
                                  country={"in"}
                                  onChange={(value) => {
                                    console.log("Phone number:", `+${value}`);
                                    setFieldValue("spnumber", `+${value}`);
                                    setFieldValue("isd_spnumber", `+${value}`);
                                  }}
                                />
                              </FormControl>
                            )}
                          </Field>
                        </div>{" "}
                        <div className="address">
                          <Field name="saddress">
                            {({ field, form }) => (
                              <FormControl isRequired>
                                <FormLabel htmlFor="text-Input">
                                  {" "}
                                  Address
                                </FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="saddress"
                                  name="saddress"
                                  placeholder="Address Line 1"
                                  onChange={(e) => {
                                    // console.log(e.target.value);
                                    setFieldValue("saddress", e.target.value);
                                  }}
                                  // defaultValue={
                                  //   formData[0]?.billingAddress?.saddress
                                  // }
                                  required
                                />
                                {/* <FormErrorMessage>
                                  {form.errors.name}
                                </FormErrorMessage> */}
                              </FormControl>
                            )}
                          </Field>
                          <Field name="saddress2">
                            {({ field, form }) => (
                              <FormControl isRequired>
                                <FormLabel htmlFor="text-Input">
                                  {" "}
                                  Address 2
                                </FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="saddress2"
                                  name="saddress2"
                                  placeholder="Address Line 2"
                                  onChange={(e) => {
                                    setFieldValue("saddress2", e.target.value);
                                    // console.log(e.target.value);
                                  }}
                                  // defaultValue={
                                  //   formData[0]?.billingAddress?.saddress2
                                  // }
                                  required
                                />
                                {/* <FormErrorMessage>
                                  {form.errors.name}
                                </FormErrorMessage> */}
                              </FormControl>
                            )}
                          </Field>
                        </div>
                        <div className="bill-row">
                          <Field name="scountry" validate={validateEmail}>
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">
                                  Country
                                </FormLabel>

                                <Select
                                  {...field}
                                  showSearch
                                  size="middle"
                                  // value={formData[0]?.billingAddress?.scountry}
                                  labelInValue
                                  placeholder="Select Country"
                                  onChange={(data) => {
                                    setFieldValue("scountry", data.value);
                                    states(data.value);

                                    // setFormData({
                                    //   target: { ...formData, name: "scountry" },
                                    // })
                                  }}
                                  style={{ width: "100%" }}
                                  filterOption={(Input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(Input.toLowerCase()) >= 0
                                  }
                                  required
                                >
                                  {state.countries.countries.map(
                                    (country, countryIndex) => (
                                      <Option
                                        value={country.code}
                                        index={countryIndex}
                                      >
                                        {country.label}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="sstate">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">
                                  State
                                </FormLabel>

                                <Select
                                  {...field}
                                  showSearch
                                  size="middle"
                                  labelInValue
                                  placeholder="Select State"
                                  onChange={(data) =>
                                    setFieldValue("sstate", data.value)
                                  }
                                  style={{ width: "100%" }}
                                  disabled={state.states.loading}
                                  loading={state.states.loading}
                                  filterOption={(Input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(Input.toLowerCase()) >= 0
                                  }
                                  required
                                >
                                  {state.states.states.map(
                                    (state, stateIndex) => (
                                      <Option
                                        value={state.code}
                                        index={stateIndex}
                                      >
                                        {state.label}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                        </div>
                        <div className="bill-row">
                          <Field name="scity">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">City</FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="scity"
                                  name="scity"
                                  placeholder="city"
                                  onChange={(e) => {
                                    setFieldValue("scity", e.target.value);
                                  }}
                                  required
                                />
                              </FormControl>
                            )}
                          </Field>
                          <Field name="szip">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">Zip</FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="szip"
                                  name="szip"
                                  placeholder="Zip code"
                                  onChange={(e) => {
                                    setFieldValue("szip", e.target.value);
                                  }}
                                  required
                                />
                              </FormControl>
                            )}
                          </Field>
                        </div>
                      </>
                    )}
                  </AddressDiv>
                  <br />
                </div>

                <Button
                  mt={4}
                  className="submit_right1"
                  isLoading={isloading}
                  type="submit"
                >
                  {props.isbillingInfo ? "Update" : "Add"}
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </div>
    </div>
  );
}
