import React, { useState, useEffect } from "react";
import "../../../Styles/Master.scss";
import "./PartnersList.scss";
import { Card, Input, Spin, Button, Table, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../Components";
import { API } from "../../../Services";

const PartnersList = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    value: "",
    key: "",
  });
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const [mainLoad, setMainLoad] = useState(true);
  const { partnersList } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    setTimeout(() => {
      console.log(state.partnersList.customers);
      setMainLoad((prev) => !prev);
    }, 3000);
  }, []);

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Partner Id</div>,
      dataIndex: "partnerId",
      key: "partnerId",
      render: (text) => <div className="ap-fsm">{text}</div>,
      hidden: true,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Partner Name</div>,
      dataIndex: "partnerName",
      key: "partnerName",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Email</div>,
      dataIndex: "email",
      key: "email",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Contact No.</div>,
      dataIndex: "contactNo",
      key: "contactNo",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">City</div>,
      dataIndex: "city",
      key: "city",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">State</div>,
      dataIndex: "state",
      key: "state",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Country</div>,
      dataIndex: "country",
      key: "country",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Action</div>,
      key: "action",
      render: (text, record) => (
        <Space size="small" className="table-btn">
          <Button
            type="primary"
            className="ap-btn-c"
            shape="round"
            icon={<Icons.Details />}
            size="middle"
            onClick={() => {
              history.push(`/home/ap/partners/details/${record.partnerId}`);
            }}
          >
            <span className="ap-fesm ap-fwmd">Show Details</span>
          </Button>
        </Space>
      ),
      width: "fit-content",
      fixed: "right",
    },
  ].filter((item) => !item.hidden);

  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.key = event.target.name;
      prev.value = event.target.value;
      return prev;
    });
  };

  useEffect(() => {
    if (state.partnersList.customers.length > 0) {
      console.log(state.partnersList.customers);
      setTableData((prev) => {
        prev = [];
        state.partnersList.customers.map((customer, customerIndex) => {
          prev.push({
            key: customerIndex,
            partnerId: customer.id,
            partnerName: `${customer.firstName} ${customer.lastName}`,
            email: customer.email || "NA",
            contactNo: `${
              customer.phoneNumber !== "" ? "+" + customer.isdCode : ""
            }${customer.phoneNumber}`,
            city: customer.city || "NA",
            state: customer.state || "NA",
            country: customer.country || "NA",
          });
        });
        return prev;
      });
    } else {
      setTableData((prev) => {
        console.log(prev);
      });
    }
  }, [state]);

  const callRecords = (event) => {
    console.log(event);
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };

  useEffect(() => {
    let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10;

    partnersList(
      `permission=1&limit=${current.size}&offset=${offset}&q=${data?.value}`
    );
  }, [current]);

  return (
    <>
      {" "}
      {state?.partnersList?.msg !== "" ? (
        <>
          <div
            style={{
              fontSize: "large",
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            <h3>Unauthorized</h3>
          </div>
        </>
      ) : (
        <div className="ap-flex ap-flex-center-center ap-w100">
          <div className="ap-flex ap-w100">
            <Card style={{ width: "100%" }}>
              <div className="ap-flex ap-w100 ">
                <div className="ap-flex ap-flex-center-center ap-w33">
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Partner Name
                  </div>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Enter Partner Name"
                    value={data.key === "partnerName" ? data.value : ""}
                    name="partnerName"
                    disabled={
                      data.key !== "" &&
                      data.key !== "partnerName" &&
                      data.value !== ""
                    }
                    onChange={(e) => {
                      setFormData(e);
                    }}
                  />
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Partner Email
                  </div>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Enter Partner Email"
                    value={data.key === "partnerEmail" ? data.value : ""}
                    name="partnerEmail"
                    disabled={
                      data.key !== "" &&
                      data.key !== "partnerEmail" &&
                      data.value !== ""
                    }
                    onChange={(e) => setFormData(e)}
                  />
                </div>
                <div className="ap-flex ap-flex-center-center ap-w33">
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Partner Contact No.
                  </div>
                  <Input
                    size="large"
                    type="number"
                    style={{ width: "100%" }}
                    placeholder="Enter Partner Contact Number"
                    value={data.key === "partnerNumber" ? data.value : ""}
                    name="partnerNumber"
                    disabled={
                      data.key !== "" &&
                      data.key !== "partnerNumber" &&
                      data.value !== ""
                    }
                    onChange={(e) => setFormData(e)}
                  />
                </div>
              </div>

              <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
                <div className="ap-flex ap-flex-center-center search-btn">
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={state.partnersList.loading ? <Spin /> : false}
                    icon={<Icons.Search />}
                    size="middle"
                    onClick={() =>
                      callRecords({ payload: { page: 1, size: 10 } })
                    }
                  >
                    <span className="ap-fesm ap-fwmd">Search</span>
                  </Button>
                </div>
                <div
                  className="ap-flex ap-flex-center-center search-btn"
                  style={{ marginLeft: "10px" }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={state.partnersList.loading ? <Spin /> : false}
                    icon={<Icons.Sweep />}
                    size="middle"
                    onClick={() => {
                      setData({ key: "", value: "" });
                      partnersList(`permission=1&limit=10&offset=0&q=`);
                    }}
                  >
                    <span className="ap-fesm ap-fwmd">Clear</span>
                  </Button>
                </div>
              </div>
            </Card>
          </div>

          {!state.partnersList.loading ? (
            <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
              <Card style={{ width: "100%" }}>
                <div
                  className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record"
                  onClick={() => {
                    setData({ key: "", value: "" });
                    partnersList(`permission=1&limit=10&offset=0&q=`);
                  }}
                >
                  Reset Records
                </div>

                <Table
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ x: 900 }}
                  pagination={{
                    current: current.page,
                    pageSize: current.size,
                    position: ["bottomLeft"],
                    total: state.partnersList.totalRecords,
                    onChange: (data, size) =>
                      callRecords({
                        type: "page",
                        payload: { page: data, size: size },
                      }),
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />
              </Card>
            </div>
          ) : (
            <div
              className="ap-flex ap-w100 ap-flex-center-center"
              style={{ height: "300px" }}
            >
              <Spin tip="Fetching Records....." />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PartnersList;
