
const projectAttachementCategoriesReducer = (state = {
    loading:false,
    categories:[],totalRecords:0,msg:''
}, action) => {
    switch (action.type) {
        case "FETCH_ATTACHEMENT_CATEGORIES_LIST":
            return { ...state ,loading:true,categories:[],totalRecords:0,msg:''}
        case "RECEIVE_ATTACHEMENT_CATEGORIES_LIST":
            return {...state, categories:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords,msg:''}
        case "ERROR_ATTACHEMENT_CATEGORIES_LIST":
            return { ...state ,categories:[],loading:false,totalRecords:0,msg:action.payload}
        default:
            return { ...state }
    }
}

export default projectAttachementCategoriesReducer;