import { axiosInstance } from ".."

const paymentRequest = async (data) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.post(`internal/Paymentrequest`,data).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default paymentRequest;