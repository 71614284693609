const crmCustomfieldListReducer = (
  state = {
    loading: false,
    customFieldList: [],
    totalRecords: 0,
  },
  action
) => {
  switch (action.type) {
    case "FETCH_CUSTOM_FIELD_LIST":
      return { ...state, loading: true, customFieldList: [], totalRecords: 0 };
    case "RECEIVE_CUSTOM_FIELD_LIST":
      return {
        ...state,
        customFieldList: [...action.payload.data],
        loading: false,
        totalRecords: action.payload.totalRecords,
      };
    case "ERROR_CUSTOM_FIELD_LIST":
      return { ...state, customFieldList: [], loading: true, totalRecords: 0 };
    default:
      return { ...state };
  }
};

export default crmCustomfieldListReducer;
