const trelloCommentListReducer = (state = {
    loading:false,
    commentList:[],
    totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_COMMENTS":
            return { ...state ,loading:true,commentList:[],totalRecords:0}
        case "RECEIVE_TRELLO_COMMENTS":
            return {...state, commentList:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_TRELLO_COMMENTS":
            return { ...state ,commentList:[],loading:false,totalRecords:0}
        default:
            return { ...state }
    }
}

export default trelloCommentListReducer;