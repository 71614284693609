import { axiosInstance } from ".."
import { BaseUrl } from "../../chatapp/apis/Networks"

const login = async (data) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.post(`internal/me/login`,data).then((res) => { 
            if(res.status === 200){
                console.log(res)
                window.open(`${BaseUrl}/initsession?token=${res.data.token}`)
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default login;