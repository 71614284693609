const trelloCardsReducer = (state = {
    totalRecords:0,
    loading:false,
    cards:[]
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_CARDS":
            return { ...state ,loading:true,cards:[],totalRecords:0}
        case "RECEIVE_TRELLO_CARDS":
            return {...state, cards:[...action.payload?.cards],loading:false,totalRecords:action.payload?.totalRecords}
        case "ERROR_TRELLO_CARDS":
            return { ...state ,cards:[],loading:false,totalRecords:0}
        default:
            return { ...state }
    }
}

export default trelloCardsReducer;