import { axiosInstance } from ".."

const partnersComissionList = async (partnerId,querry) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`internal/Commission?partnerId=${partnerId}&${querry}`).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default partnersComissionList;