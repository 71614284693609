import axios from "axios";


 const baseURL = "https://crm-api-testing.makemyhouse.com/";
//  const baseURL ='http://apiv2-sand.ambientresearch.co/whatsapp';
 let headers = {
   "content-type": "application/json",
   "Access-Control-Allow-Origin": "*",
   // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
   //  Accept: "application/x-www-form-urlencoded",

   "x-api-key": `${localStorage.getItem("token")}`,
 };

 axios.interceptors.request.use((config) => {
   config.withCredentials = false;
   config.crossDomain = true;
   return config;
 });
 const crmAxiosInstance = axios.create({
   baseURL: baseURL,
   headers,
 });

 export { crmAxiosInstance };
