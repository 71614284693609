const downlineAdminsReducer = (state = {
    loading:false,
    admins:[]
}, action) => {
    switch (action.type) {
        case "FETCH_DOWN_ADMIN_LIST":
            return { ...state ,loading:true,admins:[]}
        case "RECEIVE_DOWN_ADMIN_LIST":
            return {...state, admins:[...action.payload],loading:false,}
        case "ERROR_DOWN_ADMIN_LIST":
            return { ...state ,admins:[],loading:true}
        default:
            return { ...state }
    }
}

export default downlineAdminsReducer;