import { API } from "../../Services";

export const invoicesList = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_INVOICES_LIST",
        })
    
        API.invoicesList(query).then((res)=>{
            console.log(res);
            let data = JSON.parse(JSON.stringify(res)).data.invoices
            // data.map((invoice,customerIndex)=>{
            //     invoice.value = invoice.id
            //     invoice.label = `${invoice.firstName} ${invoice.lastName}`
            // })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_INVOICES_LIST",
                    payload:{data:data,totalRecords:res.data.totalInvoices},
                })
            },1000)
        }).catch((err)=>{
            let data = JSON.parse(JSON.stringify(err?.response?.data?.message))

            dispatch({
                type:"ERROR_INVOICES_LIST",
                payload:data

            })
        })
    }
}

