import styled from "styled-components";

export const AttachementListDiv = styled.div`
  .spinn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ap-flex-center {
    justify-content: flex-start;
  }
  .attach-link {
    cursor: pointer;
    &:hover {
        color: #167495;
        text-decoration: underline;
      
    }
  }
`;
export const ActionButons = styled.div`
  padding: 0 8px;
  .buttons {
    color: white;
    margin-top: 13px;
    margin-left: 17px;
    margin-bottom: 13px;
  }
`;
export const Status = styled.div`
  .icon-div::before {
    padding: 0 5px;
    font-size: 18px;
    border-radius: 50%;
  }

  .approved-icon::before {
    background: #00800073;
    content: "✓";
    color: green;
  }
  .pending-icon::before {
    background: #ffff0038;
    padding: 0 10px;
    font-size: 20px;
    content: " ! ";
    color: #b4b405;
  }
  .declined-icon::before {
    background: #f86c6b73;
    content: "✘";
    color: red;
  }
`;
export const TextHighlight = styled.div`
.Yes{
background:#b0f9b0d9;
padding:0 0.5rem;
}
.No{
background:#ffb2b2e0;
padding:0 0.5rem;

}
`
export const UploadDiv = styled.div`
  overflow-y: scroll;

  height: 32rem;
  padding: 1rem 2rem;
  .file-upload {
    border: solid #6c757d;
    border-radius: 20px;
    height: 17rem;
    align-items: center;
    display: flex;
    justify-content: center;
    .preview-div {
      justify-content: space-between;
      width: 150px;
      height: 150px;
      margin: 0 5px;
      padding: 5px;
      display: flex;
      border: 2px solid #00800070;
      flex-direction: column;
      border-radius: 9px;
      background: #0080002b;
      .display-preview {
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    .file-name {
      padding: 0 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .preview {
      // height: 98%;

      max-width: 150px;
      max-height: 80px;
      object-fit: cover;
    }

    .placeholder {
      text-align: center;
    }
    .filename {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .file-icon {
        svg {
          font-size: 50px;
          color: #ccc;
        }
      }
    }
  }
`;
export const Submit = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const FormDiv = styled.div`
  padding: 1.5rem;
`;
export const TagDiv = styled.div`
  border: solid #6c757d;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h2 {
    padding: 10px;
  }
  .ant-tag {
    font-size: 15px !important;
    padding: 3px 7px !important;
  }
  svg {
    font-size: 15px;
    margin-bottom: 5px;
  }
`;
export const FilterDiv = styled.div`
  .ap-btn-c {
    padding: 0.5rem;
  }
`;
