import React, { useEffect,useState } from "react";
import "antd/dist/antd.css";
import "../../Styles/Master.scss";
import "./Wallet.scss";
import { Skeleton,Card,Button,InputNumber,message,Alert,Spin,notification} from 'antd';
import {Icons} from "../../Utils";
import { actionCreators } from "../../Redux"
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory,useParams } from "react-router-dom";
import { API } from "../../Services"

const Wallet = (props) =>{

    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false); 
    const [amount,setAmount] = useState(0);
    const [alert,setAlert] = useState({
        status:false
    })
    const history = useHistory();
    const { id, orderId} = useParams()
    const state = useSelector(state=>state);
    const { orderSummary,customerWallet } = bindActionCreators(actionCreators,dispatch);
    const url = (history?.location?.pathname?.includes('child')?`/home/ap/order/child/details/${orderId}`:`/home/ap/order/details/${orderId}`);

    useEffect(()=>{
        orderSummary(localStorage.getItem("orderid"))
        customerWallet(localStorage.getItem("clientid"))
    },[])

    useEffect(()=>{
        if(state.customerWallet.wallet !== null){
            setAmount((prev)=>{
                prev = state?.customerWallet?.wallet?.balance < state.orderSummary.orderSummary?.totalBalanceAmount ? state?.customerWallet?.wallet?.balance : state.orderSummary.orderSummary?.totalBalanceAmount
                return prev
            })
        }
    },[state])

    console.log(history)

    const applyAmount = () =>{
       setLoading((prev)=>!prev)
        API.applyCustomerWallet(id,{amount:amount}).then(()=>{
                setLoading((prev)=>!prev);
            setAlert((prev)=>{
                prev = JSON.parse(JSON.stringify(prev))
                prev.status = true
                prev.type = "success"
                prev.message = "Successfully Applied !"
                prev.description = `Wallet amount of ${amount} is successfully applied.`
                return prev
            })
            console.log('hell',props.isChild);
            setTimeout(()=>{
                history.push(url);
            },5000)
 
            setTimeout(()=>{
                setAlert((prev)=>{
                    prev = JSON.parse(JSON.stringify(prev))
                    prev.status = false
                    return prev
                })
            },4000)
            customerWallet(localStorage.getItem("clientid"))
            // setTimeout(()=>{
            //     history.goBack()
            // },7000)
        }).catch(()=>{
            setLoading((prev)=>!prev)
            setAlert((prev)=>{
                prev = JSON.parse(JSON.stringify(prev))
                prev.status = true
                prev.type = "error"
                prev.message = "Not Applied!"
                prev.description = `Wallet amount of ${amount} is not applied.`
                return prev
            })
            setTimeout(()=>{
                setAlert((prev)=>{
                    prev = JSON.parse(JSON.stringify(prev))
                    prev.status = false
                    return prev
                })
            },5000)
        })
    }

    return <div className="ap-flex ap-flex-center-center ap-w100 wallet">
            {alert.status && <Alert
                message={alert?.message}
                description={alert?.description}
                type={alert?.type}
                showIcon
                closable
            />}
        <Card 
            size="small"
            style={{width:"450px",borderRadius:"10px"}}
            title={
                <div className="ap-flex ap-flex-space-bw ap-w100">
                    <div className="ap-flex ap-flex-center-center ap-fmd ap-fwmd">
                        Wallet
                    </div>
                    <div className="ap-flex ap-flex-center-center close ap-fsm" onClick={()=>history.push(url)}>
                        <Icons.Close />
                    </div>
                </div>
            }
            actions={[
                <div className="ap-flex ap-flex-end ap-w100" style={{borderRadius:"10px",paddingRight:"10px"}}>
                    <Button shape="round" size="large" className="btn "
                     disabled={state.customerWallet.loading || loading || state.customerWallet.wallet?.balance === 0 || amount > state.customerWallet.wallet?.balance} 
                     onClick={()=>applyAmount()}>
                        <span className="ap-fsm ap-fwmd ap-lsp">Apply</span>
                    </Button>
                </div>
                
            ]}
        >
            {!loading ? <><div className="ap-flex ap-w100 ap-flex-space-bw" style={{padding:"5px 0"}}>
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">Order Balance</div>
                <div className="ap-flex ap-flex-center-center ap-fsm ">
                    {
                        state.customerWallet.loading ? <Skeleton.Button style={{width:"100px"}} active size="small" /> :state.orderSummary.orderSummary?.totalBalanceAmount
                    }
                </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw" style={{padding:"5px 0"}}>
                <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">Wallet Balance</div>
                <div className="ap-flex ap-flex-center-center ap-fsm ">
                    {
                        state.customerWallet.loading ? <Skeleton.Button active style={{width:"100px"}} size="small"/> :state.customerWallet.wallet?.balance
                    }    
                </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-center-center" style={{padding:"5px 0"}}>
                <div className="ap-flex ap-fsm ap-fwmd ap-w100">Enter Amount</div>
                <div className="ap-flex  ap-w100" style={{marginTop:"5px"}}>
                    {
                        state.customerWallet.loading ? 
                        <Skeleton.Button active style={{width:"420px"}} size="large"/> :
                        <InputNumber 
                            size="large" 
                            type="number"
                            style={{width:"100%"}}
                            value={amount} 
                            onChange={(value)=>setAmount((prev)=>value)} 
                        />
                    }    
                </div>
            </div></> :<div className="ap-flex ap-flex-center-center ap-w100" style={{height:"150px"}}>
                    <Spin tip="Applying...."/>
                </div>}
        </Card>
    </div>
}

export default Wallet;

