import { API } from "../../Services";

export const admins = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_ADMINS_LIST",
        })
         API.admins(query).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data.admins
            data.map((admin,adminIndex)=>{
                admin.value = admin.admin_id
                admin.label = `${admin.admin_firstname} ${admin.admin_lastname}`
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_ADMINS_LIST",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_ADMINS_LIST",
            })
        })
    }
}