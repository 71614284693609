import React from "react";
import "../../Styles/Master.scss"
import { Icons } from "../../Utils";

const SpinLoader = (props) =>{
    return <div className="ap-flex" style={{alignItem:"center"}}>
        <img
                              src={Icons.Loading}
                              style={{ width: "40px", height: "40px" }}
                              alt="loading"
                            />
   </div>
}

export default SpinLoader