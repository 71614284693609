import styled from "styled-components";

export const SettingsDiv = styled.div`
  display: flex;
`;

export const SettingsMenuDiv = styled.div`
  width: 20%;
  min-height: 36rem;
  border-right: 1px solid #f0f0f0;

  h2 {
    justify-content: flex-start;
    padding:0 1.5rem;
    display: flex;
    font-size: 25px;
    font-weight: 700;
    color: #8080809c;
    svg {
      margin: 4px 0;
    }
  }
`;

export const SettingsDetailDiv = styled.div`
padding:1rem 2rem;
  width: 80%;
`;

export const CustomFieldsDetailDiv = styled.div`
  h2 {
    font-size: 24px;
    font-weight: 500;
  }
  svg {
    margin-bottom: 2px;
  }
 
`;

export const FormModalDiv = styled.div`
  .ant-select-selector {
    width: 28rem !important;
  }
`;