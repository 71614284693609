
const customerLinkRequestListReducer = (
  state = {
    loading: false,
    customers: [],
    totalRecords: 0,
    msg: "",
  },
  action
) => {
  switch (action.type) {
    case "FETCH_CUSTOMER_REQUEST_LINK_LIST":
      return {
        ...state,
        loading: true,
        customers: [],
        totalRecords: 0,
        msg: "",
      };
    case "RECEIVE_CUSTOMER_REQUEST_LINK_LIST":
      return {
        ...state,
        customers: [...action.payload.data],
        loading: false,
        totalRecords: action.payload.totalRecords,
        msg: "",
      };
    case "ERROR_CUSTOMER_REQUEST_LINK_LIST":
      return {
        ...state,
        customers: [],
        loading: true,
        totalRecords: 0,
        msg: action.payload,
      };
    default:
      return { ...state };
  }
};

export default customerLinkRequestListReducer;