import { API } from "../../Services";

export const projectDetail = (projectId) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PROJECT_DETAIL",
    });

    API.projectDetail(projectId)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res)).data.projects;
        let totalData = JSON.parse(JSON.stringify(res)).data?.totalProjects;

        setTimeout(() => {
          dispatch({
            type: "RECEIVE_PROJECT_DETAIL",
            payload: { data, totalRecords: totalData },
          });
        }, 1000);
        // setTimeout(()=>{
        //     dispatch({
        //         type:"RECEIVE_TRELLO_SEARCH_CARDS",
        //         payload:{cards,totalRecords:cards.length},
        //     })
        // },1000)
      })
      .catch((err) => {
        dispatch({
          type: "ERROR_PROJECT_DETAIL",
        });
      });
  };
};