const customerWalletReducer = (state = {
    loading:false,
    wallet:null
}, action) => {
    switch (action.type) {
        case "FETCH_WALLET":
            return { ...state ,loading:true,wallet:null}
        case "RECEIVE_WALLET":
            return {...state, wallet:action.payload,loading:false,}
        case "ERROR_WALLET":
            return { ...state ,wallet:null,loading:true}
        default:
            return { ...state }
    }
}

export default customerWalletReducer;