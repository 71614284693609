const projectsReducer = (state = {
    loading:false,
    projects:[]
}, action) => {
    switch (action.type) {
        case "FETCH_PROJECTS_LIST":
            return { ...state ,loading:true,projectss:[]}
        case "RECEIVE_PROJECTS_LIST":
            return {...state, projects:[...action.payload],loading:false,}
        case "ERROR_PROJECTS_LIST":
            return { ...state ,projects:[],loading:true}
        default:
            return { ...state }
    }
}

export default projectsReducer;