import React, { useEffect, useState } from "react";
import "../../../Styles/Master.scss";
import "./ProjectsList.scss";
import {
  Card,
  Select,
  Spin,
  Button,
  Table,
  Tag,
  Space,
  Input,
  DatePicker,
  Modal,
  Form,
  Row,
  Col,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { API } from "../../../Services";
import toast from "react-hot-toast";

const SitesList = ({ isClient, clientID }) => {
  const { Option } = Select;
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [createForm] = Form.useForm();
  const directions = [
    { value: "EE", label: "East " },
    { value: "WW", label: "West" },
    { value: "NN", label: "North" },
    { value: "SS", label: "South" },
    { value: "NE", label: "North-East" },
    { value: "NW", label: "North-West" },
    { value: "SE", label: "South-East" },
    { value: "SW", label: "South-West" },
  ];
  const { customersList, sitesList, admins, country, states, project } =
    bindActionCreators(actionCreators, dispatch);
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Site Id</div>,
      dataIndex: "projectId",
      key: "projectId",
      render: (text) => <div className="ap-fsm">{text}</div>,
      hidden: true,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Site Name</div>,
      dataIndex: "projectName",
      key: "projectName",
      render: (text) => <div className="ap-fsm">{text}</div>,
      width: "200px",
      fixed: "left",
    },
    {
      title: <div className="ap-fsm ap-fwmd">Plot Area</div>,
      dataIndex: "plot_area",
      key: "plot_area",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Width</div>,
      dataIndex: "width",
      key: "width",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Depth</div>,
      dataIndex: "depth",
      key: "depth",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Type</div>,
      dataIndex: "type",
      key: "type",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Site Stage</div>,
      dataIndex: "stage",
      key: "stage",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Direction</div>,
      dataIndex: "direction",
      key: "direction",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Address</div>,
      dataIndex: "address",
      key: "address",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Pincode</div>,
      dataIndex: "pincode",
      key: "pincode",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Customer Name</div>,
      dataIndex: "customerName",
      key: "customerName",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },

    {
      title: <div className="ap-fsm ap-fwmd">Status</div>,
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Active" ? "var(--primary-green)" : "yellow"}>
          <div className="ap-fsm">{status}</div>
        </Tag>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Space size="small" className="table-btn">
    //       <Button
    //         type="primary"
    //         className="ap-btn-c"
    //         shape="round"
    //         icon={<Icons.Details />}
    //         size="middle"
    //         onClick={() =>
    //           history.push(`/home/ap/site/details/${record.projectId}`)
    //         }
    //       >
    //         <span className="ap-fesm ap-fwmd">Show Details</span>
    //       </Button>
    //     </Space>
    //   ),
    //   width: "fit-content",
    //   fixed: "right",
    // },
  ].filter((item) => !item.hidden);

  const setFormData = (event) => {
    console.log("data", data);
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      if (["status", "customer"].includes(event.target.name)) {
        prev[`${event.target.name}`] = event.target;
      } else {
        prev[`${event.target.name}`] = event.target.value;
      }
      return prev;
    });
  };

  useEffect(() => {
    if (state.sitesList?.sitesList?.length > 0) {
      setTableData((prev) => {
        prev = [];
        state.sitesList.sitesList.map((project, projectIndex) => {
          const directionLabel =
            directions.find((dir) => dir.value === project.direction)?.label ||
            "";

          prev.push({
            key: projectIndex,
            projectId: project.ID,
            projectName: project.name,
            customerName: project.customer?.first_name,
            address: project.address,
            assignedTo: project.assignedTo?.name,
            type: project?.type,
            status: project.status,
            customerId: project.customer?.ID,
            city: project.city,
            direction: directionLabel,
            pincode: project.pincode,
            plot_area: project.plot_area,
            stage: project.stage,
            depth: project.depth,
            width: project.width,
          });
        });
        return prev;
      });
    } else {
      setTableData([]);
    }
  }, [state]);

  const callRecords = (event) => {
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };

  useEffect(() => {
    sitesList(
      `limit=${current.size}&page=${current.page}&customer=${
        clientID ? clientID : ""
      }`
    );
  }, [current]);

  const showCreateModal = () => {
    createForm.resetFields(); // Reset form fields
    setIsCreateModalVisible(true);
  };

  const handleCreateOk = () => {
    createForm
      .validateFields()
      .then((values) => {
        // Handle form submission here
        API.createSite(values)
          .then((res) => {
            toast.success("Site created successfully.", {
              position: "top-right",
            });
            sitesList(
              `limit=${current.size}&page=${current.page}&customer=${
                clientID ? clientID : ""
              }`
            );
          })
          .catch((err) => {
            const error =
              err?.response?.data?.email ||
              err?.response?.data?.city ||
              err?.response?.data?.firstName ||
              err?.response?.data?.phoneNumber;

            toast.error(`Oops!,${error || "Site is not created."}!`, {
              position: "top-right",
            });
          });
        setIsCreateModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsCreateModalVisible(false);
  };

  const handleCountryChange = (value) => {
    states(value);
  };

  return (
    <div className="ap-flex ap-flex-center-center ap-w100">
      {!state.sitesList?.loading ? (
        <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
          <Card
            style={{ width: "100%" }}
            // title={
            //   <div className="ap-w100 ap-flex ap-flex-end ap-fmd ap-fwmd">
            //     <Button
            //       loading={state.sitesList?.loading}
            //       type="primary"
            //       className="ap-btn-c"
            //       shape="round"
            //       icon={<Icons.Plus />}
            //       size="middle"
            //       onClick={showCreateModal}
            //     >
            //       <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">
            //         Add New Site
            //       </span>
            //     </Button>
            //   </div>
            // }
          >
            <Table
              columns={columns}
              dataSource={tableData}
              scroll={{ x: 1300 }}
              pagination={{
                current: current.page,
                pageSize: current.size,
                position: ["bottomLeft"],
                total: state.sitesList?.totalRecords,
                onChange: (data, size) =>
                  callRecords({
                    type: "page",
                    payload: { page: data, size: size },
                  }),
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Card>
        </div>
      ) : (
        <div
          className="ap-flex ap-w100 ap-flex-center-center"
          style={{ height: "300px" }}
        >
          <Spin tip="Fetching Records....." />
        </div>
      )}
      <Modal
        title="Create Site"
        visible={isCreateModalVisible}
        onOk={handleCreateOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleCreateOk}>
            Create
          </Button>,
        ]}
      >
        <Form form={createForm} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="projectName"
                label="Site Name"
                rules={[
                  { required: true, message: "Please enter the site name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="plot_area"
                label="Plot Area"
                rules={[
                  { required: true, message: "Please enter the plot area" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="width"
                label="Width"
                rules={[{ required: true, message: "Please enter the width" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="depth"
                label="Depth"
                rules={[{ required: true, message: "Please enter the depth" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: "Please enter the type" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="stage"
                label="Site Stage"
                rules={[
                  { required: true, message: "Please enter the site stage" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="direction" label="Direction">
                <Select>
                  {directions.map((dir) => (
                    <Option key={dir.value} value={dir.value}>
                      {dir.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  { required: true, message: "Please enter the address" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[
                  { required: true, message: "Please enter the pincode" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="country" label="Country">
                <Select
                  showSearch
                  placeholder="Select Country"
                  onChange={handleCountryChange}
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.countries?.countries?.map((country) => (
                    <Option key={country.code} value={country.code}>
                      {country.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="state" label="State">
                <Select
                  showSearch
                  placeholder="Select State"
                  style={{ width: "100%" }}
                  disabled={state.states.loading}
                  loading={state.states.loading}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {state.states?.states?.map((state) => (
                    <Option key={state.code} value={state.code}>
                      {state.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city" label="City">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default SitesList;
