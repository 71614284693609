import React, { useEffect, useRef } from 'react'
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

export default function ZoomImage(props) {
    const imgRef = useRef(null);
    const quickPinchZoomRef = useRef(null);

    const onUpdate = ({ x, y, scale }) => {
      const { current: img } = imgRef;

      if (img && img.complete) {
        const value = make3dTransformValue({ x, y, scale });
        img.style.setProperty("transform", value);
        img.style.opacity = 1;
      } else if (img) {
        const value = make3dTransformValue({ x: 0, y: 0, scale: 1 });
        img.style.setProperty("transform", value);
        img.style.opacity = 0;
      }
    };
     const resetZoom = () => {
       const { current: img } = imgRef;
       const { current: quickPinchZoomElement } = quickPinchZoomRef;

       const { width, height } = img;

       const centerX = width / 2;
       const centerY = height / 2;

       quickPinchZoomElement.alignCenter({ x: centerX, y: centerY, scale: 1 });
     };
useEffect(() => {
     function cleanup() {
       resetZoom();
    }
     cleanup();
}, []);
    

  return (
    <div>
      {" "}
      <QuickPinchZoom
        onUpdate={onUpdate}
        // onUpdate={() => {}}
        ref={quickPinchZoomRef}
        draggableUnZoomed={false}
        shouldInterceptWheel={() => false}
      >
        <img
          className="zoom-image product-image"
          src={props?.url}
          alt="zoomed-item"
          style={{
            height: "460px",
            maxWidth: "100%",
            objectFit: "contain",
          }}
          ref={imgRef}
        />
      </QuickPinchZoom>
    </div>
  );
}
