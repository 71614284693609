import React, { useState, useEffect } from "react";
import { Card, Select, Spin, Button, } from "antd";
import "./SelectProject.scss";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import { useHistory, useParams } from "react-router-dom";
import { Icons } from "../../Utils";
import toast from "react-hot-toast";
import { API } from "../../Services";
import { BillingReminder } from "../Invoices";
import { Modal } from "reactstrap";

const SelectProject = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { customersList, projects, project, customer } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const { Option } = Select;
  const [data, setData] = useState({});
  const history = useHistory();
  const { view } = useParams();
  const [isBillingInfoModalOpen, setIsBillingInfoModalOpen] = useState(false);
  const [isSendBilingDisable, setSendBilingDisable] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState();

  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev[`${event.target.name}`] = event.target;
      return prev;
    });
    if (event.target.name === "customer") {
      customer(event.target.value);
      projects(event.target.value);
    } else {
      project(event.target.value);
    }
  };

  const handleView = () => {
    if (view === "do") {
      if(state.project.project?.category == "interior")
      {
        toast.error("Draft Order not Allowed!", {
          position: "top-right",
        });
      }else{
        history.push(`/home/ap/draft/order/create/${data?.project?.value}`);
      }
      
    } else {
      localStorage.setItem("customerNo", state.customer.customer?.phoneNumber);
      history.push(
        `/home/ap/custom/order/create/${data?.customer?.value}/${state.project?.project?.projectCode}/${state.project?.project?.category}/${state.project?.project?.type}`
      );
    }
  };
  const handleCustomerChange = (d) => {
    setData({ customer: d });

    // Replace this with your actual logic to check billing info status
    const selectedCustomer = state.customers.customers.find(
      (customer) => customer.value === d.value
    );
    setSelectedCustomerId(selectedCustomer.id);
    if (selectedCustomer.billing_info_status==0) {
      console.log("isdata bill",selectedCustomer.billing_info_status)
      setIsBillingInfoModalOpen(true);
    }
  };
  // const handleSendReminder = () => {
  //   setSendBilingDisable(true);
  //   API.sendBilingLink(selectedCustomerId)
  //     .then((res) => {
  //       console.log(res);
  //       setSendBilingDisable(false);
  //       setIsBillingInfoModalOpen(false);

  //       setData({ customer: null });
  //       toast.success(res?.data?.message, {
  //         position: "top-right",
  //       });
  //     })
  //     .catch((err) => {
  //       setSendBilingDisable(false);
  //       console.log(err);
  //       toast.error("Error! ", err, {
  //         position: "top-right",
  //       });
  //     });
  //   setIsBillingInfoModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setData({ customer: null });
  //   setIsBillingInfoModalOpen(false);
  // };
  return (
    <div className="ap-flex ap-flex-center-center ap-w100 select-project">
      <Card style={{ width: "100%" }}>
        <div className="ap-flex ap-w100 ap-flex-space-bw">
          <div className="ap-flex ap-flex-center-center ap-w33">
            <div className="ap-flex ap-fsm ap-fwmd ap-w100">Customer</div>
            <Select
              size="large"
              showSearch
              onSearch={(data) => customersList(`q=${data}`)}
              labelInValue
              placeholder="Select customer"
              style={{ width: "100%" }}
              value={data?.customer}
              loading={state.customers.loading}
              onChange={(d) =>
               { handleCustomerChange(d);

                setFormData({ target: { name: "customer", ...d } })}
              }
              notFoundContent={
                state.customers.loading && (
                  <div className="ap-w100 ap-flex ap-flex-center">
                    <Spin size="small" />
                  </div>
                )
              }
              filterOption={false}
            >
              {state.customers.customers.map((customer, customerIndex) => (
                <Option value={customer.value} key={customerIndex}>
                  {customer.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="ap-flex ap-flex-center-center ap-w33">
            <div className="ap-flex ap-fsm ap-fwmd ap-w100">Project</div>
            <Select
              size="large"
              labelInValue
              showSearch
              placeholder="Select project"
              style={{ width: "100%" }}
              value={data?.project}
              loading={state.projects.loading}
              onChange={(d) => {
                setFormData({ target: { name: "project", ...d } });
              }}
              onSearch={(data) => projects(state.customer.customer?.Id, data)}
              disabled={state.projects.projects.length === 0}
              filterOption={false}
            >
              {state.projects.projects.map((project, projectIndex) => (
                <Option value={project.value} key={projectIndex}>
                  {project.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">or</div>
          <div className="ap-flex ap-flex-center-center ap-w24">
            <Button
              type="primary"
              className="ap-btn-c co-btn"
              shape="round"
              icon={<Icons.Plus />}
              size="large"
              htmlType="button"
              onClick={() => {
                if (view === "co") {
                  history.push("/home/ap/project/create/for/co");
                } else {
                  history.push("/home/ap/draft/order/create");
                  localStorage.removeItem("orderid");
                  localStorage.removeItem("draftOrderDetails");

                }
              }}
            >
              <span className="ap-fsm ap-fwmd">
                Create New {view === "co" ? "Project" : "Draft Order"}
              </span>
            </Button>
          </div>
        </div>
      </Card>

      {!state.projects.loading &&
        state.project.project !== null &&
        data?.project && (
          <Card
            size="small"
            style={{ width: "100%", marginTop: "20px" }}
            title={
              <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
                Project Details
              </div>
            }
          >
            <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Project Name
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.name}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Project Code
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.projectCode}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Project Category
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.category}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Project Type
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.type}
              </div>
            </div>
            { state.project.project?.category !== "interior" &&<>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Width
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.width}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Depth
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.length}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Direction
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.direction}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Floor
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.floor}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Area
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.buildArea}
              </div>
            </div></>}
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Created By
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.createdBy?.name}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Assigned To
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.assignedTo?.name}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Status
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.status}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-end ap-p5">
              <Button
                type="primary"
                className="ap-btn-c co-btn"
                shape="round"
                size="large"
                htmlType="button"
                onClick={() => {
                  handleView();
                }}
              >
                <span className="ap-fsm ap-fwmd">Confirm</span>
              </Button>
            </div>
          </Card>
        )}
       {/* <Modal
        title="Billing Info Not Available"
        visible={isBillingInfoModalOpen}
        onCancel={handleCancel} 
        maskClosable={false} 
        footer={[
          <Button key="close" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="reminder" type="primary" onClick={handleSendReminder}>
            Send Billing Info Reminder
          </Button>,
           <Button key="reminder" type="primary" onClick={() => {
            // setClientID(state.invoicesList.invoices[0]?.customerId);
            // onBillOpen();
          }}>
            Add Billing Information
         </Button>
          ,
        ]}
      >
        The billing info for this customer is not available. Would you like to send a reminder?
      </Modal> */}
      
       <Modal
        isOpen={isBillingInfoModalOpen} 
        // toggle={this.toggleBillModal}
      >
        <BillingReminder setData={setData}  isClass={false} selectedCustomerId={selectedCustomerId} setIsBillingInfoModalOpen={setIsBillingInfoModalOpen}/>
      </Modal>

    </div>
  );
};

export default SelectProject;
