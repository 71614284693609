import { API } from "../../Services";

export const browserFileActionLogsList = (id, query = "") => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BROWSER_FILE_LOGS_LIST",
    });

    API.browserFileActionLogs(id, query)
      .then((res) => {
        console.log(res);
        let data = JSON.parse(JSON.stringify(res)).data.data;

        setTimeout(() => {
          dispatch({
            type: "RECEIVE_BROWSER_FILE_LOGS_LIST",
            payload: {
              data: data,
              totalRecords: res.data.totalProjectFilteredLogs,
            },
          });
        }, 1000);
      })
      .catch((err) => {
        let data = JSON.parse(JSON.stringify(err?.response?.data?.message));

        dispatch({
          type: "ERROR_BROWSER_FILE_LOGS_LIST",
          payload: data,
        });
      });
  };
};

