import { API } from "../../Services"

export const project = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PROJECT",
        })
         API.project(id).then((res)=>{
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PROJECT",
                    payload:res.data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_PROJECT",
            })
        })
    }
}