import React, { useState, useEffect, useMemo } from "react";
import "./Approver.scss";
import { Card, Select, Option, Input, Button, Alert, Space } from "antd";
import { Box, Flex, Center, Text } from "@chakra-ui/react";
import { API } from "../../../Services";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import customOrderStatus from "../../../Services/Apis/customOrderStatus";

const Approver = (props) => {
  const [data, setData] = useState({
    products: [],
    approverRemark: "",
  });
  const { TextArea } = Input;
  const state = useSelector((state) => state);
  const { id } = useParams();
  const [load, setLoad] = useState(false);
  const [alert, setAlert] = useState({
    status: false,
  });
  const dispatch = useDispatch();
  const { customOrder } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const checKIsDisabled = useMemo(() => {
    let prCount = state.customOrder.customOrder?.products.length;
    let priceCount = 0;
    data.products.forEach((pr) => {
      Object.entries(pr).forEach(([key, value]) => {
        if (key === "price" && value !== "") {
          priceCount = priceCount + 1;
        }
      });
    });
    console.log(prCount, priceCount);
    if (priceCount === prCount && (data.approverRemark !== ""||state.customOrder.customOrder?.approverRemark !== "")) {
      return false;
    } else {
      return true;
    }
  }, [data]);

  const setFormData = (event) => {
    setData((prev) => {
      prev = { ...prev };
      if (event.target.name === "products") {
        if (event.target.index !== undefined) {
          prev.products[event.target.index] = { ...event.target.value };
        } else {
          prev.products.push(event.target.value);
        }
      } else {
        prev[`${event.target.name}`] = event.target.value;
      }
      return prev;
    });
  };

  const approveOrder = () => {
    setLoad((prev) => !prev);
    API.approveCustomOrder({
      orderId: id,
      ...data,
    })
      .then((res) => {
        setLoad((prev) => !prev);
        setAlert((prev) => {
          prev = JSON.parse(JSON.stringify(prev));
          prev.status = true;
          prev.type = "success";
          prev.message = "Approved";
          prev.description = `This order is successfully approved !`;
          return prev;
        });
        setTimeout(() => {
          setAlert((prev) => {
            prev = {};
            prev.status = false;
            return prev;
          });
          customOrder(id);
        }, 2000);
      })
      .catch((err) => {
        setLoad((prev) => !prev);
        setAlert((prev) => {
          prev = JSON.parse(JSON.stringify(prev));
          prev.status = true;
          prev.type = "error";
          prev.message = "Not Approved";
          prev.description = `This order is not approved !`;
          return prev;
        });
        setTimeout(() => {
          setAlert((prev) => {
            prev = {};
            prev.status = false;
            return prev;
          });
        }, 2000);
      });
  };

  const handleDecline = () => {
    setLoad((prev) => !prev);
    API.customOrderStatus({
      orderId: id,
      order_status: 4,
      approverRemark: data?.approverRemark,
    })
      .then((res) => {
        setLoad((prev) => !prev);
        setAlert((prev) => {
          prev = JSON.parse(JSON.stringify(prev));
          prev.status = true;
          prev.type = "success";
          prev.message = "Order Declined";
          prev.description = `This order is successfully declined !`;
          return prev;
        });
        setTimeout(() => {
          setAlert((prev) => {
            prev = {};
            prev.status = false;
            return prev;
          });
          customOrder(id);
        }, 2000);
      })
      .catch((err) => {
        setLoad((prev) => !prev);
        setAlert((prev) => {
          prev = JSON.parse(JSON.stringify(prev));
          prev.status = true;
          prev.type = "error";
          prev.message = "Not Declined";
          prev.description = `This order is not declined !`;
          return prev;
        });
        setTimeout(() => {
          setAlert((prev) => {
            prev = {};
            prev.status = false;
            return prev;
          });
        }, 2000);
      });
  };

  return (
    <form
      className="ap-flex ap-w100"
      style={{ marginTop: "20px" }}
      onSubmit={(event) => {
        event.preventDefault();
        approveOrder();
      }}
    >
      {alert.status && (
        <Alert
          message={alert?.message}
          description={alert?.description}
          type={alert?.type}
          showIcon
          closable
        />
      )}
      <Card size="small" style={{ width: "100%" }}>
        {state.customOrder.customOrder?.products?.map(
          (product, productIndex) => (
            <Box
              w="100%"
              border="1px solid rgba(0,0,0,0.5)"
              p={3}
              borderRadius={10}
              mt={5}
              key={productIndex}
            >
              <div>
                <Box className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                  <Text color="black" fontSize="xl" fontWeight="600">
                    Service
                  </Text>
                  <Text color="black" fontSize="md" fontWeight="400">
                    {product?.product?.name}
                  </Text>
                </Box>

                {!Array.isArray(product.unit) && (
                  <Box className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                    <Text color="black" fontSize="xl" fontWeight="600">
                      Unit
                    </Text>
                    <Text color="black" fontSize="md" fontWeight="400">
                      {product?.unit?.name}
                    </Text>
                  </Box>
                )}

                <Box className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                  <Text color="black" fontSize="xl" fontWeight="600">
                    Estimated Budget
                  </Text>
                  <Text color="black" fontSize="md" fontWeight="400">
                    {product.estimatedBudget}
                  </Text>
                </Box>
              </div>
              {product.description && product.description !== "" && (
                <Flex mt={4}>
                  <Box className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                    <Text color="black" fontSize="xl" fontWeight="600">
                      Description
                    </Text>
                    <Text color="black" fontSize="md" fontWeight="400">
                      {product.description}
                    </Text>
                  </Box>
                </Flex>
              )}
              <Flex mt={4}>
                <div className="ap-flex ap-flex-center-center ap-w50">
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Price</div>
                  <Input
                    size="large"
                    //type="number"
                    style={{ width: "100%" }}
                    placeholder="Enter price"
                    
                    {...state.customOrder.customOrder?.orderStatus === "approved" ? {value:product?.price}:{value:
                      data.products[productIndex] &&
                      data.products[productIndex]?.price
                    }}
                    disabled={state.customOrder.customOrder?.orderStatus === "approved"}
                    name="price"
                    onChange={(e) =>
                      setFormData({
                        target: {
                          name: "products",
                          index: productIndex,
                          value: {
                            productServiceID: product.ID,
                            price: e.target.value,
                            activationAmount: e.target.value,
                          },
                        },
                      })
                    }
                  />
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w50"
                  style={{ marginLeft: "10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Activation Amount
                  </div>
                  <Input
                    size="large"
                   // type="number"
                    style={{ width: "100%" }}
                    placeholder="Enter activation amount"
                    {...state.customOrder.customOrder?.orderStatus === "approved" ? {value:product?.price}:{value:
                      data.products[productIndex] &&
                      data.products[productIndex]?.price
                    }}
                    name="activation"
                    disabled={true}
                  />
                </div>
              </Flex>
            </Box>
          )
        )}

        <Flex mt={5}>
          <div className="ap-flex ap-w100 " style={{ marginTop: "5px" }}>
            <div className="ap-flex ap-flex-center-center ap-w100">
              <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Remark</div>
              <TextArea
                rows={8}
                placeholder="Enter remark"
                {...(state.customOrder.customOrder?.orderStatus === "approved"||state.customOrder.customOrder?.orderStatus === "cancelled")? {value: state.customOrder.customOrder?.approverRemark}:{value:
                  data?.approverRemark  
                }}
                disabled={state.customOrder.customOrder?.orderStatus === "approved"||state.customOrder.customOrder?.orderStatus === "cancelled"}
                name="approverRemark"
                onChange={(d) => setFormData(d)}
              />
            </div>
          </div>
        </Flex>
        {state.customOrder.customOrder?.orderStatus !== "approved" &&
<div
          className="ap-flex ap-w100 ap-flex-end ap-p5"
          style={{ marginTop: "20px" }}
        >
          <div className="ap-flex ap-flex-center-center approver-btn">
            <Button
              type="primary"
              className="ap-btn-c"
              shape="round"
              size="large"
              htmlType="submit"
              disabled={checKIsDisabled}
              loading={load}
            >
              <span className="ap-fsm ap-fwmd">Approve</span>
            </Button>
          </div>
          <div
            className="ap-flex ap-flex-center-center "
            style={{ marginLeft: "10px" }}
          >
            <Button
              type="primary"
              className="ap-btn-danger"
              shape="round"
              disabled={state.customOrder.customOrder?.orderStatus === "cancelled"}
              size="large"
              htmlType="button"
              loading={load}
              onClick={() => handleDecline()}
            >
              <span className="ap-fsm ap-fwmd">Decline</span>
            </Button>
          </div>
        </div>}
      </Card>
    </form>
  );
};

export default Approver;

