import { API } from "../../Services"

export const projects = (id,q) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PROJECTS_LIST",
        })
         API.projects(id,q).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data.projects
            data.map((project,projectIndex)=>{
                project.value = project.ID
                project.label = project.name
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PROJECTS_LIST",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_PROJECTS_LIST",
            })
        })
    }
}