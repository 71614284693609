const trelloCheckListReducer = (state = {
    loading:false,
    list:[]
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_CHECK_LIST":
            return { ...state ,loading:true}
        case "RECEIVE_TRELLO_CHECK_LIST":
            return {...state, list:[...action.payload],loading:false}
        case "ERROR_TRELLO_CHECK_LIST":
            return { ...state ,list:[],loading:false}
       default:
            return { ...state }
    }
}

export default trelloCheckListReducer;