const gatewayListReducer = (state = {
    loading:false,
    gateway:[],totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_GATEWAY_LIST":
            return { ...state ,loading:true,gateway:[],totalRecords:0}
        case "RECEIVE_GATEWAY_LIST":
            return {...state, gateway:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_GATEWAY_LIST":
            return { ...state ,gateway:[],loading:true,totalRecords:0}
        default:
            return { ...state }
    }
}

export default gatewayListReducer;