import countryList from 'react-select-country-list';
import Flags from 'country-flag-icons/react/3x2'
import countryCodes from "country-codes-list"
import React from "react"

export const phoneCountryInput = () =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PHONE_COUNTRY_INPUT",
        })

        async function pci (){
            let c = countryList().getData()
            let cc = countryCodes.customList('countryCode','+{countryCallingCode}')
            c.map((country,countryIndex)=>{
            let F = Flags[`${country.value}`]
            country.label = <div className="ap-flex ap-w100">
            <div className="ap-flex ap-flex-center-center">
                <F  style={{width:"32px",height:"25px"}}/>
            </div>
            <div className="ap-flex ap-flex-center-center ap-fesm ap-fwmd" style={{marginLeft:"5px"}}>
                {country.label}
            </div>
            </div>
            country.value = cc[`${country.value}`]
            })
            return c
        }

        pci().then((res)=>{
            dispatch({
                type:"RECIEVE_PHONE_COUNTRY_INPUT",
                payload:res
            })
        })
    }
}