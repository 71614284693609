import React, { useEffect } from "react";
import { DatePicker } from 'antd';
import "./dueDate.scss";
import { API } from "../../Services";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import moment from "moment";
import { Text } from "@chakra-ui/react";
import "moment-timezone";


const DueDate = (props) => {

    const { id } = useParams();
    const state = useSelector(state=>state)
    const dispatch = useDispatch();
    const {trelloCardInfo,trelloCommentList } = bindActionCreators(actionCreators,dispatch)
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
      };
      
      
    useEffect(() => {
         console.log(state.trelloCardInfo?.cardInfo?.dueDate)
    }, [state.trelloCardInfo])
    
            
    const handleDate = (dateString) => {
        let data = {
            dueDate:dateString
        }
        var formData = new FormData();
        for (var i in data) {
          formData.append(i, data[i]);
        }
       // let d = new Date(dateString)
        //console.log('date time',moment(d).format('yyyy-MM-ddTHH:mm:ss.SSSZ'))

        console.log(data)
        API.trelloCardUpdate(id,formData).then((res) => {
            console.log(res)
           // console.log('dueDate',d)

            trelloCardInfo(id)
            trelloCommentList(id, 20, 1);

            toast.success("Due date updated", {
                position: "top-right",
            });

        }).catch((err) => {
            toast.error("Oops ! Due date not updated", {
                position: "top-right",
            });
        })
    }

    return  <div className="trello-date">
        <Text
            fontSize = "lg"
            mt = { 2 }
            color = 'gray'
        >
            Due Date
        </Text>
        <DatePicker 
            format={
                "MMM D, YYYY [at] hh:mm A z"
              }
              onOk={(value,dateString)=>{
                console.log('date time',moment(value).format('MMM D, YYYY [at] hh:mm A z').toLocaleString('en-US', { timeZone: 'America/New_York' }))
                props.SetCommentPage(1)
                handleDate(value)}} 
            //onChange={(value,dateString)=>{handleDate(value)}} 
            value = {state.trelloCardInfo?.cardInfo?.dueDate? moment(state.trelloCardInfo?.cardInfo?.dueDate).tz("Asia/Kolkata"):''}
            placeholder="Due Date"
            allowClear={false}
            showTime={{ use12Hours: true, format: "h:mm A" }}
        />
        
    </div>
    
}

export default DueDate;