import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { Spin } from "antd";

export default function PreviewSlider({ BaseUrl, imageList }) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      const token = localStorage.getItem("token");

      try {
        const fetchedImages = await Promise.all(
          imageList.map(async (item) => {
            const response = await axios.get(
              `${BaseUrl}/internal/storage/${item?.filePath}`,
              {
                responseType: "blob",
                headers: {
                  "X-Api-Key": token,
                },
              }
            );
            const imageUrl = URL.createObjectURL(response.data);
            return { ...item, imageUrl };
          })
        );
        setImages(fetchedImages);
        setLoading(false); // Set loading to false when images are fetched
      } catch (error) {
        console.error("Error fetching images:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchImages();
  }, [BaseUrl, imageList]);

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            src={images[i]?.imageUrl}
            alt=""
            style={{ height: "45px", width: "50px", objectFit: "cover" }}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {loading ? ( 
        <div className="slider-container flex ap-flex ap-w100 ap-flex-center-center">
          <div className="flex ap-flex ap-flex-center-center">
            <Spin />
            <span>Loading...</span>
          </div>
        </div>
      ) : (
        <div className="slider-container">
          <Slider {...settings}>
            {images.map((item, index) => (
              <div key={index}>
                <img
                  src={item.imageUrl}
                  alt=""
                  style={{
                    height: "460px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
}
