import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import { Button, Input, Select } from "antd";
import { FItem } from "./interioriDesignListingStyle";
import { green } from "@material-ui/core/colors";
import { API } from "../../Services";
import toast from "react-hot-toast";

const EditInteriorDesignModal = ({
  isOpen,
  onClose,
  data,
  categories,
  subCategories,
  interiorDesignList,
  formData,
  current,
}) => {
  const [width, setWidth] = useState(data.width);
  const [depth, setDepth] = useState(data.depth);
  const [category, setCategory] = useState(data.category);
  const [subCategory, setSubCategory] = useState(data.subCategory);
  const [folderLink, setFolderLink] = useState(data.folderPath);
  const { Option } = Select;

  const widthDepthOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: "XX", label: "XX" },
  ];
  
  useEffect(() => {
    console.log("data", data);
    setWidth(data.width);
    setDepth(data.depth);
    setCategory(data.category);
    setSubCategory(data.subCategory);
    setFolderLink(data.folderPath);
  }, [data]);

  const handleSave = () => {
    // Call API to update interior design details
    console.log("Updated data:", {
      width,
      depth,
      category,
      subCategory,
      folderLink,
    });
    let fdata = {
      width: width,
      depth: depth,
      category: category,
      subCategory: subCategory,
      folderPath: folderLink,
    };
    API.updateInteriorDetail(data?.uuid, fdata)
      .then((res) => {
        toast.success("Interior details are updated successfully.", {
          position: "top-right",
        });
        onClose();
        let querry = "";

        Object.entries(formData).map(([key, value], formDataIndex) => {
          querry = querry + `&${key}=${value}`;
        });
        let offset = current.page === 1 ? 0 : Number(current.page) * 50 - 50;

        let url = `limit=${current.size}&offset=${offset}&${querry}&page=${current.page}`;
        interiorDesignList(url);
      })
      .catch((err) => {
        toast.error(`Oops!,${err || "Interior details are not updated."}!`, {
          position: "top-right",
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered // Centers the modal
    >
      <ModalOverlay />
      <ModalContent style={{ margin: "1rem", width: "85%" }}>
        <ModalCloseButton fontSize={"1rem"} backgroundColor={green} />

        <ModalBody>
          <h2
            style={{
              padding: "0.5rem 1rem",
              fontWeight: "600",
              fontSize: "large",
            }}
          >
            Edit Design Information
          </h2>

          <div style={{ padding: "1.2rem" }}>
            <FItem>
              <span className="f-check-group">
                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Width</FormLabel>
                  <Select
                    size="medium"
                    value={width?.toString()} // Convert width to string
                    onChange={(value) => setWidth(Number(value))}
                    placeholder="Select Width"
                    style={{ width: "98%" }}
                  >
                    {widthDepthOptions.map((row, rowIndex) => (
                      <Option value={row.value?.toString()} key={rowIndex}>
                        {row.label}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Depth</FormLabel>
                  <Select
                    size="medium"
                    value={depth?.toString()} // Convert depth to string
                    onChange={(value) => setDepth(Number(value))}
                    placeholder="Select Depth"
                    style={{ width: "98%" }}
                  >
                    {widthDepthOptions.map((row, rowIndex) => (
                      <Option value={row.value?.toString()} key={rowIndex}>
                        {row.label}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </span>
            </FItem>
            <FItem>
              <span className="f-check-group">
                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Category</FormLabel>
                  <Select
                    size="medium"
                    value={category}
                    onChange={(value) => setCategory(value)}
                    placeholder="Select Category"
                    style={{ width: "98%" }}
                  >
                    {categories?.map((row, rowIndex) => (
                      <Option value={row} key={rowIndex}>
                        {row}
                      </Option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Subcategory</FormLabel>
                  <Select
                    size="medium"
                    value={subCategory}
                    onChange={(value) => setSubCategory(value)}
                    placeholder="Select Sub Category"
                    style={{ width: "98%" }}
                  >
                    {subCategories?.map((row, rowIndex) => (
                      <Option value={row} key={rowIndex}>
                        {row}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </span>
            </FItem>
            <FItem>
              <FormControl as="fieldset">
                <FormLabel className="f-lable">Folder Path :</FormLabel>
                <Input
                  allowClear
                  value={folderLink}
                  placeholder="Enter Folder Path..."
                  name="folderPath"
                  style={{ width: "95%" }}
                  onChange={(e) => setFolderLink(e.target.value)}
                />
              </FormControl>
            </FItem>
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave}>Update</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditInteriorDesignModal;
