const unitsReducer = (
  state = {
    loading: false,
    units: [],
  },
  action
) => {
  switch (action.type) {
    case "FETCH_UNITS":
      return { ...state, loading: true, units: [] };
    case "RECEIVE_UNITS":
      return { ...state, units: [...action.payload], loading: false };
    case "ERROR_UNITS":
      return { ...state, units: [], loading: false };
    default:
      return { ...state };
  }
};

export default unitsReducer;
