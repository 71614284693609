import { API } from "../../Services";

export const trelloAttachmentList = (id,limit,page) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_TRELLO_ATTACHMENTS",
        })
    
        API.trelloAttachmentList(id,limit,page).then((res)=>{
            console.log(res);
            let data = JSON.parse(JSON.stringify(res)).data.attachments
            console.log(data);
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_TRELLO_ATTACHMENTS",
                    payload:{data,totalRecords:res.data.totalRecords},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_TRELLO_ATTACHMENTS",
            })
        })
    }
}