const customersReducer = (state = {
    loading:false,
    customers:[],totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_CUSTOMERS_LIST":
            return { ...state ,loading:true,customers:[],totalRecords:0}
        case "RECEIVE_CUSTOMERS_LIST":
            return {...state, customers:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_CUSTOMERS_LIST":
            return { ...state ,customers:[],loading:true,totalRecords:0}
        default:
            return { ...state }
    }
}

export default customersReducer;