import { axiosInstance } from ".."

const clientUpdate = async (id,data) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.put(`internal/customer/${id}`,data).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default clientUpdate;