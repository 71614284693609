import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import toast from "react-hot-toast";
import { Card, Input, Spin, Table } from "antd";
import {
  ActionButtons,
  DetailContent,
  DetailHeading,
  DetailItem,
  DLable,
  DValue,
  TButton,
} from "./invoiceStyle";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  CloseButton,
  ModalBody,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import { Icons } from "../../../Utils";
import EditInvoiceDetailForm from "./EditInvoiceDetailForm";
import { API, baseURL } from "../../../Services";
import { EditBillingInfo } from "../../Clients/ClientsBillingInfo";
import axios from "axios";

export default function InvoiceDetails() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isBillOpen,
    onOpen: onBillOpen,
    onClose: onBillClose,
  } = useDisclosure();

  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const history = useHistory();
  const [customerDetail, setCustomerDetail] = useState([]);
  const [itemsDetail, setItemsDetail] = useState([]);
  const [totalDetail, setTotalDetail] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [isSendBilingDisable, setSendBilingDisable] = useState(false);
  const [isConfirmDisable, setConfirmDisable] = useState(false);
  const [clientID, setClientID] = useState([]);
 const [urlOpenLoader, setUrlOpenLoader] = useState(false);
 const [urlOpenId, setUrlOpenId] = useState("");
  const { invoicesList, customer } = bindActionCreators(
    actionCreators,
    dispatch
  );
const getUrl = (value, urlId) => {
  setUrlOpenLoader(true);
  setUrlOpenId(urlId);
  console.log("url", value);
  const config = {
    headers: {
      "X-API-Key": localStorage.getItem("token"),
    },
  };
  axios
    .get(value, config)
    .then((response) => {
      console.log("response.data", response);
      setUrlOpenLoader(false);
      setUrlOpenId("");
      window.open(response?.data?.data?.url, "_blank");
    })
    .catch((error) => {
      setUrlOpenLoader(false);
      setUrlOpenId("");

      console.error("Error fetching data:", error);
      toast.error("Error", error, {
        position: "top-right",
      });
    });
};
  useEffect(() => {
    invoicesList(`invoiceID=` + id);
    console.log(state.partnerDetailsSingle);
  }, []);

  const ResendInvoice = () => {
    setSendBilingDisable(true);
    API.resendInvoice(id)
      .then((res) => {
        console.log(res);
        setSendBilingDisable(false);

        toast.success(res?.data?.message, {
          position: "top-right",
        });
      })
      .catch((err) => {
        setSendBilingDisable(false);
        console.log(err);
        toast.error("Error! ", err, {
          position: "top-right",
        });
      });
  };
  const SendBilingLink = () => {
    setSendBilingDisable(true);
    API.sendBilingLink(state.invoicesList?.invoices[0]?.customerId)
      .then((res) => {
        console.log(res);
        setSendBilingDisable(false);

        toast.success(res?.data?.message, {
          position: "top-right",
        });
      })
      .catch((err) => {
        setSendBilingDisable(false);
        console.log(err);
        toast.error("Error! ", err, {
          position: "top-right",
        });
      });
  };
  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Invoice Id</div>,
      dataIndex: "invoiceId",
      key: "invoiceId",
      render: (text) => <div className="ap-fsm">{text}</div>,
      hidden: true,
    },
    {
      title: <div className="ap-fsm ap-fwmd">item Name</div>,
      dataIndex: "item",
      key: "item",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Description</div>,
      dataIndex: "description",
      key: "description",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Quantity</div>,
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Unit</div>,
      dataIndex: "unit",
      key: "unit",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Rate</div>,
      dataIndex: "rate",
      key: "rate",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },

    {
      title: <div className="ap-fsm ap-fwmd">Total Amount</div>,
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
  ].filter((item) => !item.hidden);

  //   useEffect(() => {
  //     if(state.invoicesList.invoices)
  // {
  //     customer(state.invoicesList?.invoices[0]?.customerId);
  //   console.log(state.invoicesList?.invoices[0]?.customerId)
  // }
  //   }, [state.invoicesList?.invoices[0]?.customerId])
  useEffect(() => {
    if (state.invoicesList.invoices.length > 0) {
      setCustomerDetail((prev) => {
        prev = [];
        state.invoicesList.invoices.map((invoice, invoiceIndex) => {
          prev.push(
            {
              lable: "Customer Name",
              value: `${invoice.firstName} ${invoice.lastName}`,
              linkId: invoice.customerId,
            },
            { lable: "Email", value: invoice.email || "NA" },
            { lable: "Contact No", value: `${invoice?.phoneNumber || "NA"}` },
            { lable: "Business Name", value: invoice?.businessName || "NA" },
            { lable: "GST NO", value: invoice?.gstNo || "NA" },
            { lable: "PAN NO", value: invoice?.panNo || "NA" }
          );
        });
        return prev;
      });
      setInvoiceDetail((prev) => {
        prev = [];
        state.invoicesList.invoices.map((invoice, invoiceIndex) => {
          prev.push(
            // { lable: "Invoice Type", value: invoice.invoiceType || "NA" },
            {lable:"Invoice ID",value:invoice.invoiceNo||"NA"},
            { lable: "Currency", value: `${invoice?.currency || "NA"}` },
            {
              lable: "Total Amount",
              value: invoice?.totalAmount
                ? `${invoice?.currency} ${invoice?.totalAmount}`
                : "NA",
            },
            { lable: "Entity", value: invoice?.entity || "NA" },
            { lable: "Status", value: invoice?.status || "NA" },
            { lable: "Remarks", value: invoice?.remarks || "NA" },
            {
              lable: "Last Updated At",
              value: invoice?.modifiedDate
                ? moment(invoice?.modifiedDate).format(
                    "MMM D, YYYY [at] h:mm A z"
                  )
                : "NA",
            }
          );
        });
        return prev;
      });
      setBillingAddress((prev) => {
        prev = [];
        [state.invoicesList.invoices[0].billingAddress].map(
          (invoice, invoiceIndex) => {
            prev.push(
              {
                lable: "Address",
                value: invoice?.addressLine_1
                  ? `${invoice?.addressLine_1} ${invoice?.addressLine_2}`
                  : "NA",
              },
              { lable: "Pin Code", value: `${invoice?.pincode || "NA"}` },
              { lable: "city", value: invoice?.city || "NA" },
              { lable: "state", value: invoice?.state || "NA" },
              { lable: "country", value: invoice?.country || "NA" }
            );
          }
        );
        return prev;
      });
      setShippingAddress((prev) => {
        prev = [];
        [state.invoicesList.invoices[0].shippingAddress].map(
          (invoice, invoiceIndex) => {
            prev.push(
              {
                lable: "Address",
                value: invoice?.addressLine_1
                  ? `${invoice?.addressLine_1} ${invoice?.addressLine_2}`
                  : "NA",
              },
              { lable: "Pin Code", value: `${invoice?.pincode || "NA"}` },
              { lable: "city", value: invoice?.city || "NA" },
              { lable: "state", value: invoice?.state || "NA" },
              { lable: "country", value: invoice?.country || "NA" }
            );
          }
        );
        return prev;
      });
      setItemsDetail((prev) => {
        prev = [];
        state.invoicesList.invoices[0].items.map((invoice, invoiceIndex) => {
          prev.push({
            sNo: invoiceIndex,
            item: invoice?.item,
            description: invoice?.description || "NA",
            quantity: invoice?.quantity,
            unit: invoice?.unit || "NA",
            rate: invoice?.rate
              ? `${state.invoicesList.invoices[0].currency} ${invoice?.rate}`
              : "NA",
            totalAmount: invoice?.grossAmount
              ? `${state.invoicesList.invoices[0].currency} ${invoice?.grossAmount} `
              : "NA",
            key: invoice?.hsn_no || "NA",
          });
        });
        return prev;
      });
      setTotalDetail((prev) => {
        prev = [];
        state.invoicesList.invoices.map((invoice, invoiceIndex) => {
          prev.push(
            { lable: "Total Gross Amount", value: invoice.grossAmount || 0 },
            {lable:"Total Discount",value:invoice.discountAmount||0},
            { lable: "Total Freight", value:invoice?.freightAmount || 0},
            {
              lable: "Total Taxable Amount",
              value: invoice?.taxableAmount||0,
            },
            { lable: "Total SGST", value: invoice?.sgstAmount ||0 },
            { lable: "Total CGST", value: invoice?.cgstAmount ||0},
            { lable: "Total IGST", value: invoice?.igstAmount || 0 },
            { lable: "Roundoff", value: invoice?.roundoff || 0 },

            { lable: "Grand Total", value: invoice?.totalAmount ||0 },

          );
        });
        return prev;
      });
      console.log(state.invoicesList.invoices[0].items);
    }
  }, [state]);
  return state.invoicesList.loading ? (
    <div
      className="ap-flex ap-w100 ap-flex-center-center"
      style={{ height: "300px" }}
    >
      <Spin tip="Fetching Records....." />
    </div>
  ) : (
    <div>
      <div className="ap-flex ap-flex-center-center ap-w100">
        <Card
          size="small"
          style={{ width: "100%" }}
          title={
            <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
              <div className="ap-flex">
                <span>Invoice Details</span>
              </div>
              <span
                className="ap-fesm ap-fwmd"
                style={{
                  border: "2px solid",
                  color: "green",
                  cursor: "pointer",
                  padding: "5px",
                }}
                onClick={() => history.goBack()}
              >
                {"<< go back"}
              </span>
            </div>
          }
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Customer Details */}

            <Card size="small" style={{ width: "49%" }}>
              <DetailHeading>Customer Details</DetailHeading>
              <DetailContent>
                {customerDetail &&
                  customerDetail.map((data, adminIndex) => (
                    <DetailItem key={adminIndex}>
                      <DLable>{data?.lable}:</DLable>
                      {data?.linkId ? (
                        <DValue
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "gray",
                          }}
                          onClick={() => {
                            history.push(
                              "/home/ap/client/details/" + data?.linkId
                            );
                          }}
                        >
                          {data?.value}
                        </DValue>
                      ) : (
                        <DValue>{data?.value}</DValue>
                      )}
                    </DetailItem>
                  ))}
              </DetailContent>
            </Card>

            {/* Invoice Details */}

            <Card size="small" style={{ width: "49%" }}>
              <DetailHeading>Invoice Details</DetailHeading>
              <DetailContent>
                {invoiceDetail &&
                  invoiceDetail.map((data, adminIndex) => (
                    <DetailItem key={adminIndex}>
                      <DLable>{data?.lable}:</DLable>{" "}
                      {data?.lable == "Status" ? (
                        <DValue
                          style={{
                            background: `${
                              data?.value == "confirmed" ? "#00b700" : "#40a9ff"
                            }`,
                            color: "#fff",
                            padding: "2px 4px",
                          }}
                        >
                          {data?.value}
                        </DValue>
                      ) : (
                        <DValue>{data?.value}</DValue>
                      )}
                    </DetailItem>
                  ))}
              </DetailContent>
            </Card>
          </div>
          <br />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* billing Address */}

            <Card size="small" style={{ width: "49%" }}>
              <DetailHeading>Billing Address</DetailHeading>
              <DetailContent>
                {billingAddress &&
                  billingAddress.map((data, adminIndex) => (
                    <DetailItem key={adminIndex}>
                      <DLable>{data?.lable}:</DLable>{" "}
                      <DValue>{data?.value}</DValue>
                    </DetailItem>
                  ))}
              </DetailContent>
            </Card>

            {/*Shipping Address Details */}

            <Card size="small" style={{ width: "49%" }}>
              <DetailHeading>Shipping Address</DetailHeading>
              <DetailContent>
                {shippingAddress &&
                  shippingAddress.map((data, adminIndex) => (
                    <DetailItem key={adminIndex}>
                      <DLable>{data?.lable}:</DLable>{" "}
                      <DValue>{data?.value}</DValue>
                    </DetailItem>
                  ))}
              </DetailContent>
            </Card>
          </div>
          <br />
          {/* Items Detail */}
          <DetailHeading>Items Detail</DetailHeading>
          <Card>
            <Table
              columns={columns}
              dataSource={itemsDetail}
              scroll={{ x: 900 }}
              pagination={{
                current: 1,
                pageSize: 20,
                position: ["bottomLeft"],
                total: itemsDetail?.length,
                hideOnSinglePage: true,
              }}
            />
            <div className="item-total">
              <Card size="small">
                {/* <DetailHeading>Total Details</DetailHeading> */}
                <DetailContent>
                  {totalDetail &&
                    totalDetail.map((data, adminIndex) =>
                      data?.lable == "Grand Total" ? (
                        <DetailItem
                          style={{ background: "#e5e5e5" }}
                          key={adminIndex}
                        >
                          <DLable>{data?.lable}:</DLable>{" "}
                          <DValue>{data?.value}</DValue>
                        </DetailItem>
                      ) : (
                        <DetailItem key={adminIndex}>
                          <DLable>{data?.lable}:</DLable>{" "}
                          <DValue>{data?.value}</DValue>
                        </DetailItem>
                      )
                    )}
                </DetailContent>
              </Card>
            </div>
          </Card>
          <br />
          <Card size="small">
            <ActionButtons>
              {state.invoicesList?.invoices[0]?.status == "draft" && (
                <div style={{ padding: "0 10px" }}>
                  <TButton
                    onClick={() => {
                      history.push("/home/ap/invoice/update-details/" + id);
                      // onOpen();
                      // setModalViewType("MoveCard");
                    }}
                  >
                    <Icons.Edit /> Edit
                  </TButton>
                </div>
              )}
              {/* {state.invoicesList?.invoices[0]?.status == "confirmed" && ( */}
              <div style={{ padding: "0 10px" }}>
                <TButton
                  onClick={() => {
                    getUrl(
                      `${baseURL}/internal/storage/invoices/Invoice-${id}.pdf?type=url`,
                      id
                    );
                  }}
                >
                  {urlOpenLoader && urlOpenId == id ? (
                    <span style={{ padding: "0 1rem" }}>
                      <Spin />
                    </span>
                  ) : (
                    <span>
                      <Icons.Download />
                      Download
                    </span>
                  )}{" "}
                </TButton>
              </div>
              {/* )} */}
              {state.invoicesList?.invoices[0]?.status == "confirmed" && (
                <div style={{ padding: "0 10px" }}>
                  <TButton onClick={ResendInvoice}>
                    <Icons.Download /> Resend Invoice
                  </TButton>
                </div>
              )}
              {state.invoicesList?.invoices[0]?.status == "draft" && (
                <div style={{ padding: "0 10px" }}>
                  <TButton
                    disabled={
                      isSendBilingDisable ||
                      state.invoicesList?.invoices[0]?.status !== "draft"
                    }
                    onClick={SendBilingLink}
                  >
                    <span style={{ display: "flex", padding: "2px 0" }}>
                      {isSendBilingDisable ? (
                        <img
                          src={Icons.Loading}
                          alt=""
                          style={{ width: "20px" }}
                        />
                      ) : (
                        <>
                          <Icons.Send />{" "}
                        </>
                      )}
                      Send Billing Reminder
                    </span>{" "}
                  </TButton>
                </div>
              )}
              {state.invoicesList?.invoices[0]?.billingAddress.length == 0 ? (
                <TButton
                  onClick={() => {
                    setClientID(state.invoicesList.invoices[0]?.customerId);
                    onBillOpen();
                  }}
                >
                  <span style={{ display: "flex" }}>
                    <Icons.Add /> Add Billing Info
                  </span>
                </TButton>
              ) : (
                <>
                  {state.invoicesList?.invoices[0]?.status == "draft" && (
                    <div style={{ padding: "0 10px" }}>
                      <TButton onClick={onConfirmOpen}>
                        <span style={{ display: "flex" }}>
                          {isConfirmDisable ? (
                            <img
                              src={Icons.Loading}
                              alt=""
                              style={{ width: "20px" }}
                            />
                          ) : (
                            <Icons.Check />
                          )}{" "}
                          Confirm invoice
                        </span>{" "}
                      </TButton>
                    </div>
                  )}
                </>
              )}
            </ActionButtons>
          </Card>
        </Card>
      </div>
      {/* <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent minH="15rem" maxH="35rem">
          <ModalHeader
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            Update Invoice Details
            <div>
              <CloseButton onClick={onClose} />
            </div>
          </ModalHeader>
          <EditInvoiceDetailForm
            onClose={() => {
              onClose();
              invoicesList(`invoiceID=` + id);
            }}
            invoiceData={state.invoicesList.invoices[0]}
            // billingAddress = {billingAddress}
            // shippingAddress = {shippingAddress}
          />
        </ModalContent>
      </Modal> */}
      <Modal isOpen={isConfirmOpen} onClose={onConfirmClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent minH="6wrem" maxH="35rem">
          <ModalHeader
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            Are You Sure You Want to Confirm this Invoice ?
            <div>
              <CloseButton onClick={onConfirmClose} />
            </div>
          </ModalHeader>
          <ModalBody>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 12rem",
              }}
            >
              <Button
                mt={1}
                colorScheme="teal"
                isLoading={isConfirmDisable}
                // type="submit"
                onClick={() => {
                  // let data = :id}
                  setConfirmDisable(true);

                  API.invoiceUpdate(id)
                    .then((res) => {
                      console.log(res);
                      setConfirmDisable(false);
                      toast.success("Invoice Confermed!", {
                        position: "top-right",
                      });
                      onConfirmClose();
                    })
                    .catch((err) => {
                      console.log(err);
                      setConfirmDisable(false);
                      toast.error("Error! ", err, {
                        position: "top-right",
                      });
                      onConfirmClose();
                    });
                }}
              >
                Yes
              </Button>
              <Button
                mt={1}
                colorScheme="red"
                // type="submit"
                onClick={onConfirmClose}
              >
                No
              </Button>
            </span>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isBillOpen} onClose={onBillClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent minH="15rem" maxH="35rem">
          <ModalHeader
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            Add Billing Information
            <div>
              <CloseButton onClick={onBillClose} />
            </div>
          </ModalHeader>
          <EditBillingInfo
            onClose={() => {
              onBillClose();
              setTimeout(() => {
                invoicesList(`invoiceID=` + id);
              }, 1500);
            }}
            CustomerID={clientID}
            isbillingInfo={false}
            customerDetail={state.invoicesList.invoices[0]}
            // billingAddress = {billingAddress}
            // shippingAddress = {shippingAddress}
          />
        </ModalContent>
      </Modal>
    </div>
  );
}
