import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Select } from "antd";
import toast from "react-hot-toast";
import { Button, Input } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { API } from "../../Services";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";

export default function AddNewSubCategory(props) {
  const state = useSelector((state) => state);
  const { Option } = Select;
  const [selectedParentID, setSelectedParentID] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { projectAttachementSubCategories } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const AddSubCategory = (values) => {
    setLoading(true);
    let data = { sub_category: values.sub_category };
    var formData = new FormData();
    for (var i in data) {
      formData.append(i, data[i]);
    }
    API.addAttchementCategory(values.parentCategoryID, formData)
      .then((res) => {
        setLoading(false);
        props.onClose();
        projectAttachementSubCategories();
        toast.success("Sub Category Added Successfully ! ", {
          position: "top-right",
        });
      })
      .catch((err) => {
        toast.error("Oops! There is error", {
          position: "top-right",
        });
      });
  };
  return (
    <div>
      <Formik
        initialValues={{
          parentCategoryID: "",
          sub_category: "",
        }}
        onSubmit={(values) => {
          AddSubCategory(values);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Field name="name">
              {({ field, form }) => (
                <>
                  <FormControl>
                    <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                      Category
                    </div>

                    <Select
                      size="large"
                      showSearch
                      name="parentCategoryID"
                      value={selectedParentID}
                      labelInValue
                      placeholder="Select Category"
                      style={{ width: "100%" }}
                      loading={state.projectAttachementCategories.loading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(data) => {
                        setSelectedParentID(data.value);
                        setFieldValue("parentCategoryID", data.value);
                      }}
                    >
                      {state.projectAttachementCategories?.categories?.map(
                        (cat, adminIndex) => (
                          <Option value={cat.ID} key={adminIndex}>
                            {cat.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="text-input">
                      Enter Sub Category Name
                    </FormLabel>
                    <Input
                      type="text"
                      id="sub_category"
                      name="sub_category"
                      placeholder="Enter SubCategory"
                      onChange={(e) => {
                        setFieldValue("sub_category", e.target.value);
                      }}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                </>
              )}
            </Field>
            <Button
              mt={4}
              className="submit_right1"
              isLoading={loading}
              type="submit"
            >
              Add
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
