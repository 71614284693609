import { API } from "../../Services";

export const products2 = (query = "") => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PRODUCTS2",
    });
    API.products2(query)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(res)).data;

        data.map((product) => {
          product.value = product.id;
          product.label = `${product.name}`;
        });

        setTimeout(() => {
          dispatch({
            type: "RECEIVE_PRODUCTS2",
            payload: data,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: "ERROR_PRODUCTS2",
        });
      });
  };
};
