import { Spin, Button, Pagination, Empty } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import CatlogFilter from "./CatlogFilter";
import { API, baseURL, mmhBaseURL } from "../../Services";
import imagePlaceholder from "./../../Assets/Images/placeholder.png";

import {
  FilterDiv,
  ImageDiv,
  LHeading,
  ListDiv,
  ListFooter,
  MainContainerDiv,
  PImageDiv,
  PInfoDiv,
  PLargeImageDiv,
  PList,
  ProjectDiv,
  ProjectSlider,
  PSliderImageDiv,
} from "./projectCatlogStyle";
import { BaseUrl } from "../../chatapp/apis/Networks";
import Slider from "react-slick";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageZoom from "react-image-zoom";
import { MobileStepper, Paper, Typography, useTheme } from "@material-ui/core";
import {
  CenterFocusWeak,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Pageview,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Icons } from "../../Utils";
import toast from "react-hot-toast";
export default function ProjectCatlogList() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [dataList, SetDataList] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [searchData, setSearchData] = useState(false);
  const [formData, setFormData] = useState({});
  const [isProjectId, setProjectId] = useState(false);
  const [clearFilter, setFilterClear] = useState(0);
  const { projectsCatlog } = bindActionCreators(actionCreators, dispatch);
  const { isOpen, onOpen, onClose } = useDisclosure();
   const [isSyncLoad, setSyncLoad] = useState(false);
   const [isSyncId, setSyncId] = useState();
  const theme = useTheme();
  const [imageList, setImageList] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();

  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const directions = [
    { value: "EE", label: "East " },
    { value: "WW", label: "West" },
    { value: "NN", label: "North" },
    { value: "SS", label: "South" },
    { value: "NE", label: "North-East" },
    { value: "NW", label: "North-West" },
    { value: "SE", label: "South-East" },
    { value: "SW", label: "South-West" },
  ];

  var settings = {
    arrows: true,
    dots: true,
    infinite: true,
    // speed: 500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
 const SyncProject = (projectId) => {
   setSyncLoad(true);
   setSyncId(projectId);
   API.SyncRsformFromMaster(projectId)
     .then((res) => {
       setSyncId("");
       setSyncLoad(false);
       toast.success(res.data.message || "Project Synced Successfully!", {
         position: "top-right",
       });
       SetDataList((prevDataList) => {
         const updatedDataList = prevDataList.map((item) =>
           item.projectID === projectId ? { ...item, isSynced: true } : item
         );
         return updatedDataList;
       });
     })
     .catch((err) => {
       setSyncId("");

       setSyncLoad(false);
       toast.error("Oops! Failed to Add Member.", {
         position: "top-right",
       });
     });
 };
  useEffect(() => {
    if (state.projectsCatlog.projects !== null) {
      SetDataList(state.projectsCatlog.projects);
      if (state.projectsCatlog?.projects?.length > 0) {
        // state.masterProjects.projects.map((data, I) => {
        setSliderData((prev) => {
          prev = [];
          state.projectsCatlog.projects.map((d, Index) => {
            prev.push({
              index: 0,
              currentUrl: d?.images ? d?.images[0]?.url : "nodata",
            });
          });
          return prev;
        });
      }
    }
  }, [state]);

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      let querry = "";
      Object.entries(formData).map(([key, value], formDataIndex) => {
        querry = querry + `&${key}=${value}`;
        if (key == "projectID") {
          if (value) {
            params.set("projectID", value);
            history.push({ search: params.toString() });
          }
        } else {
          setProjectId(false);
        }
      });

      console.log("current---", current);
      let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10;

      if (clearFilter == 1) {
        if (params.get("projectID")) {
          params.delete("projectID");
          history.push({ search: params.toString() });
          window.location.reload();
        } else {
          projectsCatlog(`limit=10&offset=0&page=1`);
        }
        setFilterClear(0);
      } else {
        projectsCatlog(
          `limit=${current.size}&offset=${offset}${querry}&page=${current.page}`
        );
      }
    }
  }, [current, clearFilter]);
  useEffect(() => {
    if (params.get(`projectID`)) {
      projectsCatlog(
        `projectID=${params.get(`projectID`)}&limit=10&offset=0&page=1`
      );
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (
        state.projectsCatlog.loading == false &&
        Object.keys(formData).length == 0 &&
        !searchData &&
        !params.get(`projectID`)
      ) {
        if (state.projectsCatlog.projects == null) {
          console.log("inside");
          projectsCatlog(`limit=10&offset=0&page=1`);
        }
      }
    }, 1500);
  }, [state.projectsCatlog.loading]);
  const callRecords = (event) => {
    console.log(event);
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };
  return (
    <>
      {state?.projectsCatlog?.msg !== "" &&
      state?.projectsCatlog?.msg.includes("403") ? (
        <>
          <div
            style={{
              fontSize: "large",
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            <h3>Unauthorized</h3>
          </div>
        </>
      ) : (
        <MainContainerDiv>
          <>
            <FilterDiv>
              <CatlogFilter
                setFilterData={setFormData}
                setFilterClear={setFilterClear}
                setCurrent={setCurrent}
                setSearchData={setSearchData}
                isProjectId={isProjectId}
                clearFilter={clearFilter}
              />
            </FilterDiv>
            <ListDiv>
              <LHeading>2D Project Listing</LHeading>
              {state.projectsCatlog?.loading ? (
                <>
                  <div
                    className="ap-flex ap-w100 ap-flex-center-center"
                    style={{ height: "300px" }}
                  >
                    <Spin tip="Fetching Records....." />
                  </div>
                </>
              ) : (
                <>
                  {dataList.length > 0 ? (
                    <>
                      <PList>
                        {dataList.map((data, index) => {
                          //  let enlargeImage = `${BaseUrl}/internal/storage/1024/${data?.previewImage}` || imagePlaceholder;

                          let enlargeImage = data?.previewImage
                            ? `${BaseUrl}/public/media/rimage/1024/${data?.previewImage}`
                            : imagePlaceholder;
                          //  let enlargeImage = `https://api.makemyhouse.com/public/media/rimage/1024/orders/SR206659/preview.jpg`;

                          let MyCollectionKey = index;
                          let MyCollection = data?.images;
                          let CureentSlideData = sliderData[index];
                          let ActiveStep =
                            sliderData !== "" ? sliderData[index]?.index : "";
                          const goToNextPicture = () => {
                            ActiveStep = ActiveStep + 1;

                            console.log(
                              ActiveStep,
                              "---",
                              MyCollection[ActiveStep]?.url
                            );
                            setSliderData((prev) => {
                              prev = JSON.parse(JSON.stringify(prev));
                              prev[MyCollectionKey] = {
                                index: ActiveStep,
                                currentUrl: MyCollection[ActiveStep]?.url,
                              };
                              return prev;
                            });
                          };
                          const goToPrevPicture = () => {
                            ActiveStep = ActiveStep - 1;
                            setSliderData((prev) => {
                              prev = JSON.parse(JSON.stringify(prev));
                              prev[MyCollectionKey] = {
                                index: ActiveStep,
                                currentUrl: MyCollection[ActiveStep]?.url,
                              };
                              return prev;
                            });
                          };
                          return (
                            <ProjectDiv key={index}>
                              {data?.images?.length > 0 ? (
                                <ProjectSlider
                                  style={{
                                    maxWidth: 300,
                                    // maxHeight:250,
                                  }}
                                >
                                  <PImageDiv>
                                    <ReactImageZoom
                                      img={CureentSlideData?.currentUrl}
                                      scale={1.8}
                                      zoomPosition={"left"}
                                      // width={300}
                                      // zoomWidth={400}
                                      offset={{
                                        vertical: 0,
                                        horizontal: 50,
                                      }}
                                    />
                                  </PImageDiv>
                                  <MobileStepper
                                    variant="text"
                                    position="static"
                                    activeStep={CureentSlideData?.index}
                                    index={CureentSlideData?.index}
                                    steps={MyCollection.length}
                                    backButton={
                                      <Button
                                        size="small"
                                        onClick={goToPrevPicture}
                                        disabled={CureentSlideData.index == 0}
                                      >
                                        <KeyboardArrowLeft />
                                      </Button>
                                    }
                                    nextButton={
                                      <Button
                                        size="small"
                                        onClick={goToNextPicture}
                                        disabled={
                                          CureentSlideData.index ===
                                          MyCollection.length - 1
                                        }
                                      >
                                        <KeyboardArrowRight />
                                      </Button>
                                    }
                                  />
                                  <Paper
                                    square
                                    elevation={0}
                                    style={{
                                      height: 40,
                                      display: "flex",
                                      justifyContent: "center",
                                      // paddingLeft: theme.spacing(4),
                                      backgroundColor:
                                        theme.palette.background.default,
                                      alignItems: "center",
                                    }}
                                  >
                                    <CenterFocusWeak
                                      style={{
                                        fontSize: "2em",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setImageList(data?.images);
                                        onOpen();
                                      }}
                                    />
                                  </Paper>
                                </ProjectSlider>
                              ) : (
                                <PImageDiv>
                                  <ReactImageZoom
                                    img={enlargeImage}
                                    scale={2}
                                    zoomPosition={"left"}
                                    zoomWidth={500}
                                    offset={{ vertical: 0, horizontal: 50 }}
                                  />
                                </PImageDiv>
                              )}

                              {/* </PImageDiv> */}

                              <PInfoDiv>
                                <span className="p-name">
                                  {data?.projectName}
                                </span>
                                <span className="p-id">
                                  Project id: {data?.projectID}
                                </span>
                                <span className="p-basic-details">
                                  <span
                                    style={{ width: "100%", display: "flex" }}
                                  >
                                    <span style={{ width: "60%" }}>
                                      <span>Width:</span>{" "}
                                      {data?.width ? data?.width + "FT" : "-"}{" "}
                                    </span>
                                    <span style={{ width: "40%" }}>
                                      <span>Depth:</span>{" "}
                                      {data?.depth ? data?.depth + "FT" : "-"}
                                    </span>
                                  </span>
                                  <span
                                    style={{ width: "100%", display: "flex" }}
                                  >
                                    <span style={{ width: "60%" }}>
                                      <span>PlotArea:</span>{" "}
                                      {data?.builtUpArea
                                        ? data?.builtUpArea + "SqFT"
                                        : "-"}{" "}
                                    </span>
                                    <span style={{ width: "40%" }}>
                                      <span>Floor:</span> {data?.floor || "-"}{" "}
                                    </span>
                                  </span>
                                  {/* <span style={{width:"100%",display:"flex"}}> */}
                                  <span>
                                    <span>Direction:</span>
                                    {data?.direction
                                      ? directions.map((d, index) => {
                                          let direction;
                                          if (data?.direction == d.value) {
                                            direction = d?.label;
                                          }
                                          return direction;
                                        })
                                      : "-"}
                                  </span>
                                  <span>
                                    <span>Stair Case Type:</span>{" "}
                                    {data?.staircaseType
                                      ? data?.staircaseType == 1
                                        ? "Internal"
                                        : "External"
                                      : "-"}
                                  </span>
                                </span>
                                {/* </span> */}
                                <span className="p-buttons">
                                  <Button
                                    type="primary"
                                    className="ap-btn-c"
                                    size="middle"
                                    disabled={!data?.folderLink}
                                    onClick={() => {
                                      window.open(data?.folderLink, "_blank");
                                    }}
                                  >
                                    <span className="ap-fesm ap-fwmd">
                                      Folder Link{" "}
                                    </span>
                                  </Button>
                                  <Button
                                    type="primary"
                                    className="ap-btn-c"
                                    size="middle"
                                    disabled={!data?.trelloLink}
                                    onClick={() => {
                                      window.open(data?.trelloLink, "_blank");
                                    }}
                                  >
                                    <span className="ap-fesm ap-fwmd">
                                      Trello Link{" "}
                                    </span>
                                  </Button>
                                  {/* {record?.status !== "submitted" ? ( */}
                                  {data.isSynced ? (
                                    <a
                                      href={`${mmhBaseURL}rs-form/${
                                        data.projectID
                                      }?updatedby=admin&token=${localStorage.getItem(
                                        "token"
                                      )}`}
                                      target="_blank"
                                    >
                                      <Button
                                        type="primary"
                                        className="ap-btn-c"
                                        size="middle"
                                      >
                                        <span style={{ fontSize: "15px" }}>
                                          <Icons.Edit />
                                        </span>
                                      </Button>
                                    </a>
                                  ) : (
                                    <Button
                                      type="primary"
                                      className="ap-btn-c"
                                      size="middle"
                                      style={{
                                        padding: "5px",
                                      }}
                                      disabled={isSyncLoad}
                                      loading={
                                        isSyncLoad && data.projectID == isSyncId
                                      }
                                      onClick={() => {
                                        SyncProject(data.projectID);
                                      }}
                                    >
                                      <Icons.SyncIcon
                                        style={{ paddingBottom: "5px" }}
                                      />
                                    </Button>
                                  )}
                                </span>
                              </PInfoDiv>
                            </ProjectDiv>
                          );
                        })}
                      </PList>
                      <ListFooter>
                        <Pagination
                          defaultCurrent={current.page}
                          defaultPageSize={10}
                          total={state?.projectsCatlog?.totalRecords}
                          onChange={(data, size) => {
                            callRecords({
                              type: "page",
                              payload: { page: data, size: size },
                            });
                          }}
                          pageSize={current.size}
                          showTotal={(total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`
                          }
                        />
                      </ListFooter>{" "}
                    </>
                  ) : (
                    <div style={{ margin: "20% 0" }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </>
              )}
            </ListDiv>
          </>

          <Modal isOpen={isOpen} onClose={onClose} size="7xl">
            <ModalOverlay />
            {/* <ModalHeader>Hover the Image to Zoom</ModalHeader> */}

            <ModalContent>
              <ModalCloseButton />

              <ModalBody mt={4}>
                <div
                  style={{
                    height: "36rem",
                    padding: "20px 15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PSliderImageDiv>
                    <Slider {...settings}>
                      {imageList?.map((item) => (
                        <span className="outer-img-div">
                          <img
                            src={item?.url}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = imagePlaceholder;
                            }}
                            alt=""
                          />
                        </span>
                      ))}
                    </Slider>
                  </PSliderImageDiv>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
        </MainContainerDiv>
      )}
    </>
  );
}
