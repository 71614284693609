const projectFolderpathReducer = (state = {
    loading:false,
    project:null
}, action) => {
    switch (action.type) {
        case "FETCH_PROJECTS_DETAIL":
            return { ...state ,loading:true,project:null}
        case "RECEIVE_PROJECTS_DETAIL":
               return {...state, project:{...action.payload},loading:false,}
        case "ERROR_PROJECTS_DETAIL":
            return { ...state ,project:null,loading:true}
            default:
                return { ...state }
    }
}

export default projectFolderpathReducer;