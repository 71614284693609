import { axiosInstance } from ".."

const products2 = async (query='') =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`internal/productsv2/?custom=1&${query}`).then((res) => { 
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default products2;