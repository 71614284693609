const trelloListReducer = (state = {
    loading:false,
    list:null
}, action) => {
    switch (action.type) {
        case "FETCH_TRELLO_LIST":
            return { ...state ,loading:true,list:null}
        case "RECEIVE_TRELLO_LIST":
            return {...state, list:{...action.payload},loading:false}
        case "ERROR_TRELLO_LIST":
            return { ...state ,list:null,loading:false}
        default:
            return { ...state }
    }
}

export default trelloListReducer;