import React, { useEffect } from "react";
import {
  ModalHeader,
  ModalBody,
  CloseButton,
  Button,
  Checkbox,
  IconButton,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import { Select, Input, Card, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { AddressDiv, FormHeading } from "./invoiceStyle";
import PhoneInput from "react-phone-input-international";
import "react-phone-input-international/lib/style.css";
import { Icons } from "../../../Utils";
import { toast } from "react-hot-toast";
import { API } from "../../../Services";
import { useParams,useHistory } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import SplitPopUp from "./SplitPopUp";

export default function EditInvoiceDetailForm() {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { TextArea } = Input;
  const [isloading, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { states } = bindActionCreators(actionCreators, dispatch);
  const [numOfSplits, setNumOfSplits] = useState(0);
  const state = useSelector((state) => state);
  const { Option } = Select;
  const { invoicesList, customer } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const history = useHistory();

  useEffect(() => {
    invoicesList(`invoiceID=` + id);
  }, [id])
  

  const [tdiscount, setTotalDiscount] = useState(
    state.invoicesList.invoices[0]?.discount || 0
  );
  const [tfreight, setTotalFreight] = useState(
    state.invoicesList.invoices[0]?.freight || 0
  );
  const [tgrossAmount, setTotalGrossAmount] = useState(
    state.invoicesList.invoices[0]?.grossAmount || 0
  );
  const [tTaxableAmount, setTotalTaxableAmount] = useState(
    state.invoicesList.invoices[0]?.taxableAmount || 0
  );
  const [tsgstAmount, setTotalSGST] = useState(
    state.invoicesList.invoices[0]?.sgstAmount || 0
  );
  const [tcgstAmount, setTotalCGST] = useState(
    state.invoicesList.invoices[0]?.cgstAmount || 0
  );
  const [tigstAmount, setTotalIGST] = useState(
    state.invoicesList.invoices[0]?.igstAmount || 0
  );
  const [totalAmount, setTotalAmount] = useState(
    state.invoicesList.invoices[0]?.totalAmount || 0
  );
  const [roundoff, setRoundOff] = useState(state.invoicesList.invoices[0]?.roundoff || 0);


  const [items, setItems] = useState([]);

  useEffect(() => {
    if (state.invoicesList.invoices[0]) {
      if (state.invoicesList.invoices[0]?.items && Array.isArray(state.invoicesList.invoices[0].items)) {
        setItems(state.invoicesList.invoices[0].items.map((item) => ({
          itemName: item.item || 0,
          description: item.description || 0,
          quantity: item.quantity || 0,
          unit: item.unit || 0,
          rate: item.rate || 0,
          hsn_no: item.hsn_no || 0,
          grossAmount: item.grossAmount || 0,
          discount: item.discount || 0,
          freight: item.freight || 0,
          taxableAmount: item.taxableAmount || 0,
          cgstAmount: item.cgstAmount || 0,
          sgstAmount: item.sgstAmount || 0,
          igstAmount: item.igstAmount || 0,
        })));
      }
      
    }
  }, [state.invoicesList.invoices[0]]);

  const addItem = () => {
    setItems([...items, {}]);
  };
  const calculateTotals = () => {
    let totalTaxableAmount = 0;
    let totalSGST = 0;
    let totalCGST = 0;
    let totalIGST = 0;
    let totalGrossAmount = 0;
    let totalFreight = 0;
    let totalDiscount = 0;

    items.forEach((item) => {
   
        totalTaxableAmount += parseFloat(item.taxableAmount || 0);
        totalSGST += parseFloat(item.sgstAmount || 0);
        totalCGST += parseFloat(item.cgstAmount || 0);
        totalIGST += parseFloat(item.igstAmount || 0);
        totalFreight += parseFloat(item.freight || 0);
        totalDiscount += parseFloat(item.discount || 0);
        totalGrossAmount += parseFloat(item.grossAmount || 0);
     
    });

    setTotalSGST(totalSGST.toFixed(2));
    setTotalCGST(totalCGST.toFixed(2));
    setTotalIGST(totalIGST.toFixed(2));
    setTotalGrossAmount(totalGrossAmount.toFixed(2));
    setTotalTaxableAmount(totalTaxableAmount.toFixed(2));
    setTotalFreight(totalFreight.toFixed(2));
    setTotalDiscount(totalDiscount.toFixed(2));
    // Calculate the Grand Total including roundoff
    const grandTotal =
      totalGrossAmount +
      totalSGST +
      totalCGST +
      totalIGST +
      totalFreight-
      totalDiscount+
      parseFloat(roundoff || 0);
    setTotalAmount(grandTotal.toFixed(2));
  };

 
  const deleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    calculateTotals();
  };
  const [splitItemIndex, setSplitItemIndex] = useState(-1);
  useEffect(() => {
    console.log("items", items);
  }, [items]);

  const handleSplitAmountEntered = (splitAmount) => {
    console.log("splitAmount", splitAmount);
    const totalSplitAmount = splitAmount?.reduce(
      (total, amount) => total + parseFloat(amount || 0),
      0
    );
    if (totalSplitAmount >= 0 && splitAmount?.length > 0) {
      const updatedItems = [...items];
      const mainItemRate = parseFloat(updatedItems[splitItemIndex].rate || 0);

      // Ensure the split amount is not more than the main item rate
      if (totalSplitAmount <= mainItemRate) {
        // const newRate = mainItemRate - splitAmount;
        const newRate = mainItemRate - totalSplitAmount;

        updatedItems[splitItemIndex].rate = newRate.toFixed(2);

        // Set the split item details same as the main item
        for (let i = 0; i < splitAmount?.length; i++) {
         
          updatedItems.push({
            itemName: updatedItems[splitItemIndex].itemName,
            description: updatedItems[splitItemIndex].description,
            hsn_no: updatedItems[splitItemIndex].hsn_no,
            unit: updatedItems[splitItemIndex].unit,
            quantity: 1,
            rate: splitAmount[i],
            grossAmount: splitAmount[i],
            discount: 0,
            freight: 0,
           taxableAmount: splitAmount[i],
           cgstAmount: 0,
            sgstAmount: 0,
           igstAmount: 0,
          });
        }
        // updatedItems[splitItemIndex].splitItems = splitItems;

        // Recalculate gross amount
        const quantity = parseFloat(updatedItems[splitItemIndex].quantity || 0);
        const rate = parseFloat(updatedItems[splitItemIndex].rate || 0);
        const grossAmount = quantity * rate;
        updatedItems[splitItemIndex].grossAmount = grossAmount.toFixed(2);

        // Recalculate taxable amount
        const discount = parseFloat(updatedItems[splitItemIndex].discount || 0);
        const freight = parseFloat(updatedItems[splitItemIndex].freight || 0);
        const taxableAmount = grossAmount - discount + freight;
        updatedItems[splitItemIndex].taxableAmount = taxableAmount.toFixed(2);
        // Set the split amount
        updatedItems[splitItemIndex].splitAmount = splitAmount;
        setItems(updatedItems);

        // setItems(updatedItems);
        calculateTotals();
      } else {
        // Handle error when split amount is more than the main item rate
        // You can display an error message or take appropriate action here
      }
    }
  };

  const handleInputChange = (index, event, splitIndex, isSplit) => {
    const { name, value } = event.target;
    const updatedItems = [...items];
    // console.log("calculated handleInputChange", index, name, value, splitIndex);

    // Update the specific item's property
    if (!isSplit) {
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      };
    } else {
      // console.log("calculated splitItems", name, value, splitIndex);

      updatedItems[index].splitItems[splitIndex] = {
        ...updatedItems[index].splitItems[splitIndex],
        [name]: value,
      };
      console.log("updated  splitItems", updatedItems);
    }
    // Recalculate gross amount
    const quantity = parseFloat(updatedItems[index].quantity || 0);
    const rate = parseFloat(updatedItems[index].rate || 0);
    const grossAmount = quantity * rate;
    updatedItems[index].grossAmount = grossAmount.toFixed(2);

    // Recalculate taxable amount
    const discount = parseFloat(updatedItems[index].discount || 0);
    const freight = parseFloat(updatedItems[index].freight || 0);
    const taxableAmount = grossAmount - discount + freight;
    updatedItems[index].taxableAmount = taxableAmount.toFixed(2);

    // Check if the item has a split amount
  
      // Calculate tax amounts
      const sgstRate = parseFloat(updatedItems[index].sgstAmount || 0);
      const cgstRate = parseFloat(updatedItems[index].cgstAmount || 0);
      const igstRate = parseFloat(updatedItems[index].igstAmount || 0);
      // const sgstAmount = (taxableAmount * (sgstRate / 100)).toFixed(2);
      // const cgstAmount = (taxableAmount * (cgstRate / 100)).toFixed(2);
      // const igstAmount = (taxableAmount * (igstRate / 100)).toFixed(2);
      updatedItems[index].sgstAmount = sgstRate;
      updatedItems[index].cgstAmount = cgstRate;
      updatedItems[index].igstAmount = igstRate;
    

    // Update the state with the modified items
    setItems(updatedItems);
    calculateTotals();
  };

 
  useEffect(() => {
    if (items || roundoff) {
      calculateTotals();
    }
  }, [items, roundoff]);


  const updateInvoice = (value) => {
    setLoader(true);
    
    let data = {
      cgstAmount:tcgstAmount || 0,
    grossAmount:tgrossAmount || 0,
      igstAmount:tigstAmount || 0,
      roundoff: roundoff || 0,
      sgstAmount:tsgstAmount || 0,
      taxableAmount: tTaxableAmount || 0,
      totalAmount:totalAmount || 0,
      remarks:value.remarks,
      freight:tfreight,
      discount:tdiscount,
      items:items}
    console.log(" data ",data)
    API.updateInvoiceDetail(id, data)
      .then((res) => {
        // setLoad((prev)=>!prev)
        console.log(res);
        toast.success(
          res.data.message || "Updated Invoice Information successfully.",
          {
            position: "top-right",
          }
        );
        setLoader(false);
        history.push("/home/ap/invoice/details/"+id)
        // props.onClose();
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        toast.error("Oops! Failed to Updated Invoice Information.", {
          position: "top-right",
        });
        console.log("Oops! Failed to Updated Invoice Information.");
        setLoader(false);
        // props.onClose();
      });
  };
  return  state.invoicesList.loading ? (
    <div
    className="ap-flex ap-w100 ap-flex-center-center"
    style={{ height: "300px" }}
  >
    <Spin tip="Fetching Records....." />
  </div>
) : (
    <div>
   
      <Card  size="small"
    style={{ width: "100%" }}
    title={
      <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
      <div className="ap-flex">
        <span> Update Invoice Details</span>
      </div>
      <span
        className="ap-fesm ap-fwmd"
        style={{
          border: "2px solid",
          color: "green",
          cursor: "pointer",
          padding: "5px",
        }}
        onClick={() => history.goBack()}
      >
        {"<< go back"}
      </span>
    </div>
  }>
        {/* <ModalBody> */}
          <span style={{fontWeight:"bold",color:"gray"}}>
          Invoice ID : {state.invoicesList.invoices[0]?.invoiceNo||'NA'}
          </span>
          <br />
          <Formik
            initialValues={{
              cgstAmount: state.invoicesList.invoices[0]?.cgstAmount || 0,
              discountAmount: state.invoicesList.invoices[0]?.discountAmount || 0,
              freightAmount: state.invoicesList.invoices[0]?.freightAmount || 0,
              grossAmount: state.invoicesList.invoices[0]?.grossAmount || 0,
              igstAmount: state.invoicesList.invoices[0]?.igstAmount || 0,
              roundoff: state.invoicesList.invoices[0]?.roundoff || 0,
              sgstAmount: state.invoicesList.invoices[0]?.sgstAmount || 0,
              taxableAmount: state.invoicesList.invoices[0]?.taxableAmount || 0,
              totalAmount: state.invoicesList.invoices[0]?.totalAmount || 0,
              entity: state.invoicesList.invoices[0]?.entity || 0,
              creationDate:
                moment
                  .unix(state.invoicesList.invoices[0]?.creationDate)
                  .format("YYYY-MM-DDTHH:mm") || "",
              referenceType: state.invoicesList.invoices[0]?.referenceType || "",
              referenceId: state.invoicesList.invoices[0]?.referenceId || "",
              currency: state.invoicesList.invoices[0]?.currency || "",
              invoiceType: state.invoicesList.invoices[0]?.invoiceType || "",
              remarks: state.invoicesList.invoices[0]?.remarks || "",
              first_name: state.invoicesList.invoices[0]?.firstName || "",
              last_name: state.invoicesList.invoices[0]?.lastName || "",
              business_name: state.invoicesList.invoices[0]?.businessName || "",
              business_phone_number:
                state.invoicesList.invoices[0]?.businessPhoneNumber || "",
              pan_number: state.invoicesList.invoices[0]?.panNo || "",
              is_gst: state.invoicesList.invoices[0]?.isGst || false,
              gst_no: state.invoicesList.invoices[0]?.gstNo || "",
              baddress: state.invoicesList.invoices[0]?.billingAddress?.addressLine_1 || "",
              baddress2:
                state.invoicesList.invoices[0]?.billingAddress?.addressLine_2 || "",
              bcountry: state.invoicesList.invoices[0]?.billingAddress?.country || "",
              bstate: state.invoicesList.invoices[0]?.billingAddress?.state || "",
              bcity: state.invoicesList.invoices[0]?.billingAddress?.city || "",
              bzip: state.invoicesList.invoices[0]?.billingAddress?.pincode || "",
              is_same_as_billing: state.invoicesList.invoices[0]?.isSameAddress || false,
              saddress:
                state.invoicesList.invoices[0]?.shippingAddress?.addressLine_1 || "",
              saddress2:
                state.invoicesList.invoices[0]?.shippingAddress?.addressLine_2 || "",
              scountry: state.invoicesList.invoices[0]?.shippingAddress?.country || "",
              sstate: state.invoicesList.invoices[0]?.shippingAddress?.state || "",
              scity: state.invoicesList.invoices[0]?.shippingAddress?.city || "",
              szip: state.invoicesList.invoices[0]?.shippingAddress?.pincode || "",
              customerID: state.invoicesList.invoices[0]?.customerId,
              spnumber:
                state.invoicesList.invoices[0]?.shippingAddress
                  ?.isd_shipping_phone_number || "",
            }}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log("values.....", values);
              updateInvoice(values);
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div style={{ overFlow: "scroll" }}>
                  <AddressDiv>
                    <FormHeading>Basic Information</FormHeading>

                    <div className="bill-row">
                      <Field name="first_name">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">Customer</FormLabel>
                            <Input
                              {...field}
                              id="first_name"
                              type="text"
                              name="first_name"
                              placeholder="Enter Customer Name..."
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("first_name", e.target.value);
                              }}
                              disabled={true}
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="entity">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">Entity</FormLabel>
                            <Input
                              type="text"
                              {...field}
                              id="entity"
                              name="entity"
                              placeholder="Enter Entity..."
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("entity", e.target.value);
                              }}
                              disabled={true}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <div className="bill-row">
                      <Field name="creationDate">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">
                              Invoice Date
                            </FormLabel>
                            <Input
                              {...field}
                              id="creationDate"
                              name="creationDate"
                              placeholder="Select Date and Time"
                              size="md"
                              type="datetime-local"
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("creationDate", e.target.value);
                              }}
                              disabled={true}
                            />

                            <FormErrorMessage>
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="invoiceType">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">
                              Invoice Type
                            </FormLabel>
                            <Input
                              type="text"
                              id="invoiceType"
                              name="invoiceType"
                              {...field}
                              placeholder="Enter Invoice Type..."
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("invoice_type", e.target.value);
                              }}
                              disabled={true}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <div className="bill-row">
                      <Field name="referenceType">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">
                              Reference Type
                            </FormLabel>
                            <Input
                              type="text"
                              id="referenceType"
                              name="referenceType"
                              {...field}
                              placeholder="Enter reference Type..."
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("referenceType", e.target.value);
                              }}
                              disabled={true}
                            />

                            <FormErrorMessage>
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="referenceId">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">
                              Reference Id
                            </FormLabel>
                            <Input
                              type="text"
                              id="referenceId"
                              name="referenceId"
                              {...field}
                              placeholder="Enter reference Id..."
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("referenceId", e.target.value);
                              }}
                              disabled={true}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <Field name="remarks">
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel htmlFor="text-Input"> Remarks</FormLabel>

                          <Textarea
                            {...field}
                            type="text"
                            id="remarks"
                            name="remarks"
                            placeholder="Add Remarks..."
                            onChange={(e) => {
                              console.log(e.target.value);
                              setFieldValue("remarks", e.target.value);
                            }}
                            size="sm"

                            // defaultValue={
                            //   formData[0]?.billingAddress?.baddress2
                            // }
                          />
                        </FormControl>
                      )}
                    </Field>

                    <div>
                      <br />
                      <Field name="is_gst">
                        {({ field, form }) => (
                          <FormControl>
                            <Checkbox
                              {...field}
                              size="lg"
                              colorScheme="green"
                              defaultChecked={
                                state.invoicesList.invoices[0]?.isGst || false
                              }
                              // isChecked={}
                              mr={2}
                              mb={2}
                              onChange={(e) => {
                                setFieldValue("is_gst", e.target.checked);
                                console.log("checked", e.target.checked);
                              }}
                              disabled={true}
                            >
                              Is GST required?
                            </Checkbox>
                          </FormControl>
                        )}
                      </Field>
                      {values.is_gst ? (
                        <>
                          <div className="bill-row">
                            <Field name="business_name">
                              {({ field, form }) => (
                                <FormControl
                                  isRequired
                                  className="primary-info"
                                >
                                  <FormLabel htmlFor="text-Input">
                                    Business Name
                                  </FormLabel>

                                  <Input
                                    disabled={true}
                                    {...field}
                                    type="text"
                                    id="business_name"
                                    name="business_name"
                                    placeholder="pin code"
                                    onChange={(e) => {
                                      // console.log(e.target.value);
                                      setFieldValue(
                                        "business_name",
                                        e.target.value
                                      );
                                    }}
                                    // defaultValue={
                                    //   formData[0]?.billingAddress?.business_name
                                    // }
                                  />
                                </FormControl>
                              )}
                            </Field>
                            <Field name="business_phone_number">
                              {({ field, form }) => (
                                <FormControl
                                  {...field}
                                  isRequired
                                  className="primary-info"
                                >
                                  <FormLabel htmlFor="text-Input">
                                    Business Phone Number
                                  </FormLabel>
                                  <PhoneInput
                                    disabled={true}
                                    {...field}
                                    id="business_phone_number"
                                    name="business_phone_number"
                                    placeholder="Enter Phone Number..."
                                    enableSearch={true}
                                    disableSearchIcon={true}
                                    country={"in"}
                                    onChange={(value) => {
                                      console.log("Phone number:", `+${value}`);
                                      setFieldValue(
                                        "business_phone_number",
                                        `+${value}`
                                      );
                                    }}
                                  />
                                </FormControl>
                              )}
                            </Field>
                          </div>
                          <div className="bill-row">
                            <Field name="gst_no">
                              {({ field, form }) => (
                                <FormControl
                                  isRequired
                                  className="primary-info"
                                >
                                  <FormLabel htmlFor="text-Input">
                                    gst_no
                                  </FormLabel>

                                  <Input
                                    disabled={true}
                                    {...field}
                                    type="text"
                                    id="gst_no"
                                    name="gst_no"
                                    placeholder="Enter GST No..."
                                    onChange={(e) => {
                                      setFieldValue("gst_no", e.target.value);

                                      console.log(e.target.value);
                                    }}
                                  />
                                </FormControl>
                              )}
                            </Field>
                            <Field name="pan_number">
                              {({ field, form }) => (
                                <FormControl
                                  isRequired
                                  className="primary-info"
                                >
                                  <FormLabel htmlFor="text-Input">
                                    PAN Number
                                  </FormLabel>

                                  <Input
                                    disabled={true}
                                    {...field}
                                    type="text"
                                    id="pan_number"
                                    name="pan_number"
                                    placeholder="Enter Pan Number..."
                                    onChange={(e) => {
                                      // console.log(e.target.value);
                                      setFieldValue(
                                        "pan_number",
                                        e.target.value
                                      );
                                    }}
                                    // defaultValue={
                                    //   formData[0]?.shippingAddress?.pan_number
                                    // }
                                  />
                                  {/* <FormErrorMessage>
                                  {form.errors.name}
                                </FormErrorMessage> */}
                                </FormControl>
                              )}
                            </Field>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />

                    <FormHeading>Billing Information</FormHeading>

                    <div className="address">
                      <Field name="baddress">
                        {({ field, form }) => (
                          <FormControl isRequired>
                            <FormLabel htmlFor="text-Input"> Address</FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="baddress"
                              name="baddress"
                              placeholder="Address Line 1"
                              onChange={(e) => {
                                // console.log(e.target.value);
                                setFieldValue("baddress", e.target.value);
                              }}
                              disabled={true}
                              // defaultValue={
                              //   formData[0]?.billingAddress?.baddress
                              // }
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="baddress2">
                        {({ field, form }) => (
                          <FormControl>
                            <FormLabel htmlFor="text-Input">
                              {" "}
                              Address 2
                            </FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="baddress2"
                              name="baddress2"
                              placeholder="Address Line 2"
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue("baddress2", e.target.value);
                              }}
                              disabled={true}
                              // defaultValue={
                              //   formData[0]?.billingAddress?.baddress2
                              // }
                            />
                          </FormControl>
                        )}
                      </Field>
                    </div>

                    <div className="bill-row">
                      <Field name="bcountry">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">Country</FormLabel>

                            <Select
                              {...field}
                              showSearch
                              size="middle"
                              // value={formData[0]?.billingAddress?.bcountry}
                              labelInValue
                              placeholder="Select Country"
                              onChange={(data) => {
                                setFieldValue("bcountry", data.value);
                                states(data.value);
                                // setFormData({
                                //   target: { ...formData, name: "bcountry" },
                                // })
                              }}
                              disabled={true}
                              style={{ width: "100%" }}
                              filterOption={(Input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(Input.toLowerCase()) >= 0
                              }
                            >
                              {state.countries.countries.map(
                                (country, countryIndex) => (
                                  <Option
                                    value={country.value}
                                    index={countryIndex}
                                  >
                                    {country.label}
                                  </Option>
                                )
                              )}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="bstate">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">State</FormLabel>

                            <Select
                              {...field}
                              showSearch
                              size="middle"
                              labelInValue
                              placeholder="Select State"
                              onChange={(data) =>
                                setFieldValue("bstate", data.value)
                              }
                              disabled={true}
                              style={{ width: "100%" }}
                              // disabled={state.states.loading}
                              loading={state.states.loading}
                              filterOption={(Input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(Input.toLowerCase()) >= 0
                              }
                            >
                              {state.states.states.map((state, stateIndex) => (
                                <Option value={state.value} index={stateIndex}>
                                  {state.label}
                                </Option>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <div className="bill-row">
                      <Field name="bcity">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">City</FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="bcity"
                              name="bcity"
                              placeholder="city"
                              onChange={(e) => {
                                setFieldValue("bcity", e.target.value);
                              }}
                              disabled={true}
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="bzip">
                        {({ field, form }) => (
                          <FormControl isRequired className="primary-info">
                            <FormLabel htmlFor="text-Input">Zip</FormLabel>

                            <Input
                              {...field}
                              type="text"
                              id="bzip"
                              name="bzip"
                              placeholder="Zip code"
                              onChange={(e) => {
                                setFieldValue("bzip", e.target.value);
                              }}
                              disabled={true}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </div>
                    <br />
                    <Field name="bstate">
                      {({ field, form }) => (
                        <FormControl>
                          <Checkbox
                            {...field}
                            size="lg"
                            colorScheme="green"
                            // isChecked={true}
                            defaultChecked={
                              state.invoicesList.invoices[0]?.isSameAddress || false
                            }
                            mr={2}
                            mb={2}
                            onChange={(e) => {
                              setFieldValue(
                                "is_same_as_billing",
                                e?.target?.checked
                              );
                              if (e?.target?.checked) {
                                setFieldValue("sfname", values.bfname);
                                setFieldValue("slname", values.blname);
                                setFieldValue("saddress", values.baddress);
                                setFieldValue("saddress2", values.baddress2);
                                setFieldValue("scountry", values.bcountry);
                                setFieldValue("sstate", values.bstate);
                                setFieldValue("scity", values.bcity);
                                setFieldValue("szip", values.bzip);
                              }
                            }}
                            disabled={true}
                          >
                            Is shipping address same as billing address?
                          </Checkbox>
                        </FormControl>
                      )}
                    </Field>
                    {values.is_same_as_billing ? (
                      " "
                    ) : (
                      <>
                        <br />
                        <FormHeading>Shipping Information</FormHeading>

                        <div className="address">
                          <Field name="saddress">
                            {({ field, form }) => (
                              <FormControl isRequired>
                                <FormLabel htmlFor="text-Input">
                                  {" "}
                                  Address
                                </FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="saddress"
                                  name="saddress"
                                  placeholder="Address Line 1"
                                  onChange={(e) => {
                                    // console.log(e.target.value);
                                    setFieldValue("saddress", e.target.value);
                                  }}
                                  disabled={true}
                                  // defaultValue={
                                  //   formData[0]?.billingAddress?.saddress
                                  // }
                                />
                              </FormControl>
                            )}
                          </Field>
                          <Field name="saddress2">
                            {({ field, form }) => (
                              <FormControl>
                                <FormLabel htmlFor="text-Input">
                                  {" "}
                                  Address 2
                                </FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="saddress2"
                                  name="saddress2"
                                  placeholder="Address Line 2"
                                  onChange={(e) => {
                                    setFieldValue("saddress2", e.target.value);
                                    // console.log(e.target.value);
                                  }}
                                  disabled={true}
                                  // defaultValue={
                                  //   formData[0]?.billingAddress?.saddress2
                                  // }
                                />
                              </FormControl>
                            )}
                          </Field>
                        </div>
                        <div className="bill-row">
                          <Field name="scountry">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">
                                  Country
                                </FormLabel>

                                <Select
                                  {...field}
                                  showSearch
                                  size="middle"
                                  // value={formData[0]?.billingAddress?.scountry}
                                  labelInValue
                                  placeholder="Select Country"
                                  onChange={(data) => {
                                    setFieldValue("scountry", data.value);
                                    states(data.value);

                                    // setFormData({
                                    //   target: { ...formData, name: "scountry" },
                                    // })
                                  }}
                                  disabled={true}
                                  style={{ width: "100%" }}
                                  filterOption={(Input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(Input.toLowerCase()) >= 0
                                  }
                                >
                                  {state.countries.countries.map(
                                    (country, countryIndex) => (
                                      <Option
                                        value={country.value}
                                        index={countryIndex}
                                      >
                                        {country.label}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="sstate">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">
                                  State
                                </FormLabel>

                                <Select
                                  {...field}
                                  disabled={true}
                                  showSearch
                                  size="middle"
                                  labelInValue
                                  placeholder="Select State"
                                  onChange={(data) =>
                                    setFieldValue("sstate", data.value)
                                  }
                                  style={{ width: "100%" }}
                                  // disabled={state.states.loading}
                                  loading={state.states.loading}
                                  filterOption={(Input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(Input.toLowerCase()) >= 0
                                  }
                                >
                                  {state.states.states.map(
                                    (state, stateIndex) => (
                                      <Option
                                        value={state.value}
                                        index={stateIndex}
                                      >
                                        {state.label}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                        </div>
                        <div className="bill-row">
                          <Field name="scity">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">City</FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="scity"
                                  name="scity"
                                  placeholder="city"
                                  onChange={(e) => {
                                    setFieldValue("scity", e.target.value);
                                  }}
                                  disabled={true}
                                />
                              </FormControl>
                            )}
                          </Field>
                          <Field name="szip">
                            {({ field, form }) => (
                              <FormControl isRequired className="primary-info">
                                <FormLabel htmlFor="text-Input">Zip</FormLabel>

                                <Input
                                  {...field}
                                  type="text"
                                  id="szip"
                                  name="szip"
                                  placeholder="Zip code"
                                  onChange={(e) => {
                                    setFieldValue("szip", e.target.value);
                                  }}
                                  disabled={true}
                                />
                              </FormControl>
                            )}
                          </Field>
                        </div>
                      </>
                    )}
                    <br />
                    <hr />
                    <br />
                    <FormHeading> Invoice Items</FormHeading>

                    <div>
                      <br />

                      {items.map((item, index) => (
                        <div className="item-container">
                          <div className="item" key={index}>
                            <div className="item-row">
                              <div className="item-field">
                                <label>Item Name:</label>
                                <Input
                                  type="text"
                                  name="itemName"
                                  placeholder="Item Name"
                                  value={item.itemName}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>Description:</label>
                                <Input
                                  type="text"
                                  name="description"
                                  placeholder="Description"
                                  value={item.description}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>HSN No:</label>
                                <Input
                                  type="text"
                                  name="hsn_no"
                                  placeholder="HSN No"
                                  value={item.hsn_no}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>Unit:</label>
                                <Input
                                  type="text"
                                  name="unit"
                                  placeholder="Unit"
                                  value={item.unit}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                            </div>

                            <hr />
                            <div className="item-row-price">
                              <div className="item-field">
                                <label>Quantity:</label>
                                <Input
                                  type="number"
                                  name="quantity"
                                  placeholder="Quantity"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>Rate:</label>
                                <Input
                                  type="number"
                                  name="rate"
                                  placeholder="Rate"
                                  value={item.rate}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>Gross Amount:</label>
                                <Input
                                  type="number"
                                  name="grossAmount"
                                  placeholder="Gross Amount"
                                  value={item.grossAmount}
                                  // onChange={(e) => handleInputChange(index, e)}
                                  isReadOnly={true}
                                  disabled={true}
                                />
                              </div>
                              <div className="item-field">
                                <label>Discount:</label>
                                <Input
                                  type="number"
                                  name="discount"
                                  placeholder="Discount"
                                  value={item.discount}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>Freight:</label>
                                <Input
                                  type="number"
                                  name="freight"
                                  placeholder="Freight"
                                  value={item.freight}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>Taxable Amount:</label>
                                <Input
                                  type="number"
                                  name="taxableAmount"
                                  placeholder="Taxable Amount"
                                  value={item.taxableAmount}
                                  // onChange={(e) => handleInputChange(index, e)}
                                  isReadOnly={true}
                                  disabled={true}
                                />
                              </div>
                              <div className="item-field">
                                <label>CGST:</label>
                                <Input
                                  type="number"
                                  name="cgstAmount"
                                  placeholder="CGST Amount"
                                  value={item.cgstAmount}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>SGST:</label>
                                <Input
                                  type="number"
                                  name="sgstAmount"
                                  placeholder="SGST Amount"
                                  value={item.sgstAmount}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                              <div className="item-field">
                                <label>IGST:</label>
                                <Input
                                  type="number"
                                  name="igstAmount"
                                  placeholder="IGST Amount"
                                  value={item.igstAmount}
                                  onChange={(e) =>
                                    handleInputChange(index, e, false)
                                  }
                                />
                              </div>
                            </div>
                           
                          </div>
                          <div>
                            <IconButton
                              // colorScheme="green"
                              size="sm"
                              style={{
                                background: "white",
                                color: "red",
                              }}
                              icon={<Icons.Delete />}
                              onClick={() => deleteItem(index)}
                            />
                            {!item.splitItems && (
                              <IconButton
                                // colorScheme="green"
                                size="sm"
                                style={{
                                  background: "white",
                                  color: "green",
                                }}
                                icon={<Icons.Split />}
                                onClick={() => {
                                  onOpen();
                                  setSplitItemIndex(index);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                      <br />
                      <Button
                        // colorScheme="green"
                        size="sm"
                        style={{
                          background: "white",
                          color: "var( --primary-dark-background)",
                        }}
                        icon={<Icons.Add />}
                        onClick={addItem}
                      >
                        + Add Item
                      </Button>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <div className="item-total">
                      <div className="item-div">
                        <label>Total Gross Amount:</label>
                        <Input
                          type="number"
                          name="grossAmount"
                          value={tgrossAmount}
                          onChange={(e) => {}}
                          disabled={true}
                        />
                      </div>
                      <div className="item-div">
                                <label>Total Discount:</label>
                                <Input
                                  type="number"
                                  name="discount"
                                  placeholder="Discount"
                                  value={tdiscount}
                                  onChange={(e) => {}}
                          disabled={true}
                                />
                              </div>
                              <div className="item-div">
                                <label>Total Freight:</label>
                                <Input
                                  type="number"
                                  name="freight"
                                  placeholder="Freight"
                                  value={tfreight}
                                  onChange={(e) => {}}
                                  disabled={true}
                                />
                                </div>
                      <div className="item-div">
                        <label>Total Taxable Amount:</label>
                        <Input
                          type="number"
                          value={tTaxableAmount}
                          name="taxableAmount"
                          onChange={(e) => {}}
                          disabled={true}
                        />
                      </div>
                      <div className="item-div">
                        <label>Total SGST:</label>
                        <Input
                          type="number"
                          name="sgstAmount"
                          value={tsgstAmount}
                          disabled={true}
                          onChange={(e) => {}}
                        />
                      </div>
                      <div className="item-div">
                        <label>Total CGST:</label>
                        <Input
                          type="number"
                          name="cgstAmount"
                          value={tcgstAmount}
                          disabled={true}
                          onChange={(e) => {}}
                        />
                      </div>
                      <div className="item-div">
                        <label>Total IGST:</label>
                        <Input
                          type="number"
                          name="igstAmount"
                          value={tigstAmount}
                          disabled={true}
                          onChange={(e) => {}}
                        />
                      </div>
                      <div className="item-div">
                        <label>Roundoff:</label>
                        <Input
                          type="number"
                          value={roundoff}
                          name="roundoff"
                          onChange={(e) => {
                            setRoundOff(e.target.value);
                            calculateTotals();
                          }}
                        />
                      </div>

                      <div className="item-div">
                        {" "}
                        <label>Grand Total:</label>
                        <Input
                          type="number"
                          value={totalAmount}
                          disabled={true}
                          name="totalAmount"
                          onChange={(e) => {}}
                        />
                      </div>
                    </div>
                  </AddressDiv>
                  <br />
                </div>

                <Button
                  mt={4}
                  className="submit_right1"
                  isLoading={isloading}
                  type="submit"
                  // onClick={() => {
                  //   console.log("values.....",values);
                  //   updateInvoice(values);
                  // }}
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        {/* </ModalBody> */}
      </Card>
      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent minH="15rem" maxH="35rem">
          <ModalHeader
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            Enter Split Amount
            <div>
              <CloseButton onClick={onClose} />
            </div>
          </ModalHeader>
          <SplitPopUp
            onClose={onClose}
            onSplitAmountEntered={handleSplitAmountEntered}
            setNumOfSplits={setNumOfSplits}
            mainItemAmount={items[splitItemIndex]?.rate}
          />
        </ModalContent>
      </Modal>
    </div>
  );
}
