import React,{ useEffect }  from "react";
import { useHistory } from "react-router-dom";
import "../../Styles/Master.scss";
import "./DashBoard.scss";
import { API } from "../../Services";

const DashBoard = (props) =>{

    const history = useHistory();
    return <div className="ap-flex ap-w100 ap-flex-center-center ap-fmd ap-fwmd">
        Coming Soon ........
    </div>
}

export default DashBoard;
