import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { API } from "../../../Services";
import { Icons } from "../../../Utils";
import { Modal, ModalOverlay, ModalContent, ModalHeader as ChakraModalHeader, ModalFooter as ChakraModalFooter, ModalBody as ChakraModalBody, ModalCloseButton as ChakraModalCloseButton, useDisclosure } from "@chakra-ui/react";
import { CloseButton } from "react-bootstrap";
import { EditBillingInfo } from "../../Clients/ClientsBillingInfo";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { useDispatch, useSelector } from "react-redux";

export default function BillingReminder({
  setData,
  selectedCustomerId,
  isClass,
  setState,
  setIsBillingInfoModalOpen,
}) {
  const [isSendBilingDisable, setSendBilingDisable] = useState(false);
  const state = useSelector((state) => state);

  const { isOpen: isBillOpen, onOpen: onBillOpen, onClose: onBillClose } = useDisclosure();
  const dispatch = useDispatch();

  const {
    customer,
  } = bindActionCreators(actionCreators, dispatch);
  useEffect(() => {
    if(selectedCustomerId)
{    customer(selectedCustomerId);
}
  }, [selectedCustomerId]);

  const handleSendReminder = () => {
    setSendBilingDisable(true);
    API.sendBilingLink(selectedCustomerId)
      .then((res) => {
        console.log(res);
        setSendBilingDisable(false);
        if (isClass) {
          console.log("isClass", isClass);
          setState({ showBillingReminderModal: false, selectedAppOption: "" });
        } else {
          setIsBillingInfoModalOpen(false);
          setData({ customer: null });
        }

        toast.success(res?.data?.message, {
          position: "top-right",
        });
      })
      .catch((err) => {
        if (isClass) {
          setState({ showBillingReminderModal: false, selectedAppOption: "" });
        } else {
          setIsBillingInfoModalOpen(false);
          setData({ customer: null });
        }
        console.log(err);
        toast.error("Error! ", err, {
          position: "top-right",
        });
      });
  };

  const handleCancel = () => {
    if (isClass) {
      setState({ showBillingReminderModal: false, selectedAppOption: "" });
    } else {
      setIsBillingInfoModalOpen(false);
      setData({ customer: null });
    }
  };

  return (
    <div>
      <ModalHeader>Billing Info Not Available</ModalHeader>
      <ModalBody>
        The billing info for this customer is not available. Would you like to
        send a reminder?
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleSendReminder}>
          Send Billing Reminder
        </Button>
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          key="reminder"
          type="primary"
          onClick={() => {
            // Open the outer modal
            onBillOpen();
          }}
        >
          <span style={{ display: "flex" }}>
            <Icons.Add /> Add Billing Info
          </span>{" "}
        </Button>
      </ModalFooter>
      {/* Outer Modal */}
      <Modal isOpen={isBillOpen} onClose={onBillClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent minH="18rem" maxH="35rem">
          <ChakraModalHeader
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            Add Billing Information
            <div>
              <CloseButton onClick={onBillClose} />
            </div>
          </ChakraModalHeader>
          <ChakraModalBody>
            <EditBillingInfo
              
              CustomerID={selectedCustomerId}
              isbillingInfo={false}
              customerDetail={state.customer.customer}
              // billingAddress = {billingAddress}
              // shippingAddress = {shippingAddress}
            />
          </ChakraModalBody>
          <ChakraModalFooter>
            {/* Add buttons or actions here */}
          </ChakraModalFooter>
        </ModalContent>
      </Modal>

    
    </div>
  );
}
