import {
  Button,
  Checkbox,
  CloseButton,
  ModalBody,
  ModalHeader,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import FTIcon from "react-file-type-icons";
import CadIcon from "../../../Assets/Icons/cad.png";
import { FormDiv, Submit, UploadDiv } from "./attachementStyle";
import { Icons } from "../../../Utils";
import { Tag, Input, Spin, Select } from "antd";
import { API } from "../../../Services";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { actionCreators } from "../../../Redux";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

export default function UploadAttachment(props) {
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({ isNeedAproval: false });
  const [tags, setTags] = useState([]);
  const [loading, setLoad] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const { Option } = Select;
  const {
    trelloCommentList,
    projectAttachementSubCategories,
    trelloAttachmentList,
  } = bindActionCreators(actionCreators, dispatch);
  const state = useSelector((state) => state);

  const handleFileSelect = (event) => {
    let files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };
  useEffect(() => {
    if (state.projectAttachementCategories?.categories?.length > 0) {
      let id;
      if (props.isCheklist == true) {
        id = state.projectAttachementCategories?.categories
          ?.filter((item) => item?.name == "Service Upload")
          .map((i) => i.ID);
      } else {
        id = state.projectAttachementCategories?.categories
          ?.filter((item) => item?.name == "General Upload")
          .map((i) => i.ID);
      }
      projectAttachementSubCategories(id);
    }
  }, []);

  const handleDrop = (event) => {
    event.preventDefault();
    let files = event.dataTransfer.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };
  const handleRemoveFile = (index) => {
    let newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);

    let newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
    setPreviewUrls(newPreviewUrls);
  };
  useEffect(() => {
    Promise.all(
      selectedFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(file);
        });
      })
    ).then((results) => {
      setPreviewUrls(results);
    });
  }, [selectedFiles]);

  const renderSelectedFiles = () => {
    return selectedFiles.map((file, index) => {
      return (
        <div key={index} className="preview-div">
          <button onClick={() => handleRemoveFile(index)}>X</button>
          {previewUrls[index] && (
            <div className="display-preview">
              {file.type.startsWith("image/") ? (
                <img
                  className="preview"
                  src={previewUrls[index]}
                  alt="Preview"
                />
              ) : (
                <div className="filename">
                  {file.name?.split(".").pop() !== ("cad" || "CAD") ? (
                    <span className="file-icon">
                      <FTIcon
                        fileName={file.name?.split(".")?.pop() || "url"}
                        style={{ fontSize: "40px" }}
                        color="#2b6b2a"
                        fileType={"url"}
                      />
                    </span>
                  ) : (
                    <img src={CadIcon} style={{ width: "40px" }} alt="" />
                  )}
                </div>
              )}
            </div>
          )}
          <span className="file-name">{file.name}</span>
        </div>
      );
    });
  };

  const onSubmit = () => {
    setLoad(true);
    let uploadData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      uploadData.append("media[]", selectedFiles[i]);
    }
    uploadData.append(
      "approverGroupID",
      formData?.isNeedAproval ? formData?.groupID : "123 "
    );
    uploadData.append("tags", tags.join(","));
    uploadData.append("category", formData?.category);

    if (props.isCheklist == true) {
      uploadData.append("orderID", props?.orderID);
      uploadData.append("serviceID", props?.serviceID);
      uploadData.append("isFileNeedApproval", 1);
    } else {
      uploadData.append("isFileNeedApproval", 0);
    }

    API.addTrelloAttachment(id, uploadData)
      .then((res) => {
        setLoad(false);
        console.log(res);
        toast.success("updated successfully.", {
          position: "top-right",
        });
        trelloAttachmentList(id, `limit=5&page=1`);
        trelloCommentList(id, 20, 1);

        props.onClose();
      })
      .catch((err) => {
        setLoad(false);
        toast.error("Error", err.message, {
          position: "top-right",
        });
        props.onClose();
      });
  };
  return (
    <div>
      <ModalHeader style={{ justifyContent: "space-between", display: "flex" }}>
        Upload File{" "}
        <div>
          <CloseButton
            onClick={() => {
              props.onClose();
            }}
          />
        </div>
      </ModalHeader>
      <ModalBody>
        <UploadDiv>
          <div
            className="file-upload"
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
          >
            {selectedFiles.length > 0 ? (
              <>
                {renderSelectedFiles()}
                <div>
                  <Button
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                  >
                    +
                  </Button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />
                </div>
              </>
            ) : (
              <div className="placeholder">
                <div>
                  <Button
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                  >
                    Choose a file
                  </Button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />
                </div>
                <div> or</div> <div>Drag it here...</div>
              </div>
            )}
          </div>
          <FormDiv>
            {props.isCheklist == true && (
              <>
                <Checkbox
                  size="lg"
                  colorScheme="green"
                   isChecked={true}
                  mr={2}
                  mb={2}
                  onChange={(e) => {
                    setFormData((prev) => {
                      prev = JSON.parse(JSON.stringify(prev));
                      prev[`isNeedAproval`] = e?.target?.checked;
                      return prev;
                    });
                  }}
                >
                  The File Need Approval?
                </Checkbox>
                <br />
              </>
            )}

            <div style={{ padding: "1rem 0" }}>
              <div className="ap-flex ap-flex-center-center ap-w100">
                <Select
                  size="large"
                  showSearch
                  value={formData?.category}
                  labelInValue
                  placeholder="Select Category"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(data) => {
                    console.log(data.label)

                    setFormData((prev) => {
                      prev = JSON.parse(JSON.stringify(prev));
                      prev[`category`] = data.value;
                      return prev;
                    });
                  }}
                >
                  {" "}
                  {state.projectAttachementSubCategories?.subCategories?.map(
                    (cat, adminIndex) => (
                      <Option value={cat.ID} key={adminIndex}>
                        {cat.name}
                      </Option>
                    )
                  )}
                </Select>
              </div>
            </div>
          </FormDiv>
          <Submit>
            <Button
              className="submit_right1"
              isDisabled={loading || (selectedFiles.length == 0)||!formData?.category}
              onClick={onSubmit}
              isLoading={loading ? <Spin /> : ""}
            >
              Submit
            </Button>
          </Submit>
        </UploadDiv>
      </ModalBody>
    </div>
  );
}
