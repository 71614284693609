import { Button, Card, Input, Select, Space, Spin, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Loader } from "../../../Components";
import { DateRangePicker } from "react-dates";
import toast from "react-hot-toast";

import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";

export default function PaymentRequestLink(props) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    value: "",
    key: "",
  });
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const { Option } = Select;

  const [mainLoad, setMainLoad] = useState(true);
  const { paymentLinkList, customersList, admins } = bindActionCreators(
    actionCreators,
    dispatch
  );

  useEffect(() => {
        admins();
    setTimeout(() => {
      console.log(state.paymentLinkList);
      setMainLoad((prev) => !prev);
    }, 3000);
  }, []);

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Id</div>,
      dataIndex: "linkId",
      key: "linkId",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">customer Name</div>,
      dataIndex: "partnerName",
      key: "partnerName",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Link</div>,
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <div
          className="ap-fsm"
          style={{ display: "flex",
        //    justifyContent: "space-between"
         }}
        >
          {text.link}
          {text.linkExpired !== true && (
            <span
              onClick={() => {
                navigator.clipboard.writeText(text.link);
                toast.success("Link Copied!", {
                  position: "top-right",
                });
              }}
              style={{
                fontSize: "22px",
                display: "flex",
                color: " var( --primary-green)",
                cursor: "pointer",
                padding: "5px 10px",
              }}
            >
              <Icons.CopyOutlined />
            </span>
          )}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">Created By</div>,
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">currency</div>,
      dataIndex: "currency",
      key: "currency",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Amount</div>,
      dataIndex: "amount",
      key: "amount",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    
    {
      title: <div className="ap-fsm ap-fwmd">Payment Request ID</div>,
      dataIndex: "paymentRequestID",
      key: "paymentRequestID",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Creation date</div>,
      dataIndex: "creationdate",
      key: "creationdate",
      render: (text) => (
        <div className="ap-fsm">
          {moment(text).format("MMM D, YYYY [at] h:mm A ")}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">Payment Date</div>,
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (text) => (
        <div className="ap-fsm">
          {text?moment(text).format("MMM D, YYYY [at] h:mm A "):"-"}
          {/* {text} */}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">link Status</div>,
      dataIndex: "linkExpired",
      key: "linkExpired",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
        title: <div className="ap-fsm ap-fwmd">Payment Status</div>,
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (text) => <div className="ap-fsm">{text}</div>,
      },
  ].filter((item) => !item.hidden);

  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      if (["projectCode"].includes(event.target.name)) {
        prev[`${event.target.name}`] = event.target.value;
      } else {
        prev[`${event.target.name}`] = event.target;
      }
      return prev;
    });
  };

  useEffect(() => {
    if (state.paymentLinkList.links.length > 0) {
      console.log(state.paymentLinkList.links);
      setTableData((prev) => {
        prev = [];
        state.paymentLinkList.links.map((link, customerIndex) => {
          prev.push({
            key: customerIndex,
            linkId: link?.id,
            partnerName: `${link?.customer?.firstName} ${link?.customer?.lastName}(+${link?.customer?.isd_code}-${link?.customer?.phoneNumber})`,
            link: { link: link?.link || "NA", linkExpired: link.linkExpired },
            createdBy:`${link?.admin?.name} ${link?.admin?.lastname}`,
            paymentRequestID: link.paymentRequestID || "NA",
            creationdate: link.creationdate || "NA",
            linkExpired: link.linkExpired == true ? "Expired" : "-",
            paymentStatus: link.status||"-",
            paymentDate:link?.paymentDate,
            amount:link?.amount,
            currency:link?.currency,

          });
        });
        return prev;
      });
    } else {
      setTableData((prev) => {
        console.log(prev);
      });
    }
  }, [state]);

  const callRecords = (event) => {
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };

  useEffect(() => {
    let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10;
    paymentLinkList(
      `limit=${current.size}&offset=${offset}&admin_id=${
        data.assignee ? data.assignee.value : ""
      }&customer_id=${
        data.customer
          ? data.customer.value
          : props.isPaymentRequest == true
          ? props.customerId
          : ""
      }&status=${
        data.status
          ? data.status.value: ""
      }`
    );
  }, [current, props.customerId, props.isLinkGenerated]);

  return (
    <>
      {/* { mainLoad ? <Loader />: */}
      <div className="ap-flex ap-flex-center-center ap-w100">
        {props.isPaymentRequest == true ? (
          ""
        ) : (
          <div className="ap-flex ap-w100">
            <Card style={{ width: "100%" }}>
              <div className="ap-flex ap-w100 ">
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Customers
                  </div>
                  <Select
                    size="large"
                    showSearch
                    onSearch={(data) => customersList(`q=${data}`)}
                    labelInValue
                    placeholder="Select Customer"
                    style={{ width: "100%" }}
                    value={data?.customer}
                    loading={state.customers.loading}
                    onChange={(data) =>
                      setFormData({ target: { name: "customer", ...data } })
                    }
                    notFoundContent={
                      state.customers.loading && (
                        <div className="ap-w100 ap-flex ap-flex-center">
                          <Spin size="small" />
                        </div>
                      )
                    }
                    filterOption={false}
                  >
                    {state.customers.customers.map(
                      (customer, customerIndex) => (
                        <Option value={customer.value} index={customerIndex}>
                          {customer.label}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                <div className="ap-flex ap-flex-center-center ap-w33">
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Created By
                  </div>
                  <Select
                    size="large"
                    showSearch
                    value={data?.assignee}
                    labelInValue
                    placeholder="Select Assignee"
                    style={{ width: "100%" }}
                    loading={state.admins.loading}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(data) =>
                      setFormData({ target: { name: "assignee", ...data } })
                    }
                  >
                    {state.admins.admins.map((admin, adminIndex) => (
                      <Option value={admin.value} key={adminIndex}>
                        {admin.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Status</div>
                  <Select
                    size="large"
                    // showSearch
                    labelInValue
                    placeholder="Select Status"
                    style={{ width: "100%" }}
                    value={data?.status}
                    loading={state.customers.loading}
                    onChange={(data) =>
                      setFormData({ target: { name: "status", ...data } })
                    }
                    notFoundContent={
                      state.customers.loading && (
                        <div className="ap-w100 ap-flex ap-flex-center">
                          <Spin size="small" />
                        </div>
                      )
                    }
                    filterOption={false}
                  >
                    <Option value={"paid"}>Paid</Option>
                    <Option value={"created"}>Created</Option>
                    <Option value={"failed"}>Failed</Option>
                  </Select>
                </div>

                {/* <div className="ap-flex ap-flex-center-center ap-w33">
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd"> className="lable_filter">Filter By Date Range</div>
                                <DateRangePicker
                                    startDate={this.state.startDate}
                                    startDateId="startDate"
                                    endDate={this.state.endDate}
                                    endDateId="endDate"
                                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    isOutsideRange={this.isOutsideRange}
                                />

                            </div>
                */}
              </div>

              <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
                <div className="ap-flex ap-flex-center-center search-btn">
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={state.paymentLinkList.loading ? <Spin /> : false}
                    icon={<Icons.Search />}
                    size="middle"
                    onClick={() =>
                      callRecords({ payload: { page: 1, size: 10 } })
                    }
                  >
                    <span className="ap-fesm ap-fwmd">Search</span>
                  </Button>
                </div>
                <div
                  className="ap-flex ap-flex-center-center search-btn"
                  style={{ marginLeft: "10px" }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={state.paymentLinkList.loading ? <Spin /> : false}
                    icon={<Icons.Sweep />}
                    size="middle"
                    onClick={() => {
                      setData({ key: "", value: "" });
                      paymentLinkList(`limit=10&offset=0&q=`);
                    }}
                  >
                    <span className="ap-fesm ap-fwmd">Clear</span>
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        )}

        {!state.paymentLinkList.loading ? (
          <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
            <Card style={{ width: "100%" }}>
              {props.isPaymentRequest == true ? (
                ""
              ) : (
                <div
                  className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record"
                  onClick={() => {
                    setData({ key: "", value: "" });
                    paymentLinkList(`limit=10&offset=0&q=`);
                  }}
                >
                  Reset Records
                </div>
              )}
              <Table
                columns={columns}
                dataSource={tableData}
                scroll={{ x: 900 }}
                pagination={{
                  current: current.page,
                  pageSize: current.size,
                  position: ["bottomLeft"],
                  total: state.paymentLinkList.totalRecords,
                  onChange: (data, size) =>
                    callRecords({
                      type: "page",
                      payload: { page: data, size: size },
                    }),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </Card>
          </div>
        ) : (
          <div
            className="ap-flex ap-w100 ap-flex-center-center"
            style={{ height: "300px" }}
          >
            <Spin tip="Fetching Records....." />
          </div>
        )}
      </div>
      {/* } */}
    </>
  );
}
