const customOrderReducer = (state = {
    loading:false,
    customOrder:null
}, action) => {
    switch (action.type) {
        case "FETCH_CUSTOM_ORDER":
            return { ...state ,loading:true,customOrder:null}
        case "RECEIVE_CUSTOM_ORDER":
            return {...state, customOrder:action.payload,loading:false}
        case "ERROR_CUSTOM_ORDER":
            return { ...state ,customOrder:null,loading:true}
        default:
            return { ...state }
    }
}

export default customOrderReducer;