const currencyReducer = (state = {
    loading:false,
    currencies:[],totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_CURRENCY":
            return { ...state ,loading:true,currencies:[],totalRecords:0}
        case "RECEIVE_CURRENCY":
            return {...state, currencies:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_CURRENCY":
            return { ...state ,currencies:[],loading:true,totalRecords:0}
        default:
            return { ...state }
    }
}

export default currencyReducer;