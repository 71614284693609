
const partnersListReducer = (state = {
    loading:false,
    customers:[],totalRecords:0,msg:''
}, action) => {
    switch (action.type) {
        case "FETCH_PARTNER_LIST":
            return { ...state ,loading:true,customers:[],totalRecords:0,msg:''}
        case "RECEIVE_PARTNER_LIST":
            return {...state, customers:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords,msg:''}
        case "ERROR_PARTNER_LIST":
            return { ...state ,customers:[],loading:true,totalRecords:0,msg:action.payload}
        default:
            return { ...state }
    }
}

export default partnersListReducer;