import { API } from "../../Services";
export const crmCustomfieldList = (query = "") => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CUSTOM_FIELD_LIST",
    });
    API.crmCustomfieldList()
      .then((res) => {
        console.log("CUSTOM_FIELD",res);
        let data = JSON.parse(JSON.stringify(res)).data.customers;
        data.map((customer, customerIndex) => {
          customer.value = customer.id;
          customer.label = `${customer.firstName} ${customer.lastName}`;
        });
        setTimeout(() => {
          dispatch({
            type: "RECEIVE_CUSTOM_FIELD_LIST",
            payload: { data: data, totalRecords: res.data.totalRecords },
          });
        }, 1000);
      })
      .catch((err) => {
                console.log("CUSTOM_FIELD", err);

        dispatch({
          type: "ERROR_CUSTOM_FIELD_LIST",
        });
      });
  };
};
