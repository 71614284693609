import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import {
  Card,
  Input,
  Spin,
  Button,
  Table,
  Space,
  Select,
  DatePicker,
} from "antd";
import { Icons } from "../../../Utils";
import moment from "moment";

export default function InvoicesList(props) {
  const state = useSelector((state) => state);
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const history = useHistory();
  const InputGroup = Input.Group;

  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const [mainLoad, setMainLoad] = useState(true);
  const { invoicesList, admins } = bindActionCreators(actionCreators, dispatch);
  const { Option } = Select;

  useEffect(() => {
    admins();
    setTimeout(() => {
      console.log(state.invoicesList.invoices);
      setMainLoad((prev) => !prev);
    }, 3000);
  }, []);

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Invoice Id</div>,
      dataIndex: "invoiceId",
      key: "invoiceId",
      render: (text) => <div className="ap-fsm">{text}</div>,
      hidden: true,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Customer Name</div>,
      dataIndex: "customerName",
      key: "customerName",
      render: (text,record) => <div className="ap-fsm"><span style={{cursor: "pointer"}} onClick={()=>{history.push("/home/ap/client/details/"+record?.customerId)}}>{text}</span></div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Email</div>,
      dataIndex: "email",
      key: "email",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Contact No.</div>,
      dataIndex: "contactNo",
      key: "contactNo",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Creation Date</div>,
      dataIndex: "creationDate",
      key: "creationDate",
      render: (text) => <div className="ap-fsm">{moment.unix(text).format("MMM D, YYYY [at] h:mm A z")}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Invoice ID</div>,
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    // {
    //   title: <div className="ap-fsm ap-fwmd">Invoice Type</div>,
    //   dataIndex: "invoiceType",
    //   key: "invoiceType",
    //   render: (text) => <div className="ap-fsm">{text}</div>,
    // },

    {
      title: <div className="ap-fsm ap-fwmd">Total Amount</div>,
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Status</div>,
      dataIndex: "status",
      key: "status",
      render: (text) => <div className="ap-fsm"><span style={{background:`${text=='confirmed'?'#00b700':'#40a9ff'}`,color:'#fff',padding:'2px 4px'}}>{text}</span></div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Action</div>,
      key: "action",
      render: (text, record) => (
        <Space size="small" className="table-btn">
          <Button
            type="primary"
            className="ap-btn-c"
            shape="round"
            icon={<Icons.Details />}
            size="middle"
            onClick={() => {
              history.push(`/home/ap/invoice/details/${record.invoiceId}`);
            }}
          >
            <span className="ap-fesm ap-fwmd">Show Details</span>
          </Button>
        </Space>
      ),
      width: "fit-content",
      fixed: "right",
    },
  ].filter((item) => !item.hidden);

  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      if (event.target.name == "startDate") {
        prev[`${event.target.name}`] = moment(event.target.value._d)?.format(
          "YYYY-MM-DD"
        );
      } else if (event.target.name == "endDate") {
        prev[`${event.target.name}`] = moment(event.target.value._d)?.format(
          "YYYY-MM-DD"
        );
      } else {
        prev[`${event.target.name}`] = event.target;
      }

      return prev;
    });
  };

  useEffect(() => {
    if (state.invoicesList.invoices.length > 0) {
      console.log(state.invoicesList.invoices);
      setTableData((prev) => {
        prev = [];
        state.invoicesList.invoices.map((invoice, invoiceIndex) => {
          prev.push({
            key: invoiceIndex,
            invoiceId: invoice._id||"NA",
            invoiceNo:invoice?.invoiceNo||"NA",
            customerName: `${invoice.firstName} ${invoice.lastName}`,
            customerId:invoice?.customerId,
            email: invoice.email || "NA",
            contactNo: `${invoice?.phoneNumber || "NA"}`,
            creationDate: invoice?.creationDate,
            invoiceType: invoice?.invoiceType || "NA",
            totalAmount: invoice?.totalAmount
              ? `${invoice?.totalAmount} ${invoice?.currency}`
              : "NA",
            status: invoice.status || "NA",
          });
        });
        return prev;
      });
    } else {
      setTableData((prev) => {
        console.log(prev);
      });
    }
  }, [state]);

  const callRecords = (event) => {
    console.log(event);
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };
 
  useEffect(() => {
    console.log(data);
    // let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10;
    if(props.isClient == true)
    {
      invoicesList(`limit=${current.size}&page=${current.page}&customerId=${props.clientID}`)
    }else
   { invoicesList(`limit=${current.size}&page=${current.page}&createdBy=${
      data?.createdBy ? data?.createdBy?.value : ""
    }
    &email=${data?.email ? data?.email?.value : ""}&phoneNumber=${
      data?.phoneNumber ? data?.phoneNumber?.value : ""
    }
    &status=${data?.status ? data?.status?.value : ""}&invoiceType=${
      data?.invoiceType ? data?.invoiceType?.value : ""
    }&startDate=${data?.startDate ? data?.startDate : ""}&endDate=${
      data?.endDate ? data?.endDate : ""
    }&to=${
      data?.to ? data?.to?.value : ""
    }&from=${
      data?.from ? data?.from?.value : ""
    }`);}
  }, [current]);
  return (
    <>
      {" "}
      {state?.invoicesList?.msg !== "" ? (
        <>
          <div
            style={{
              fontSize: "large",
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            <h3>Unauthorized</h3>
          </div>
        </>
      ) : (
        <div className="ap-flex ap-flex-center-center ap-w100">
         { props.isClient?(''):
          <div className="ap-flex ap-w100">
            <Card style={{ width: "100%" }}>
              <div className="ap-flex ap-w100 ">
                <div className="ap-flex ap-flex-center-center ap-w33">
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Created By
                  </div>
                  <Select
                    showSearch
                    size="large"
                    value={data?.createdBy}
                    labelInValue
                    placeholder="Select Manager"
                    style={{ width: "100%" }}
                    loading={state.admins.loading}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(data) => {
                      setFormData({ target: { name: "createdBy", ...data } });
                      console.log(data);
                    }}
                  >
                    {state.admins.admins.map((admin, adminIndex) => (
                      <Option value={admin.value} key={admin.value}>
                        {admin.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Email</div>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Enter Customer Email"
                    value={data.email ? data.email.value : ""}
                    name="email"
                    // disabled={
                    //   data.key !== "" &&
                    //   data.key !== "email" &&
                    //   data.value !== ""
                    // }
                    onChange={(e) => {
                      setFormData({
                        target: { name: e.target.name, value: e.target.value },
                      });
                    }}
                  />
                </div>
                <div className="ap-flex ap-flex-center-center ap-w33">
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Customer Phone Number.
                  </div>
                  <Input
                    size="large"
                    type="number"
                    style={{ width: "100%" }}
                    placeholder="Enter Customer Contact Number"
                    value={data.phoneNumber ? data.phoneNumber.value : ""}
                    name="phoneNumber"
                    // disabled={
                    //   data.key !== "" &&
                    //   data.key !== "phoneNumber" &&
                    //   data.value !== ""
                    // }
                    onChange={(e) =>
                      setFormData({
                        target: { name: e.target.name, value: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <div className="ap-flex ap-w100 ">
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Filter By Date Range
                  </div>
                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                    }}
                  >
                    <RangePicker
                      value={
                        data?.startDate
                          ? [moment(data?.startDate), moment(data?.endDate)]
                          : ""
                      }
                      onChange={(momentData, Date) => {
                        setFormData({
                          target: { name: "startDate", value: momentData[0] },
                        });
                        setFormData({
                          target: { name: "endDate", value: momentData[1] },
                        });
                      }}
                      //format={dateFormat}
                    />
                  </span>
                </div>
                <div className="ap-flex ap-flex-center-center ap-w33">
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Status</div>
                  <Select
                    showSearch
                    size="large"
                    value={data?.status}
                    labelInValue
                    placeholder="Select Status"
                    style={{ width: "100%" }}
                    loading={state.admins.loading}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(data) => {
                      setFormData({ target: { name: "status", ...data } });
                      console.log(data);
                    }}
                  >
                    <Option value={"confirmed"} key={"confirmed"}>
                      Confirmed
                    </Option>
                    <Option value={"draft"} key={"draft"}>
                      Draft
                    </Option>
                  </Select>
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Amount Range
                  </div>
                  <InputGroup compact>
                    <Input
                      size="large"
                      name="from"
                      style={{ width: 100, textAlign: "center" }}
                      value={data.from?.value?data.from?.value:''}
                      placeholder="From"
                      onChange={(e) =>
                        setFormData({
                          target: {
                            name: e.target.name,
                            value: e.target.value,
                          },
                        })
                      }
                    />
                    <Input
                     size="large"
                      style={{
                        width: 30,
                        borderLeft: 0,
                        pointerEvents: "none",
                        backgroundColor: "#fff",
                      }}
                      placeholder="~"
                      disabled
                    />
                    <Input
                      size="large"
                      name="to"
                      value={data.to?.value?data.to?.value:''}
                      style={{ width: 100, textAlign: "center", borderLeft: 0 }}
                      placeholder="To"
                      onChange={(e) =>
                        setFormData({
                          target: {
                            name: e.target.name,
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
                <div className="ap-flex ap-flex-center-center search-btn">
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={state.invoicesList.loading ? <Spin /> : false}
                    icon={<Icons.Search />}
                    size="middle"
                    onClick={() =>
                      callRecords({ payload: { page: 1, size: 10 } })
                    }
                  >
                    <span className="ap-fesm ap-fwmd">Search</span>
                  </Button>
                </div>
                <div
                  className="ap-flex ap-flex-center-center search-btn"
                  style={{ marginLeft: "10px" }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={state.invoicesList.loading ? <Spin /> : false}
                    icon={<Icons.Sweep />}
                    size="middle"
                    onClick={() => {
                      setData({ key: "", value: "" });
                      invoicesList(`limit=10&page=1&q=`);
                    }}
                  >
                    <span className="ap-fesm ap-fwmd">Clear</span>
                  </Button>
                </div>
              </div>
            </Card>
          </div>}

          {!state.invoicesList.loading ? (
            <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
              <Card style={{ width: "100%" }}>

              { props.isClient? <div 
                    className="ap-flex ap-w100 ap-fmd ap-fwmd" 
                    style={{marginBottom:'5px'}}
                >
                    Clients Invoices
                </div>:
                <div
                  className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record"
                  onClick={() => {
                    setData({ key: "", value: "" });
                    invoicesList(`limit=10&page=1&q=`);
                  }}
                >
                  Reset Records
                </div>}

                <Table
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ x: 900 }}
                  pagination={{
                    current: current.page,
                    pageSize: current.size,
                    position: ["bottomLeft"],
                    total: state.invoicesList.totalRecords,
                    onChange: (data, size) =>
                      callRecords({
                        type: "page",
                        payload: { page: data, size: size },
                      }),
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />
              </Card>
            </div>
          ) : (
            <div
              className="ap-flex ap-w100 ap-flex-center-center"
              style={{ height: "300px" }}
            >
              <Spin tip="Fetching Records....." />
            </div>
          )}
        </div>
      )}
    </>
  );
}
