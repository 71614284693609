const PartnerLocationsReducer = (state = {
    loading:false,
    cities:null
}, action) => {
    switch (action.type) {
        case "FETCH_PARTNER_LOCATIONS":
            return { ...state ,loading:true,cities:null}
        case "RECEIVE_PARTNER_LOCATIONS":
            return {...state, cities:action.payload,loading:false,}
        case "ERROR_PARTNER_LOCATIONS":
            return { ...state ,cities:null,loading:true}
        default:
            return { ...state }
    }
}

export default PartnerLocationsReducer;