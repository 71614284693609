import { axiosInstance } from ".."

const projectFolderpath = async (querry) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`/internal/projects/list?projectID=${querry}`).then((res) => { 
            if(res.status === 200){
                console.log(res)
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default projectFolderpath;