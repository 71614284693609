const statesReducer = (state = {
    loading:false,
    states:[]
}, action) => {
    switch (action.type) {
        case "FETCH_STATES":
            return { ...state ,loading:true,states:[]}
        case "RECEIVE_STATES":
            return {...state, states:[...action.payload],loading:false,}
        case "ERROR_STATES":
            return { ...state ,states:[],loading:true}
        default:
            return { ...state }
    }
}

export default statesReducer;