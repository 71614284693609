import React, { useEffect, useRef, useState } from "react";
import "../../../Styles/Master.scss";
import "./ProjectDetails.scss";
import { Loader } from "../../../Components";
import {
  Card,
  Spin,
  Button,
  Table,
  Tag,
  Space,
  notification,
  Tooltip,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { useHistory, useParams } from "react-router";
import { Icons } from "../../../Utils";
import { CustomerDetails } from "../../../Components";
import { API, baseURL, mmhBaseURL } from "../../../Services";
import Swal from "sweetalert2";
import FolderUpdate from "./folderUpdate";
import TrelloCardIdUpdate from "./trelloCardIdUpdate";
import toast from "react-hot-toast";
import SiteDetails from "./SiteDetails";

const ProjectDetails = (props) => {
  const state = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [rsData, setRSData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
const [view, setView] = useState("project"); 
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  // const [scurrent,setScurrent] = useState({
  //     page:1,
  //     size:10,
  // })
  const dispatch = useDispatch();
  const { id, customerId, prjCode } = useParams();
  const history = useHistory();
  const { project, orders, customer, requestForms } = bindActionCreators(
    actionCreators,
    dispatch
  );
 
  const scolumns = [
    {
      title: <div className="ap-fsm ap-fwmd">Version</div>,
      dataIndex: "version",
      key: "version",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Update Date</div>,
      dataIndex: "update",
      key: "update",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Status</div>,
      dataIndex: "status",
      key: "status",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small" className="table-btn">
          {record?.status !== "submitted" ? (
            <a
              href={`${mmhBaseURL}rs-form/${prjCode}?updatedby=admin&token=${localStorage.getItem(
                "token"
              )}`}
              target="_blank"
            >
              <Button
                type="primary"
                className="ap-btn-c"
                shape="round"
                size="middle"
              >
                <span className="ap-fesm ap-fwmd">Edit</span>
              </Button>
            </a>
          ) : (
            <a
              href={`${baseURL}internal/storage/projects/${prjCode}/rsform/${record?.version}.pdf?signed=1&projectID=${prjCode}&version=${record?.version}`}
              target="_blank"
            >
            <span   style={{cursor:'pointer'}}>
              <Button
                type="primary"
                className="ap-btn-c"
                shape="round"
                size="middle"
              >
                <span className="ap-fesm ap-fwmd">View</span>
              </Button>
              </span>
             </a> 
          )}
        </Space>
      ),
      width: "fit-content",
      fixed: "right",
    },
  ];

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Order Id</div>,
      dataIndex: "orderId",
      key: "orderId",
      render: (text) => <div className="ap-fsm">{text}</div>,
      hidden: true,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Order Code</div>,
      dataIndex: "orderCode",
      key: "orderCode",
      render: (text) => <div className="ap-fsm">{text}</div>,
      width: "200px",
      fixed: "left",
    },
    {
      title: <div className="ap-fsm ap-fwmd">Created At</div>,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Total Amount</div>,
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Status</div>,
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={
            status === "Active"
              ? "green"
              : status === "Cancelled"
              ? "red"
              : "yellow"
          }
        >
          <div className="ap-fsm">{status}</div>
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="ap-flex " style={{ alignItems: "flex-start" }}>
          <Space size="small" className="table-btn">
            <Button
              type="primary"
              className="ap-btn-c"
              shape="round"
              icon={<Icons.Details />}
              size="middle"
              onClick={() => {
                history.push(`/home/ap/order/details/${record.orderCode}`);
                localStorage.setItem("orderid", record.orderCode);
              }}
            >
              <span className="ap-fesm ap-fwmd">Show Details</span>
            </Button>
          </Space>
         
        </div>
      ),
      width: "200px",
    },
  ].filter((item) => !item.hidden);

  const callRecords = (event) => {
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };

  useEffect(() => {
    if (state.orders.orders.length > 0) {
      setData((prev) => {
        prev = [];
        state.orders.orders.map((order, orderIndex) => {
          prev.push({
            key: orderIndex,
            orderId: order.orderID,
            orderCode: order.orderCode,
            createdAt: order.orderedAt,
            totalAmount: `${order.currency?.code} ${order.orderTotalAmount}`,
            status: order.orderStatus,
          });
        });
        return prev;
      });
    }

    if (state.requestForms.forms.length > 0) {
      setRSData((prev) => {
        prev = [];
        state.requestForms.forms.map((f, fIndex) => {
          let dateObj = new Date(f?.updatedat * 1000);
          let month = dateObj.getUTCMonth() + 1; //months from 1-12
          let day = dateObj.getUTCDate();
          let year = dateObj.getUTCFullYear();
          prev.push({
            key: fIndex,
            version: f?.version,
            update: `${day}-${month}-${year}`,
            status: f?.status,
          });
        });
        return prev;
      });
    }
  }, [state]);

  useEffect(() => {
    customer(customerId);
    API.getWalletBalance(customerId)
    .then((res) => {
      console.log("currencies", res.data.balance);
      setWalletBalance(res.data.balance);
    })
    .catch((err) => {});

    project(id);
    orders(`projectID=${id}&limit=${current.size}&page=${current.page}`);
  }, [current]);

  useEffect(() => {
    requestForms(prjCode);
    localStorage.setItem("clientid", customerId);
  }, []);

  const handleRequestForm = () => {
    Swal.fire({
      title: "Request RS Form",
      icon: "info",
      html: "Are you sure you want update or request form ?",
      showCancelButton: true,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading((prev) => !prev);
        API.requestForm(state.project.project?.projectCode)
          .then((res) => {
            requestForms(prjCode);
            setLoading((prev) => !prev);
            notification.success({
              message: "Request sent",
              description: "Your request is successfully sent",
              duration: 2,
              placement: "bottomRight",
            });
          })
          .catch((err) => {
            setLoading((prev) => !prev);
            let desc = "Internal Error";
            let msg = "Some error(s) is/are occured";
            if (err.response.status === 403) {
              desc = "You have not access to request / update RS form";
              msg = "Access denied";
            }
            notification.error({
              message: msg,
              description: desc,
              duration: 2,
              placement: "bottomRight",
            });
          });
      }
    });
  };
  // const SyncTrello = () => {
  //     console.log(state.project.project?.projectCode);

  //      API.syncTrello(state.project.project?.projectCode).then((res)=>{
  //        console.log(res);
  //        toast.success("Project Data Sync Completed Sucessfully",{
  //          position: "top-right",
  //      });
  //      project(id);

  //      }).catch((err)=>{
  //          toast.error("Error! ",err, {
  //              position: "top-right",
  //          });

  //      })
  //  }

  const sendReminder = () => {
    Swal.fire({
      title: "Send RS Form Reminder",
      icon: "info",
      html: "Are you sure you want send reminder?",
      showCancelButton: true,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading((prev) => !prev);

        API.rsReminder(state.project.project?.projectCode)
          .then((res) => {
            setLoading((prev) => !prev);
            notification.success({
              message: "Reminder sent",
              description: "Reminder is successfully sent",
              duration: 2,
              placement: "bottomRight",
            });
          })
          .catch((err) => {
            setLoading((prev) => !prev);
            let desc = "There is an error from server";
            let msg = "Not send";
            if (err.response.status === 403) {
              desc = "You have not access to send reminder";
              msg = "Access denied";
            }
            notification.error({
              message: msg,
              description: desc,
              duration: 2,
              placement: "bottomRight",
            });
          });
      }
    });
  };
    const renderProjectDetails = () => (
      <Card size="small" style={{ width: "98%" }}>
        <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Project Name
          </div>
          <div className="ap-flex ap-flex-center-center ap-fsm">
            {state.project.project?.name}
          </div>
        </div>
        <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Project Code
          </div>
          <div className="ap-flex ap-flex-center-center ap-fsm">
            {state.project.project?.projectCode}
          </div>
        </div>
        <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Project Category
          </div>
          <div className="ap-flex ap-flex-center-center ap-fsm">
            {state.project.project?.category}
          </div>
        </div>
        <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Project Type
          </div>
          <div className="ap-flex ap-flex-center-center ap-fsm">
            {state.project.project?.type}
          </div>
        </div>
        {state.project.project?.category !== "interior" && (
          <>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Width
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.width}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Depth
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.length}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Direction
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.direction}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Floor
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.floor}
              </div>
            </div>
            <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
              <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                Area
              </div>
              <div className="ap-flex ap-flex-center-center ap-fsm">
                {state.project.project?.buildArea}
              </div>
            </div>
          </>
        )}
        <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Created By
          </div>
          <div className="ap-flex ap-flex-center-center ap-fsm">
            {state.project.project?.createdBy?.name}
          </div>
        </div>
        <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Assigned To
          </div>
          <div className="ap-flex ap-flex-center-center ap-fsm">
            {state.project.project?.assignedTo?.name}
          </div>
        </div>
        <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
          <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
            Status
          </div>
          <div className="ap-flex ap-flex-center-center ap-fsm">
            {state.project.project?.status}
          </div>
        </div>
      </Card>
    );


  return !state.project.loading && state.project.project !== null ? (
    <div className="ap-flex ap-flex-center-center ap-w100 prj-details">
      <div className="ap-flex ap-w100">
        <Card
          size="small"
          style={{ width: "100%" }}
          title={
            <div className="ap-flex ap-flex-space-bw ap-w100 ap-fmd ap-fwmd">
              <div className="ap-flex  ap-w100 ap-fmd ap-fwmd">
                <Button
                  // type={view === "project" ? "primary" : "default"}
                  onClick={() => setView("project")}
                  className={
                    view === "project"
                      ? "submit_right1"
                      : "submit_right_outline"
                  }
                >
                  Project Details
                </Button>
                <Button
                  // type={view === "site" ? "primary" : "default"}
                  onClick={() => setView("site")}
                  className={
                    view === "site" ? "submit_right1" : "submit_right_outline"
                  }
                >
                  Site Details
                </Button>
              </div>
              <span
                className="ap-fesm ap-fwmd"
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => history.goBack()}
              >
                {"<< go back"}
              </span>
            </div>
          }
        >
          <div className="ap-flex ap-w100">
            <div className="ap-flex  " style={{ width: "65%" }}>
              {view === "project" && renderProjectDetails()}

              {view === "site" && (
                <SiteDetails siteDetail={state.project.project?.site} />
              )}
            </div>
            <div className="ap-flex ap-flex-end " style={{ width: "35%" }}>
              <CustomerDetails
                editFunction={() =>
                  history.push(
                    `/home/ap/client/details/${state.customer.customer?.Id}/edit`
                  )
                }
                showWallet={true}
                walletBalance={walletBalance}
              />
            </div>
          </div>
          <div className="ap-flex ap-flex-end  ap-p10">
            {/* {state.project?.project?.trelloSyncDatetime == null &&
                        <Button 
                                    loading={loading} 
                                    type="primary" 
                                    className="ap-btn-c " 
                                    shape="round"  
                                    size="middle" 
                                    style={{margin:"0 2px"}}
                                    onClick={()=>{SyncTrello()}}
                                >
                                    <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">Sync Trello</span> 
                                </Button>} */}

            <Button
              loading={loading}
              type="primary"
              className="ap-btn-c"
              style={{ margin: "0 2px" }}
              onClick={() => {
                localStorage.setItem("isOrder", false);
                localStorage.setItem("projectId", id);
                localStorage.setItem("projectCode", prjCode);
                localStorage.setItem(
                  "orderCode",
                  state.project.project?.projectCode
                );
                localStorage.setItem(
                  "orderid",
                  state.orders?.orders?.orderCode
                );
                localStorage.setItem("istrelloList", false);

                //console.log('orderCode',state.project.project?.projectCode)
                history.push(
                  "/home/ap/trello/details/" +
                    state.project.project?.projectCode
                );
              }}
              shape="round"
              size="middle"
            >
              <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">
                Go To Design PMS Details
              </span>
            </Button>
          </div>
        </Card>
      </div>
      {/* <div
          className="ap-flex ap-flex-space-bw ap-w100"
          style={{ padding: "15px 5px" }}
        >
        <FolderUpdate prjCode ={prjCode}/>
        <TrelloCardIdUpdate prjCode ={prjCode} />
        </div> */}

      <div
        className="ap-flex ap-flex-center-center ap-w100"
        style={{ marginTop: "20px" }}
      >
        <Card
          size="small"
          style={{ width: "100%" }}
          title={
            <div className="ap-w100 ap-flex ap-flex-space-bw ap-fmd ap-fwmd">
              Orders
              <div>
                <Button
                  loading={loading}
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  className="ap-btn-c"
                  shape="round"
                  icon={<Icons.Plus />}
                  size="middle"
                  onClick={() => {
                    localStorage.setItem(
                      "customerNo",
                      state.customer.customer?.phoneNumber
                    );
                    history.push(
                      `/home/ap/custom/order/create/${state.customer.customer?.Id}/${state.project.project?.projectCode}/${state.project.project?.category}/${state.project.project?.type}`
                    );
                  }}
                >
                  <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">
                    Create Custom Order
                  </span>
                </Button>
                <Button
                  loading={loading}
                  disabled={
                    state.project.project?.width === 0 ||
                    state.project.project?.length === 0
                  }
                  type="primary"
                  className="ap-btn-c"
                  shape="round"
                  style={{ marginLeft: "10px" }}
                  icon={<Icons.Plus />}
                  size="middle"
                  onClick={() => {
                    history.push(`/home/ap/draft/order/create/${id}`);
                  }}
                >
                  <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">
                    Create Draft Order
                  </span>
                </Button>
              </div>
            </div>
          }
        >
          {!state.orders.loading ? (
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 1000 }}
              pagination={{
                current: current.page,
                pageSize: current.size,
                position: ["bottomLeft"],
                total: state.orders.totalRecords,
                onChange: (data, size) =>
                  callRecords({
                    type: "page",
                    payload: { page: data, size: size },
                  }),
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          ) : (
            <div
              className="ap-flex ap-w100 ap-flex-center-center"
              style={{ height: "300px" }}
            >
              <Spin tip="Fetching Records....." />
            </div>
          )}
        </Card>
      </div>

      <div
        className="ap-flex ap-flex-center-center ap-w100"
        style={{ marginTop: "20px" }}
      >
        <Card
          size="small"
          style={{ width: "100%" }}
          title={
            <div className="ap-w100 ap-flex ap-flex-space-bw ap-fmd ap-fwmd">
              RS Forms
              <div className="ap-flex">
                <Button
                  loading={loading}
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  className="ap-btn-c"
                  shape="round"
                  size="middle"
                  onClick={() => {
                    sendReminder();
                  }}
                >
                  <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">
                    Send RS Form Reminder
                  </span>
                </Button>
                <Button
                  loading={loading}
                  style={{ marginLeft: "5px" }}
                  type="primary"
                  className="ap-btn-c"
                  shape="round"
                  icon={<Icons.Plus />}
                  size="middle"
                  onClick={() => handleRequestForm()}
                >
                  <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">
                    Request/Update RS Form
                  </span>
                </Button>
              </div>
            </div>
          }
        >
          {!state.requestForms.loading ? (
            <Table
              columns={scolumns}
              dataSource={rsData}
              scroll={{ x: 1000 }}
              // pagination={
              //     {
              //         current:scurrent.page,
              //         pageSize:scurrent.size,
              //         position:["bottomLeft"],
              //         total:state.orders.totalRecords,
              //         onChange:(data,size)=>callRecords({type:"page",payload:{page:data,size:size}}),
              //         showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
              //     }
              // }
            />
          ) : (
            <div
              className="ap-flex ap-w100 ap-flex-center-center"
              style={{ height: "300px" }}
            >
              <Spin tip="Fetching Records....." />
            </div>
          )}
        </Card>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default ProjectDetails;
