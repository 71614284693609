import React, { useState, useEffect } from "react";
import "../../../Styles/Master.scss";
import "./PartnersList.scss";
import {
  Card,
  Input,
  Spin,
  Button,
  Table,
  Space,
  Select,
  DatePicker,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../Components";
import { API } from "../../../Services";
import { Tag } from "@chakra-ui/react";
import moment from "moment";

const LinkRequestList = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    value: "",
    key: "",
  });
    const { RangePicker } = DatePicker;

  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const [mainLoad, setMainLoad] = useState(true);
  const { customerLinkRequestList } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const { Option } = Select;

  useEffect(() => {
    setTimeout(() => {
      console.log(state.customerLinkRequestList.customers);
      setMainLoad((prev) => !prev);
    }, 3000);
  }, []);

  const columns = [
    {
      title: <div className="ap-fsm ap-fwmd">Partner Id</div>,
      dataIndex: "partnerId",
      key: "partnerId",
      render: (text) => <div className="ap-fsm">{text}</div>,
      hidden: true,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Partner Name</div>,
      dataIndex: "partnerName",
      key: "partnerName",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Customer Id</div>,
      dataIndex: "customer_id",
      key: "customer_id",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Customer Name</div>,
      dataIndex: "customer_name",
      key: "customer_name",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    {
      title: <div className="ap-fsm ap-fwmd">Customer Phone No.</div>,
      dataIndex: "contactNo",
      key: "contactNo",
      render: (text) => <div className="ap-fsm">{text}</div>,
    },
    // {
    //   title: <div className="ap-fsm ap-fwmd">New Customer</div>,
    //   dataIndex: "is_new_customer",
    //   key: "is_new_customer",
    //   render: (text) => <div className="ap-fsm">{text ? "Yes" : "No"}</div>,
    // },
    // {
    //   title: <div className="ap-fsm ap-fwmd">Response</div>,
    //   dataIndex: "response",
    //   key: "response",
    //   render: (text) => <div className="ap-fsm">{text}</div>,
    // },

    {
      title: <div className="ap-fsm ap-fwmd">Created At</div>,
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <div className="ap-fsm">
          {" "}
          {moment.unix(text).format("MMM D, YYYY [at] h:mm A z")}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">Updated At</div>,
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => (
        <div className="ap-fsm">
          {" "}
          {moment.unix(text).format("MMM D, YYYY [at] h:mm A z")}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">Declined Date</div>,
      dataIndex: "declined_date",
      key: "declined_date",
      render: (text) => (
        <div className="ap-fsm">
          {text ? moment.unix(text).format("MMM D, YYYY [at] h:mm A z") : "NA"}
        </div>
      ),
    },
    {
      title: <div className="ap-fsm ap-fwmd">Status</div>,
      dataIndex: "status",
      key: "status",

      render: (text) => (
        <Tag
          color="#fff"
          backgroundColor={
            text === "accepted"
              ? "var(--primary-green)"
              : text === "pending"
              ? "#E1C16E"
              : "#D70040"
          }
          p="0.5rem"
        >
          <div className="ap-fsm">{text}</div>
        </Tag>
      ),
    },
  ].filter((item) => !item.hidden);

  const setFormData = (event) => {
    setData((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      if (event.target.name == "from") {
        prev[`${event.target.name}`] = moment(event.target.value._d)?.format(
          "YYYY-MM-DD"
        );
      } else if (event.target.name == "to") {
        prev[`${event.target.name}`] = moment(event.target.value._d)?.format(
          "YYYY-MM-DD"
        );
      } else {
        prev[`${event.target.name}`] = event.target;
      }

      return prev;
    });
  };

  useEffect(() => {
    if (state.customerLinkRequestList.customers.length > 0) {
      console.log(state.customerLinkRequestList.customers);
      setTableData((prev) => {
        prev = [];
        state.customerLinkRequestList.customers.map(
          (customer, customerIndex) => {
            prev.push({
              key: customerIndex,
              partnerId: customer.partner_id,
              partnerName: customer.partner_name,
              customer_name: customer.customer_name || "NA",
              contactNo: customer.customer_mobile,
              customer_id: customer.customer_id || "NA",
              status: customer.status || "NA",
              response: customer.response || "NA",
              is_new_customer: customer.is_new_customer || "NA",
              created_at: customer.created_at || "NA",
              updated_at: customer.updated_at || "NA",
              declined_date:
                customer.declined_date == null
                  ? ""
                  : customer.declined_date || "NA",
            });
          }
        );
        return prev;
      });
    } else {
      setTableData((prev) => {
        console.log(prev);
      });
    }
  }, [state]);

  const callRecords = (event) => {
    console.log(event);
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };

  useEffect(() => {
    console.log("formdata",data)
    customerLinkRequestList(
      `limit=${current.size}&page=${current.page}
    &status=${data?.status ? data?.status?.value : ""}&to=${data?.to ? data?.to : ""}&from=${
        data?.from ? data?.from : ""
      }`
    );
  }, [current]);

  return (
    <>
      {" "}
      {state?.customerLinkRequestList?.msg !== "" ? (
        <>
          <div
            style={{
              fontSize: "large",
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            <h3>Unauthorized</h3>
          </div>
        </>
      ) : (
        <div className="ap-flex ap-flex-center-center ap-w100">
          <div className="ap-flex ap-w100">
            <Card style={{ width: "100%" }}>
              <div className="ap-flex ap-w100 ">
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Status</div>
                  <Select
                    size="large"
                    showSearch
                    //   onSearch={(data)=>customersList(`q=${data}`)}
                    labelInValue
                    placeholder="Select status"
                    style={{ width: "100%" }}
                    value={data?.status}
                    //loading={state.customers.loading}
                    onChange={(data) => {
                      console.log(data);
                      setFormData({ target: { name: "status", ...data } });
                    }}
                    notFoundContent={
                      <div className="ap-w100 ap-flex ap-flex-center">
                        <Spin size="small" />
                      </div>
                    }
                    filterOption={false}
                  >
                    <Option value={"pending"} index={1}>
                      pending
                    </Option>
                    <Option value={"accepted"} index={2}>
                      accepted
                    </Option>
                    <Option value={"declined"} index={3}>
                      declined
                    </Option>
                  </Select>
                </div>
                <div
                  className="ap-flex ap-flex-center-center ap-w33"
                  style={{ margin: "0 10px" }}
                >
                  <div className="ap-flex ap-w100 ap-fsm ap-fwmd">
                    Filter By Date Range
                  </div>
                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                    }}
                  >
                    <RangePicker
                      value={
                        data?.from ? [moment(data?.from), moment(data?.to)] : ""
                      }
                      onChange={(momentData, Date) => {
                        setFormData({
                          target: { name: "from", value: momentData[0] },
                        });
                        setFormData({
                          target: { name: "to", value: momentData[1] },
                        });
                      }}
                      //format={dateFormat}
                    />
                  </span>
                </div>
              </div>

              <div className="ap-flex ap-w100" style={{ marginTop: "10px" }}>
                <div className="ap-flex ap-flex-center-center search-btn">
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={
                      state.customerLinkRequestList.loading ? <Spin /> : false
                    }
                    icon={<Icons.Search />}
                    size="middle"
                    onClick={() =>
                      callRecords({ payload: { page: 1, size: 10 } })
                    }
                  >
                    <span className="ap-fesm ap-fwmd">Search</span>
                  </Button>
                </div>
                <div
                  className="ap-flex ap-flex-center-center search-btn"
                  style={{ marginLeft: "10px" }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    className="ap-btn-c"
                    loading={
                      state.customerLinkRequestList.loading ? <Spin /> : false
                    }
                    icon={<Icons.Sweep />}
                    size="middle"
                    onClick={() => {
                      setData({ key: "", value: "" });
                      customerLinkRequestList(`limit=10&page=1&q=`);
                    }}
                  >
                    <span className="ap-fesm ap-fwmd">Clear</span>
                  </Button>
                </div>
              </div>
            </Card>
          </div>

          {!state.customerLinkRequestList.loading ? (
            <div className="ap-flex ap-w100" style={{ marginTop: "20px" }}>
              <Card style={{ width: "100%" }}>
                <div
                  className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record"
                  onClick={() => {
                    setData({ key: "", value: "" });
                    customerLinkRequestList(`limit=10&offset=0&q=`);
                  }}
                >
                  Reset Records
                </div>

                <Table
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ x: 900 }}
                  pagination={{
                    current: current.page,
                    pageSize: current.size,
                    position: ["bottomLeft"],
                    total: state.customerLinkRequestList.totalRecords,
                    onChange: (data, size) =>
                      callRecords({
                        type: "page",
                        payload: { page: data, size: size },
                      }),
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />
              </Card>
            </div>
          ) : (
            <div
              className="ap-flex ap-w100 ap-flex-center-center"
              style={{ height: "300px" }}
            >
              <Spin tip="Fetching Records....." />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LinkRequestList;
