import { API } from "../../Services";

export const trelloCardInfo = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_TRELLO_CARD_INFO",
        })
    
        API.trelloCardInfo(id).then((res)=>{
            let data = JSON.parse(JSON.stringify(res)).data.project
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_TRELLO_CARD_INFO",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_TRELLO_CARD_INFO",
            })
        })
    }
}