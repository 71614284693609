import React , { useEffect , useState } from "react";
import "../../Styles/Master.scss";
import "./WalletLedger.scss";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import {useParams,useHistory} from "react-router-dom";
import { Loader } from "../../Components";
import { Card, Select,Spin,Button,Table, Tag, Space,Input  } from 'antd';
import { Icons } from "../../Utils";
import { API } from "../../Services";



const txnTypes = [
    {label:"Debit",value:"debit"},
    {label:"Credit",value:"credit"},
]

const WalletLedger = (props) =>{

    const {Option} = Select
    const state = useSelector(state=>state)
    const dispatch = useDispatch()
    const history = useHistory()
    const { walletLedger } = bindActionCreators(actionCreators,dispatch)
    const { id } = useParams();
    const [ mainLoad , setMainLoad] = useState(true);
    const [data,setData] = useState({
    })
    const [tableData,setTableData] = useState([]);
    const [current,setCurrent] =useState({
        page:1,
        size:10,
    })

    useEffect(()=>{
        setTimeout(()=>{
            setMainLoad((prev)=>!prev)
        },3000)
    },[])

    useEffect(()=>{
        walletLedger(`limit=${current?.size}&page=${current?.page}&customerID=${id}${data.txnStartDate ?`&txnStartDate=${data?.txnStartDate}`:'&txnStartDate='}${data.txnEndDate ?`&txnEndDate=${data?.txnEndDate}`:'&txnEndDate='}${data.txnType ?`&txnType=${data?.txnType?.value}`:'&txnType='}${data.txnCode ?`&txnCode=${data?.txnCode}`:'&txnCode='}`)
    },[current])

    useEffect(()=>{
        if(state.walletLedger.ledgers.length > 0){
            setTableData((prev)=>{
                prev = []
                state.walletLedger.ledgers.map((ledger,ledgerIndex)=>{
                    prev.push({
                        key:ledgerIndex,
                        txnCode:ledger.txnCode,
                        txnType:ledger.txnType,
                        txnDate:ledger.txnDate,
                        referenceCode:ledger.referenceCode,
                        amount:ledger.amount,
                        addedBy:ledger.addedBy?.name,
                    })
                })
                return prev
            })
        }else{
            setTableData([])
        }
    },[state])

    useEffect(()=>{
        console.log(data)
    },[data])

    const setFormData =(event)=>{
        setData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev[`${event.target.name}`] = event.target.value
            return prev
        })
    }

    const callRecords = (event) =>{
        setCurrent((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.page = prev.size === event.payload.size ? event.payload.page:1
            prev.size = event.payload.size
            return prev
        })
    }

    const columns = [
        {
            title:<div className="ap-fsm ap-fwmd">Transaction Code</div>,
            dataIndex:"txnCode",
            key:"txnCode",
            render:text=><div className="ap-fsm">{text}</div>,
            width:"fit-content"
        },
        
        {
            title:<div className="ap-fsm ap-fwmd">Transaction Type</div>,
            dataIndex:"txnType",
            key:"txnType",
            render:text=><div className="ap-fsm">{text}</div>,
            render:status=>(
                <Tag color={status === "credit" ? "var(--primary-green)":"red"}>
                    <div className="ap-fsm">{status}</div>
                </Tag>
            ),
            width:"fit-content"
        },
        {
            title:<div className="ap-fsm ap-fwmd">Transaction Date</div>,
            dataIndex:"txnDate",
            key:"txnDate",
            render:text=><div className="ap-fsm">{text}</div>,
            width:"fit-content"
        },
        {
            title:<div className="ap-fsm ap-fwmd">Reference</div>,
            dataIndex:"referenceCode",
            key:"referenceCode",
            render:text=><div className="ap-fsm">{text}</div>,
            width:"fit-content"
        },
        {
            title:<div className="ap-fsm ap-fwmd">Amount</div>,
            dataIndex:"amount",
            key:"amount",
            render:text=><div className="ap-fsm">{text}</div>,
            width:"fit-content"
        },
        {
            title:<div className="ap-fsm ap-fwmd">Added By</div>,
            dataIndex:"addedBy",
            key:"addedBy",
            render:text=><div className="ap-fsm">{text}</div>,
            width:"fit-content"
        },
    ]

    return <>
        { mainLoad ? <Loader />:<div className="ap-flex ap-flex-center-center ap-w100">
            <div className="ap-flex ap-w100">
                <Card
                    size="small" 
                    title={<div className="ap-flex ap-flex-end ap-fesm ap-fwmd" style={{color:"blue",cursor:"pointer"}} onClick={()=>history.goBack()}>
                        {"<< go back"}
                    </div>}
                    style={{width:"100%"}}>
                    <div className="ap-flex ap-w100 ap-flex-space-bw ap-flex-wrap">
                        <div className="ap-flex ap-flex-center-center ap-w50" style={{padding:"5px"}}>
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Transaction Code</div>
                            <Input 
                                size="large"
                                style={{width:"100%"}}
                                placeholder="Enter Transaction Code"
                                value={data?.txnCode}
                                name="txnCode"
                                onChange={(e)=>setFormData(e)}
                            />
                        </div>

                        <div className="ap-flex ap-flex-center-center ap-w50" style={{padding:"5px"}}>
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Transaction Type</div>
                            <Select 
                                size="large" 
                                value={data?.txnType}
                                labelInValue placeholder="Select Transaction Type" 
                                style={{ width: "100%" }} 
                                onChange={(data)=>setFormData({target:{name:"txnType",value:data}})}
                            >
                                {txnTypes.map((txn,txnIndex)=>(
                                    <Option value={txn.value} index={txnIndex}>{txn.label}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <div className="ap-flex ap-w100 ap-flex-space-bw ap-flex-wrap">
                        <div className="ap-flex ap-flex-center-center ap-w50" style={{padding:"5px"}}>
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Transaction Start Date</div>
                            <Input 
                                type="date"
                                size="large"
                                style={{width:"100%"}}
                                placeholder="Enter Transaction Start Date"
                                value={data?.txnStartDate}
                                name="txnStartDate"
                                onChange={(e)=>setFormData(e)}
                            />
                        </div>

                        <div className="ap-flex ap-flex-center-center ap-w50" style={{padding:"5px"}}>
                            <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Transaction End Date</div>
                            <Input 
                                type="date"
                                size="large"
                                style={{width:"100%"}}
                                placeholder="Enter Transaction End Date"
                                value={data?.txnEndDate}
                                name="txnEndDate"
                                onChange={(e)=>setFormData(e)}
                            />
                        </div>
                    </div>

                    <div className="ap-flex ap-w100" style={{marginTop:"10px"}}>
                        <div className="ap-flex ap-flex-center-center search-btn">
                            <Button type="primary" className="ap-btn-c" shape="round" loading={state.walletLedger.loading ?<Spin/>:false} disabled={Object.values(data).length===0} icon={<Icons.Search />} size="middle" onClick={()=>callRecords({payload:{page:1,size:10}})}>
                                <span className="ap-fesm ap-fwmd">Search</span>
                            </Button>
                        </div>
                        <div className="ap-flex ap-flex-center-center search-btn" style={{marginLeft:"10px"}}>
                            <Button type="primary" className="ap-btn-c" shape="round"  loading={state.walletLedger.loading ?<Spin/>:false} disabled={Object.values(data).length === 0} icon={<Icons.Sweep />} size="middle" onClick={()=>{
                                setData({})
                                walletLedger(`limit=10&page=1&customerID=${id}`)      
                            }}>
                                <span className="ap-fesm ap-fwmd">Clear</span> 
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>

            {!state.walletLedger.loading ? 
                <div className="ap-flex ap-w100" style={{marginTop:"20px"}}>
                    <Card style={{width:"100%"}}>
                        <div 
                            className="ap-flex ap-w100 ap-fsm ap-fwmd ap-flex-end rst-record" 
                            onClick={()=>{
                                setData({})
                                walletLedger(`limit=10&page=1&customerID=${id}`)
                            }}
                        >Reset Records</div>

                        <Table 
                            columns={columns} 
                            dataSource={tableData} 
                            scroll={{ x: 600 }} 
                            pagination={
                                {
                                    current:current.page,
                                    pageSize:current.size,
                                    position:["bottomLeft"],
                                    total:state.walletLedger.totalRecords,
                                    onChange:(data,size)=>callRecords({type:"page",payload:{page:data,size:size}}),
                                    showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                }
                            }
                        />
                    </Card>
    </div>:
        <div className="ap-flex ap-w100 ap-flex-center-center" style={{height:"300px"}}>
            <Spin tip="Fetching Records....."/>
        </div>
    }
        </div>}
    </>
}

export default WalletLedger;
