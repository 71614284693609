import React,{ useEffect, useState } from "react";
import "../../../Styles/Master.scss";
import "./ProjectsList.scss";
import { Card, Select,Spin,Button,Table, Tag, Space,Input  } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { Icons } from "../../../Utils";
import { useHistory } from "react-router-dom";

const ProjectsList = (props) =>{

    const { Option } = Select;
    const state = useSelector((state)=>state)
    const dispatch = useDispatch();
    const [data,setData] = useState({});
    const history = useHistory();
    const [tableData,setTableData] = useState([]);
    const [current,setCurrent] =useState({
        page:1,
        size:10,
    })
    const { customersList,projectsList,admins} = bindActionCreators(actionCreators,dispatch)

    const columns = [
       

        {
            title:<div className="ap-fsm ap-fwmd">Project Id</div>,
            dataIndex:"projectId",
            key:"projectId",
            render:text=><div className="ap-fsm">{text}</div>,
            hidden:true

        },
        {
            title:<div className="ap-fsm ap-fwmd">Project Name</div>,
            dataIndex:"projectName",
            key:"projectName",
            render:text=><div className="ap-fsm">{text}</div>,
            width:"300px",
            fixed:"left",

        },
        {
            title:<div className="ap-fsm ap-fwmd">Project Code</div>,
            dataIndex:"projectCode",
            key:"projectCode",
            render:text=><div className="ap-fsm">{text}</div>,
           
        },
        {
            title:<div className="ap-fsm ap-fwmd">Customer Name</div>,
            dataIndex:"customerName",
            key:"customerName",
            render:text=><div className="ap-fsm">{text}</div>

        },
        {
            title:<div className="ap-fsm ap-fwmd">Created By</div>,
            dataIndex:"createdBy",
            key:"createdBy",
            render:text=><div className="ap-fsm">{text}</div>

        },
        {
            title:<div className="ap-fsm ap-fwmd">Assigned To</div>,
            dataIndex:"assignedTo",
            key:"assignedTo",
            render:text=><div className="ap-fsm">{text}</div>

        },
        {
            title:<div className="ap-fsm ap-fwmd">Mode</div>,
            dataIndex:"mode",
            key:"mode",
            render:text=><div className="ap-fsm">{text}</div>

        },
        {
            title:<div className="ap-fsm ap-fwmd">Status</div>,
            dataIndex:"status",
            key:"status",
            render:status=>(
                <Tag color={status === "Active" ? "var(--primary-green)":"yellow"}>
                    <div className="ap-fsm">{status}</div>
                </Tag>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="small" className="table-btn">
                <Button type="primary" className="ap-btn-c" shape="round"  icon={<Icons.Details />} size="middle" onClick={()=>history.push(`/home/ap/project/details/${record.projectId}/${record.customerId}/${record?.projectCode}`)}>
                    <span className="ap-fesm ap-fwmd">Show Details</span> 
                </Button>
              </Space>
            ),
            width:"fit-content",
            fixed:"right",
          },
    ].filter(item => !item.hidden);
    
    const setFormData =(event)=>{
        setData((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            if(["projectCode"].includes(event.target.name)){
                prev[`${event.target.name}`] = event.target.value
            }else{
                prev[`${event.target.name}`] = event.target
            }
            return prev
        })
    }

    useEffect(()=>{
        if(state.projectsList.projectsList.length > 0){
            setTableData((prev)=>{
                prev = []
                state.projectsList.projectsList.map((project,projectIndex)=>{
                    prev.push({
                        key:projectIndex,
                        projectId:project.ID,
                        projectCode:project.projectCode,
                        projectName:project.name,
                        customerName:project.customer?.name,
                        createdBy:project.createdBy?.name,
                        assignedTo:project.assignedTo?.name,
                        mode:project?.mode,
                        status:project.status,
                        customerId:project.customer?.ID,
                        projectCode:project?.projectCode
                    })
                })
                return prev
            })
        }else{
            setTableData([])
        }
    },[state])

    const callRecords = (event) =>{
        setCurrent((prev)=>{
            prev = JSON.parse(JSON.stringify(prev))
            prev.page = prev.size === event.payload.size ? event.payload.page:1
            prev.size = event.payload.size
            return prev
        })
    }

useEffect(() => {
          admins();

}, [])

    useEffect(()=>{
        projectsList(`limit=${current.size}&page=${current.page}&projectCode=${data.projectCode ? data.projectCode :""}&createdBy=${data.createdBy ? data.createdBy.value :""}&assignedTo=${data.assignee?data.assignee.value:""}&customerID=${data.customer ? data.customer.value :""}&mode=${data.mode ? data.mode.value :""}&status=${data.status ? data.status.value :""}`)
    },[current])

    useEffect(()=>{
        console.log(data)
    },[data?.customer])


    return <div className="ap-flex ap-flex-center-center ap-w100">
                <div className="ap-flex ap-w100">
                    <Card style={{width:"100%"}}>
                        <div className="ap-flex ap-w100">
                            <div className="ap-flex ap-flex-center-center ap-w33">
                                <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Project Code</div>
                                <Input 
                                    size="large"
                                    style={{width:"100%"}}
                                    placeholder="Enter Project Code"
                                    value={data?.projectCode}
                                    name="projectCode"
                                    onChange={(e)=>setFormData(e)}
                                />
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-w33"  style={{margin:"0 10px"}}>
                                <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Customers</div>
                                <Select 
                                    size="large" 
                                    showSearch
                                    onSearch={(data)=>customersList(`q=${data}`)} 
                                    labelInValue placeholder="Select Customer" 
                                    style={{ width: "100%" }} 
                                    value={data?.customer}
                                    loading={state.customers.loading} 
                                    onChange={(data)=>setFormData({target:{name:"customer",...data}})}
                                    notFoundContent={
                                        state.customers.loading && 
                                        <div className="ap-w100 ap-flex ap-flex-center">
                                            <Spin size="small"/>
                                        </div>
                                    }
                                    filterOption={false}
                                >
                                    {state.customers.customers.map((customer,customerIndex)=>(
                                        <Option value={customer.value} index={customerIndex}>{customer.label}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-w33" style={{margin:"0 10px"}}>
                                <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Created By</div>
                                    <Select 
                                        showSearch
                                        size="large" 
                                        value={data?.createdBy}
                                        labelInValue placeholder="Select Manager" 
                                        style={{ width: "100%" }} 
                                        loading={state.admins.loading}
                                        filterOption={(input, option) =>  
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                            
                                          }
                                        onChange={(data)=>{setFormData({target:{name:"createdBy",...data}});console.log(data)}}
                                    >
                                        {state.admins.admins.map((admin,adminIndex)=>(
                                            <Option value={admin.value} key={admin.value}>{admin.label}</Option>
                                        ))}
                                    </Select>
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-w33">
                                <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Assigned To</div>
                                    <Select 
                                        size="large"
                                        showSearch 
                                        value={data?.assignee}
                                        labelInValue placeholder="Select Assignee" 
                                        style={{ width: "100%" }} 
                                        loading={state.admins.loading}
                                        filterOption={(input, option) =>  
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                            
                                          }
                                        onChange={(data)=>setFormData({target:{name:"assignee",...data}})}
                                    >
                                        {state.admins.admins.map((admin,adminIndex)=>(
                                            <Option value={admin.value} key={adminIndex}>{admin.label}</Option>
                                        ))}
                                    </Select>
                            </div>
                        </div>

                        <div className="ap-flex ap-w100">
                            <div className="ap-flex ap-flex-center-center ap-w33">
                                <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Mode</div>
                                <Select 
                                    size="large"
                                    showSearch 
                                    value={data?.mode}
                                    labelInValue placeholder="Select Mode" 
                                    style={{ width: "100%" }} 
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                    }
                                    onChange={(data)=>setFormData({target:{name:"mode",...data}})}
                                >
                                    <Option value={1}>Admin</Option>
                                    <Option value={0}>Customer</Option>
                                </Select>
                            </div>
                            <div className="ap-flex ap-flex-center-center ap-w33" style={{margin:"0 10px"}}>
                                <div className="ap-flex ap-w100 ap-fsm ap-fwmd">Status</div>
                                <Select 
                                    size="large"
                                    showSearch 
                                    value={data?.status}
                                    labelInValue placeholder="Select" 
                                    style={{ width: "100%" }} 
                                    filterOption={(input, option) =>  
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                    }
                                    onChange={(data)=>setFormData({target:{name:"status",...data}})}
                                >
                                    <Option value={2}>Active</Option>
                                    <Option value={1}>Pending</Option>
                                 
                                </Select>
                            </div>
                        </div>
                        

                        <div className="ap-flex ap-w100" style={{marginTop:"10px"}}>
                            <div className="ap-flex ap-flex-center-center search-btn">
                                <Button type="primary" className="ap-btn-c" shape="round" loading={state.projectsList.loading ?<Spin/>:false} disabled={Object.values(data).length===0} icon={<Icons.Search />} size="middle" onClick={()=>callRecords({payload:{page:1,size:10}})}>
                                    <span className="ap-fesm ap-fwmd">Search</span>
                                </Button>
                            </div>
                            <div className="ap-flex ap-flex-center-center search-btn" style={{marginLeft:"10px"}}>
                                <Button type="primary" shape="round"  className="ap-btn-c" loading={state.projectsList.loading ?<Spin/>:false} disabled={Object.values(data).length === 0} icon={<Icons.Sweep />} size="middle" onClick={()=>{
                                    setData({})
                                    setCurrent({
                                        page:1,
                                        size:10,
                                    })
                                    projectsList(`limit=10&page=1`)      
                                }}>
                                    <span className="ap-fesm ap-fwmd">Clear</span> 
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>

                {!state.projectsList.loading ? <div className="ap-flex ap-w100" style={{marginTop:"20px"}}>
                <Card style={{width:"100%"}}
                        title={<div className="ap-w100 ap-flex ap-flex-end ap-fmd ap-fwmd">
                        <Button loading={state.projectsList.loading} type="primary" className="ap-btn-c" shape="round"  icon={<Icons.Plus />} size="middle" onClick={()=>{history.push("/home/ap/project/create/for/co")}}>
                            <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">Create New Project</span> 
                        </Button>
                        </div>}
                    >
                        <Table 
                            columns={columns} 
                            dataSource={tableData} 
                            scroll={{ x: 1300 }} 
                            pagination={
                                {
                                    current:current.page,
                                    pageSize:current.size,
                                    position:["bottomLeft"],
                                    total:state.projectsList.totalRecords,
                                    onChange:(data,size)=>callRecords({type:"page",payload:{page:data,size:size}}),
                                    showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                }
                            }
                        />
                    </Card>
                </div>:
                    <div className="ap-flex ap-w100 ap-flex-center-center" style={{height:"300px"}}>
                        <Spin tip="Fetching Records....."/>
                    </div>
                }
            </div>
}

export default ProjectsList;
