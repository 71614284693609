import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Card, Input, Comment, Spin, Tooltip } from "antd";
import TrelloModelViews from "./trelloModelViews";
import FTIcon from "react-file-type-icons";
import { Icons } from "../../Utils";
import { Loader } from "../../Components";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../Services";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import moment from "moment";

import { Button, IconButton, Collapse, Box } from "@chakra-ui/react";
import {
  AttachmentCont,
  CommentDiv,
  DeleteBtn,
  DescriptionCont,
  MemberDiv,
  ScrollButton,
  TButton,
  TCardHeding,
  TrelloComments,
  UserAvtar,
} from "./trelloStyle";
import "moment-timezone";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import TrelloCheckList from "./trelloCheckList";
import DueDate from "./dueDate";
import AddMember from "./addMember";
import CadIcon from "../../Assets/Icons/cad.png";
import SpinLoader from "./spinLoader";
import { CommentBox } from "./components";
import { markdownToHtml } from "./components/commentBox/parser";
import { BaseUrl } from "../../chatapp/apis/Networks";
import Attachments from "./Attachements/Attachments";
import CommentEditor from "./CommentEditor";
import ReactMarkdown from 'react-markdown';
import ProjectChat from "./ProjectChat";

const DetailContainer = styled.div``;
const { TextArea } = Input;

export default function TrelloDetails(props) {
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { trelloCardInfo, project, trelloCommentList, trelloAttachmentList } =
    bindActionCreators(actionCreators, dispatch);
  const [isEditTitle, setEditTitle] = useState(false);
  const [commentBox, setCommentBox] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditDescription, setEditDescription] = useState(false);
  const [iscommentToggle, setCommentToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalViewType, setModalViewType] = useState("");
  const [modalViewData, setModalViewData] = useState("");
  const [selectedMember, setSelectedMember] = useState("");
  const [commentLimit, setCommentLimit] = useState(20);
  const [commentPage, SetCommentPage] = useState(1);
  const [commentList, setCommentList] = useState([]);
  const { id } = useParams();
  const [trelloTitle, setTrelloTitle] = useState();
  const history = useHistory();
  const [scrollVisible, setScrollVisible] = useState(false);
        const [isHovered, setIsHovered] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    // console.log(scrolled)
    if (scrolled > 2200) {
      setScrollVisible(true);
    } else if (scrolled <= 2200) {
      setScrollVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);
  useEffect(() => {
    trelloCardInfo(id);
  }, []);
  useEffect(() => {
    if (state.trelloCardInfo?.cardInfo?.projectId) {
      project(state.trelloCardInfo?.cardInfo?.projectId);
    }
  }, [state.trelloCardInfo?.cardInfo?.projectId]);

  useEffect(() => {
    console.log(commentPage);
    console.log(state.project.project?.projectCode);

    trelloCommentList(id, commentLimit, commentPage);
  }, [commentPage]);

  const SyncTrello = () => {
    setLoading(true);
    console.log(id);
    API.syncTrello(id)
      .then((res) => {
        console.log(res);
        setLoading(false);
        toast.success("Project Data Sync Completed Sucessfully", {
          position: "top-right",
        });
        project(state.trelloCardInfo?.cardInfo?.projectId);
        trelloCardInfo(id);
        trelloCommentList(id, 20, 1);
        trelloAttachmentList(id, 10, "");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error! ", err, {
          position: "top-right",
        });
      });
  };
  const updateTrelloCardInfo = (type, inputdata) => {
    let data;
    console.log(inputdata);
    if (type == "desc") {
      console.log(inputdata);
      data = {
        id: id,
        description: inputdata,
      };
    } else {
      console.log(inputdata);
      data = {
        projectID: id,
        projectName: inputdata,
      };
      // data = {
      //   id: id,
      //   name: inputdata,
      // };
    }

    //  setLoad((prev)=>!prev)
    API.trelloCardUpdate(id, data)
      .then((res) => {
        //  setLoad((prev)=>!prev)
        toast.success("Trello details are updated successfully.", {
          position: "top-right",
        });
        trelloCardInfo(id);
        trelloCommentList(id, commentLimit, commentPage);
        setEditTitle(false);
        setEditDescription(false);
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        toast.error("Oops! Trello details are not updated.", {
          position: "top-right",
        });
      });
  };

  const RemoveMember = (memberID) => {
    console.log(commentPage);
    var data = {
      memberID: memberID,
    };
    API.trelloCardRemoveMember(id, data)
      .then((res) => {
        toast.success("Member Removed successfully.", {
          position: "top-right",
        });
        trelloCardInfo(id);
        trelloCommentList(id, 20, 1);
        setCommentToggle(false);
        setSelectedMember("");
      })
      .catch((err) => {
        toast.error("Oops! Member Remove Action Failed.", {
          position: "top-right",
        });
      });
  };

  const AddTrelloComment = (inputdata) => {
    let data = { comment: inputdata, type: "T" };
    console.log(inputdata);

    // setLoad((prev)=>!prev)
    API.addTrelloComment(id, data)
      .then((res) => {
        //setLoad((prev)=>!prev)
        // setLoad((prev)=>!prev)
        setCommentBox("");
        toast.success("Client details are updated successfully.", {
          position: "top-right",
        });
        trelloAttachmentList(id, 10, "");

        // myTextInput.current.clear();
        trelloCommentList(id, 20, 1);
        setCommentToggle(false);
      })
      .catch((err) => {
        // setLoad((prev)=>!prev)
        toast.error("Oops! Client details are not updated.", {
          position: "top-right",
        });
      });
  };
  useEffect(() => {
    if (state.trelloCommentList?.commentList !== "") {
      if (commentPage == 1) {
        setCommentList(
          state?.trelloCommentList?.commentList.map((item) => {
            if (item?.encoding == "markdown") {
              let comment = markdownToHtml(item.message);
              // console.log(comment)
              //  item.message = comment;
              // console.log({ ...item, message: comment });
              return { ...item, message: item.message };
            } else {
              return item;
            }
          })
        );
      } else {
        let comment = [
          ...commentList,
          ...state?.trelloCommentList?.commentList.map((item) => {
            if (item?.encoding == "markdown") {
              let comment = markdownToHtml(item.message);
              // console.log(comment)
              //  item.message = comment;
              console.log({ ...item, message: comment });
              return { ...item, message: item.message };
            } else {
              return item;
            }
          }),
        ];

        const uniqueIds = [];
        setCommentList(
          comment
            .filter((element) => {
              const isDuplicate = uniqueIds.includes(element._id);
              // console.log(element._id)
              if (!isDuplicate) {
                uniqueIds.push(element._id);

                return true;
              }

              return false;
            })
            .map((item) => item)
        );
      }

      // console.log(comment)
    }
  }, [state]);

  // return state.trelloCardInfo.loading &&
  //   state.trelloCardInfo.cardInfo == null ? (
  //   <Loader />
  // ) :
  return (
    <>
      <DetailContainer className="ap-flex ap-w100 ap-flex-center-center">
        <div className="ap-flex ap-w100 ap-flex-space-bw">
          <div className="ap-flex ap-w75 ap-flex-start ap-p10">
            <div style={{ padding: "0 10px" }}>
              {/* <TButton
                onClick={() => {
                  onOpen();
                  setModalViewType("Approval");
                }}
              >
                <Icons.CheckBox /> Send to Client
              </TButton> */}
            </div>
            {/* <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={() => {
                  onOpen();
                  setModalViewType("Payment");
                }}
              >
                <i class="fa fa-money" aria-hidden="true"></i> Trello Payment
              </TButton>
            </div> */}
            {/* <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={() => {
                  onOpen();
                  setModalViewType("Notification");
                }}
              >
                <Icons.Notifications /> Trello Notification
              </TButton>
            </div> */}

            <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={() => {
                  onOpen();
                  setModalViewType("MoveCard");
                }}
              >
                <Icons.MoveToInbox /> Move Card
              </TButton>
            </div>
            <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={() => {
                  onOpen();
                  setModalViewType("currentService");
                }}
              >
                <Icons.PlaylistAddCheck /> Current Service
              </TButton>
            </div>
            <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={() => {
                  if (state.trelloCardInfo?.cardInfo?.folderLink) {
                    window.open(
                      state.trelloCardInfo?.cardInfo?.folderLink,
                      "_blank"
                    );
                  } else {
                    toast.error("No Folder Found!", {
                      position: "top-right",
                    });
                  }
                }}
              >
                <Icons.Cloud /> Go To Nextcloud
              </TButton>
            </div>
            <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={()=>{
                   window.open(`/file-browse/${id}`, "_blank");
                  
                }}
              >
                <Icons.Cloud /> Browse File
              </TButton>
            </div>
            <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={() =>
                  history.push(
                    `/home/ap/project/details/${state.project?.project?.ID}/${state.project?.project?.customer?.ID}/${state.project?.project?.projectCode}`
                  )
                }
              >
                <Icons.Project /> Go To Project
              </TButton>
            </div>
            <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={() => {
                  onOpen();
                  setModalViewType("CollectFeedBack");
                }}
              >
                <Icons.MoveToInbox /> Collect FeedBack
              </TButton>
            </div>
            <div style={{ padding: "0 10px" }}>
              <TButton
                onClick={() => {
                  onOpen();
                  // setModalViewData({clientDetail:state?.project?.project?.projectId,adminDetail:state?.project?.project?.assignedTo})
                  setModalViewType("ChatOnWhatsapp");
                }}
              >
                <Icons.WhatsApp /> Chat On Whatsapp
              </TButton>
            </div>
            {/* <div style={{ padding: "0 10px" }}>
              <TrelloCheckList />
            </div> */}
          </div>
          {localStorage.getItem("istrelloList") == "true" ? (
            <div
              style={{
                padding: "0 10px",
                float: "right",
                color: "var(--primary-dark-background)",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push(
                  "/home/ap/trello/list?bardId=" +
                    localStorage?.getItem(`bardId`) +
                    "&listId=" +
                    localStorage?.getItem(`listId`) +
                    "&memberId=" +
                    localStorage?.getItem(`memberId`)
                );
              }}
            >
              Back to Design PMS
            </div>
          ) : (
            ""
          )}
        </div>

        <Card size="small" style={{ width: "100%", marginTop: "10px" }}>
          {state.trelloCardInfo.loading == false ? (
            <TCardHeding>
              {isEditTitle == false ? (
                <>
                  <h2>
                    <i
                      class="fa fa-address-card-o"
                      aria-hidden="true"
                      style={{ marginRight: 8, fontSize: "22px !important" }}
                    ></i>
                    {state.trelloCardInfo?.cardInfo?.projectName}
                    <i
                      onClick={() => {
                        setEditTitle(true);
                        setTrelloTitle(
                          state.trelloCardInfo?.cardInfo?.projectName
                        );
                      }}
                      style={{
                        marginLeft: 10,
                        color: " var( --primary-green)",
                      }}
                      className="fa fa-edit"
                    ></i>
                  </h2>

                  <p style={{ color: "grey", paddingTop: 10 }}>
                    <span
                      style={{ padding: "0 8px", cursor: "pointer" }}
                      onClick={() => {
                        history.push(
                          "/home/ap/trello/list?bardId=" +
                            state.trelloCardInfo?.cardInfo?.board?.ID
                        );
                      }}
                    >
                      In Board: {state.trelloCardInfo?.cardInfo?.board?.name}
                    </span>
                    <span
                      style={{ padding: "0 8px", cursor: "pointer" }}
                      onClick={() => {
                        history.push(
                          "/home/ap/trello/list?bardId=" +
                            state.trelloCardInfo?.cardInfo?.board?.ID +
                            "&listId=" +
                            state.trelloCardInfo?.cardInfo?.list?.ID
                        );
                      }}
                    >
                      In list: {state.trelloCardInfo?.cardInfo?.list?.name}
                    </span>
                  </p>

                  <hr />
                  <MemberDiv>
                    <div
                      style={{
                        margin: "4px 2px",
                        width: "100%",
                        display: "flex",
                        flexFlow: "wrap",
                      }}
                    >
                      {/* <h2>
                        <Box flex="1" textAlign="left">
                          Members <Icons.Users />
                        </Box>
                      </h2> */}
                      {state.trelloCardInfo?.cardInfo?.members && (
                        <Box display="flex">
                          {state.trelloCardInfo?.cardInfo?.members?.map(
                            (row, index) => (
                              <div
                                style={{
                                  padding: "10px 0",
                                  position: "relative",
                                }}
                                className="ap-flex p-1"
                                key={index}
                              >
                                <div className="avtar-div">
                                  <Tooltip title={row?.name}>
                                    <UserAvtar>
                                      <div className="member-initials">
                                        {row?.name?.slice(0, 2)}
                                      </div>
                                    </UserAvtar>
                                  </Tooltip>
                                </div>

                                <DeleteBtn
                                  className="delete-btn ap-flex-end ap-flex"
                                  onClick={() => {
                                    onDeleteOpen();
                                    setSelectedMember({
                                      id: row?.ID,
                                      name: row?.name,
                                    });
                                  }}
                                >
                                  <b>X</b>
                                </DeleteBtn>
                              </div>
                            )
                          )}
                        </Box>
                      )}
                      <div
                        style={{
                          padding: "10px 0",
                          position: "relative",
                        }}
                        className="ap-flex p-1"
                      >
                        <div className="avtar-div">
                          {/* <Icons.Users />{" "} */}
                          <Tooltip title={"Add Member"}>
                            <UserAvtar>
                              <div className="member-initials">
                                {/* <Icons.Users /> */}
                                <AddMember
                                  id={id}
                                  boardId={
                                    state.trelloCardInfo?.cardInfo?.idBoard
                                  }
                                  SetCommentPage={SetCommentPage}
                                />
                              </div>
                            </UserAvtar>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </MemberDiv>
                  <span
                    style={{
                      margin: "-22px 2px",
                      width: "100%",
                    }}
                  >
                    {/* <Accordion defaultIndex={[0]} allowToggle={false}>
                      <AccordionItem>
                        <h2>
                          <AccordionButton
                            style={{
                              backgroundColor:
                                "var( --primary-dark-background)",
                              color: "white",
                              //margin: "-18px 2px",
                              // width: window.innerWidth > 760 ? "14rem" : "10rem",
                            }}
                          >
                            <Box flex="1" textAlign="center">
                              Members <Icons.Users />
                            </Box>
                            <AddMember
                              id={id}
                              boardId={state.trelloCardInfo?.cardInfo?.idBoard}
                              SetCommentPage={SetCommentPage}
                            />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          {state.trelloCardInfo.loading == false ? (
                            <div
                              style={{
                                width: "100%",
                                float: "right",
                                padding: "0px 8px",
                                overflow: "auto",
                                right: "6%",
                                height: "280px",
                                background: "rgb(249 251 249)",
                                // zIndex: "1",
                              }}
                            >
                              {state.trelloCardInfo?.cardInfo?.members && (
                                <Box>
                                  {state.trelloCardInfo?.cardInfo?.members?.map(
                                    (row, index) => {
                                      return (
                                        <div
                                          style={{ padding: "10px 0" }}
                                          className="ap-flex ap-w100"
                                        >
                                          <UserAvtar>
                                            <>{row?.name?.slice(0, 2)}</>
                                          </UserAvtar>{" "}
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              color: "#000",
                                              padding: "12px 7px",
                                              width: "100%",
                                            }}
                                          >
                                            {row?.name}
                                          </p>
                                          <DeleteBtn
                                            className="ap-flex-end ap-flex "
                                            onClick={() => {
                                              onDeleteOpen();
                                              setSelectedMember({
                                                id: row?.ID,
                                                name: row?.name,
                                              });
                                            }}
                                          >
                                            <Icons.Delete />
                                          </DeleteBtn>
                                        </div>
                                      );
                                    }
                                  )}
                                </Box>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "15px 8px",
                                marginTop: "15px",
                                height: "280px",
                                background: "rgb(249 251 249)",
                              }}
                            >
                              <SpinLoader />
                            </div>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion> */}
                  </span>
                  {state.trelloCardInfo?.cardInfo?.trelloSyncDatetime ==
                    null && (
                    <Button
                      loading={loading}
                      type="primary"
                      className="ap-btn-c "
                      shape="round"
                      size="middle"
                      style={{
                        margin: "2px 0px",
                        background: "#38a169",
                        padding: "8px 10px",
                      }}
                      onClick={() => {
                        SetCommentPage(1);
                        SyncTrello();
                      }}
                    >
                      <span className="ap-fesm ap-fwmd ap-flex ap-flex-center-center">
                        Sync Trello
                      </span>
                    </Button>
                  )}
                </>
              ) : (
                <div>
                  <Input
                    placeholder="Edit Name"
                    defaultValue={trelloTitle}
                    id="cardtitle"
                    // onChange={(e)=>{cardtitle.current.value = e.target.value}}
                    style={{ width: window.innerWidth > 760 ? "75%" : "100%" }}
                  />
                  <div
                    className="ap-flex ap-w75 ap-flex-end "
                    style={{ padding: "10px 0" }}
                  >
                    <div style={{ padding: "0 5px" }}>
                      <Button
                        size="sm"
                        className="submit_right1"
                        onClick={() => {
                          updateTrelloCardInfo(
                            "title",
                            document.getElementById("cardtitle").value
                          );
                        }}
                      >
                        Save
                      </Button>
                    </div>
                    <div>
                      <Button
                        size="sm"
                        className="submit_right1"
                        onClick={() => {
                          setEditTitle(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <DueDate SetCommentPage={SetCommentPage} />
                <div
                  style={{
                    padding: "12px 20px",
                    fontWeight: "450",
                    marginTop: "22px",
                    fontSize: "16px",
                    color: "#4a5568",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>Current Service:</span>{" "}
                  {state?.trelloCardInfo?.cardInfo?.currentService || "N/A"}
                </div>
                {state?.project?.loading == true ? (
                  <>
                    <SpinLoader />
                  </>
                ) : (
                  <Card size="small" style={{ minWidth: "32%" }}>
                    <div className="ap-flex ap-w100 ap-flex-space-bw  ap-p5">
                      <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                        Project Created By
                      </div>
                      <div className="ap-flex ap-flex-center-center ap-fsm">
                        {state?.project?.project?.createdBy?.name}
                      </div>
                    </div>
                    <div className="ap-flex ap-w100 ap-flex-space-bw ap-p5">
                      <div className="ap-flex ap-flex-center-center ap-fsm ap-fwmd">
                        Project Assigned to
                      </div>
                      <div className="ap-flex ap-flex-center-center ap-fsm">
                        {state?.project?.project?.assignedTo?.name}
                      </div>
                    </div>
                  </Card>
                )}
              </div>
            </TCardHeding>
          ) : (
            <SpinLoader />
          )}
          <br />
          <hr
            className="ap-w100"
            style={{ display: window.innerWidth > 760 ? "block" : "none" }}
          />
          <div
            style={{
              display: window.innerWidth > 760 ? "flex" : "block",
              padding: "10px 0",
              justifyContent: "space-between",
            }}
          >
            {/* description */}
            <div
              style={{
                width: window.innerWidth > 760 ? "60%" : "95%",
                padding: "15px 6px",
                overflow: "auto",
              }}
            >
              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      style={{
                        backgroundColor: "var( --primary-dark-background)",
                        color: "white",
                      }}
                    >
                      <Box flex="1" textAlign="left">
                        Description
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={2}>
                    <DescriptionCont>
                      {/* <i
                        class="fa fa-outdent"
                        aria-hidden="true"
                        style={{ marginRight: 2, fontSize: "22px !important" }}
                      ></i>{" "}
                      <span style={{ padding: "0 8px" }}>Description</span> */}
                      {isEditDescription == false && (
                        <TButton
                          onClick={() => {
                            setEditDescription(true);
                          }}
                          style={{ fontWeight: "bold", float: "right" }}
                        >
                          {" "}
                          Edit{" "}
                        </TButton>
                      )}
                      {isEditDescription == true ? (
                        <>
                          <TextArea
                            rows={4}
                            defaultValue={
                              state.trelloCardInfo?.cardInfo?.description
                            }
                            style={{
                              width: window.innerWidth > 760 ? "60%" : "100%",
                            }}
                            // ref={cardtitle}
                            id="carddesc"
                            // onChange={(e)=>{cardtitle.current.value = e.target.value}}
                          />
                          <div
                            className="ap-flex ap-w60 ap-flex-end "
                            style={{ padding: "10px 0" }}
                          >
                            <div style={{ padding: "0 5px" }}>
                              <Button
                                size="sm"
                                className="submit_right1"
                                onClick={() => {
                                  updateTrelloCardInfo(
                                    "desc",
                                    document.getElementById("carddesc").value
                                  );
                                }}
                              >
                                Save
                              </Button>
                            </div>
                            <div>
                              <Button
                                size="sm"
                                className="submit_right1"
                                onClick={() => {
                                  setEditDescription(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <span style={{ fontWeight: 500 }}>
                          <br />
                          {state.trelloCardInfo.loading == false ? (
                            <pre style={{ fontSize: 16, marginTop: "-22px" }}>
                              {state.trelloCardInfo?.cardInfo?.description}
                            </pre>
                          ) : (
                            <SpinLoader />
                          )}
                        </span>
                      )}
                    </DescriptionCont>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              {/* description end*/}

              {/* checklist */}
              <div
                style={{
                  //  width: window.innerWidth > 760 ? "65%" : "95%",
                  padding: "15px 0px",
                  overflow: "auto",
                }}
              >
                <Accordion allowToggle defaultIndex={[0]}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        style={{
                          backgroundColor: "var( --primary-dark-background)",
                          color: "white",
                        }}
                      >
                        <Box flex="1" textAlign="left">
                          Project Checklist
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <TrelloCheckList SetCommentPage={SetCommentPage} />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            {/* members  */}
            <span
              style={{
                maxWidth: window.innerWidth > 760 ? "40%" : "100%",
                padding: "5px 0px",
                display: "flex",
                margin: "0 2px",
              }}
            >
              <ProjectChat isAllow={true} scrollToTop={scrollToTop} />
            </span>
          </div>
          <hr className="ap-w70" />
          {/* attachments */}
          <AttachmentCont className="ap-w100">
            <Attachments
              projectId={state.trelloCardInfo?.cardInfo?.projectId}
            />
          </AttachmentCont>
          {/* attachments ends */}
          <hr className="ap-w70" />
          {/* comments */}
          {/* <CommentBox
            members={state.trelloCardInfo?.cardInfo?.members ?? []}
            //  setCommentBox={setCommentBox}
            AddTrelloComment={AddTrelloComment}
          /> */}
          {state.trelloCardInfo?.loading ? (
            <div className="ap-w100 ap-flex ap-flex-center-center p-3">
              <Spin />
            </div>
          ) : (
            <CommentEditor
              mentionItems={state.trelloCardInfo?.cardInfo?.members ?? []}
              AddTrelloComment={AddTrelloComment}
            />
          )}
          <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            {commentList && (
              <div
                style={{
                  maxHeight: "auto",
                  minHeight: "120px",
                  overflow: "auto",
                }}
              >
                {commentList?.map((comment, index) => {
                  return (
                    <Comment
                      //   actions={actions}
                      author={
                        <span style={{ fontWeight: "700" }}>
                          {comment?.commentedBy?.name}{" "}
                        </span>
                      }
                      avatar={
                        <UserAvtar>
                          {comment?.commentedBy?.name.toString().slice(0, 2)}
                        </UserAvtar>
                      }
                      content={
                        <>
                          {comment?.encoding == "markdown" ? (
                            <CommentDiv style={{ padding: "0.5rem 1.5rem" }}>
                              {/* <p style={{ color: "gray" }} >{comment.message}</p> */}
                              {/* <div
                            dangerouslySetInnerHTML={{
                              __html: comment.message.replace(/\n/g, "<br />"),
                            }}
                          ></div> */}
                              <ReactMarkdown
                                linkTarget="_blank"
                                className="mark-up-to-html"
                              >
                                {comment?.message}
                              </ReactMarkdown>

                              <p>
                                {comment.attachments.map((a, index) => {
                                  return (
                                    <a href={a.filePath} target="_blank">
                                      {a.originalName || a.fileName}
                                    </a>
                                  );
                                })}
                              </p>
                            </CommentDiv>
                          ) : (
                            <CommentDiv>
                              {/* <p style={{ color: "gray" }} >{comment.message}</p> */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: comment.message.replace(
                                    /\n/g,
                                    "<br />"
                                  ),
                                }}
                              ></div>
                              <p>
                                {comment.attachments.map((a, index) => {
                                  return (
                                    <a href={a.filePath} target="_blank">
                                      {a.originalName || a.fileName}
                                    </a>
                                  );
                                })}
                              </p>
                            </CommentDiv>
                          )}
                        </>
                      }
                      // content={
                      //   <CommentDiv>
                      //     {/* <p style={{ color: "gray" }} >{comment.message}</p> */}
                      //     <div
                      //       dangerouslySetInnerHTML={{
                      //         __html: comment.message.replace(/\n/g, "<br />"),
                      //       }}
                      //     ></div>
                      //     <p>
                      //       {comment.attachments.map((a, index) => {
                      //         return (
                      //           <a href={a.filePath} target="_blank">
                      //             {a.originalName || a.fileName}
                      //           </a>
                      //         );
                      //       })}
                      //     </p>
                      //   </CommentDiv>
                      // }
                      datetime={
                        <Tooltip
                          title={moment
                            .utc(comment.datetime)
                            .local()
                            .format("MMM D, YYYY [at] h:mm A z")}
                        >
                          <span style={{ color: "#000" }}>
                            {moment
                              .utc(comment.datetime)
                              .local()
                              .format("MMM D, YYYY [at] h:mm A z")}
                          </span>
                        </Tooltip>
                      }
                    />
                  );
                })}
              </div>
            )}
          </div>
          {state?.trelloCommentList?.loading == true && <SpinLoader />}
          {commentList?.length < state?.trelloCommentList?.totalRecords && (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginBottom: "5%",
              }}
            >
              <TButton
                onClick={() => {
                  // setCommentLimit(commentList?.length + 5);
                  SetCommentPage(commentPage + 1);
                }}
              >
                View More
              </TButton>
            </div>
          )}
          {/* comments end */}
          <ScrollButton
            onClick={scrollToTop}
            style={{ display: scrollVisible ? "inline" : "none" }}
          >
            <Icons.ArrowUpward onClick={scrollToTop} />
          </ScrollButton>
        </Card>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick={modalViewType == "Attachments" ? false : true}
        >
          <ModalOverlay />
          <ModalContent
            maxW={
              modalViewType == "Attachments"
                ? "62rem"
                : modalViewType == "ChatOnWhatsapp"
                ? "26rem"
                : "56rem"
            }
            maxH="38rem"
            minH="15rem"
          >
            <TrelloModelViews
              onClose={() => {
                onClose();
                // console.log("close");
                // if (modalViewType == "Attachments") {
                //   trelloAttachmentList(id, 5, "");
                // }
              }}
              type={modalViewType}
              data={modalViewData}
              id={id}
              boardId={state.trelloCardInfo?.cardInfo?.board?.ID}
              projectId={state.trelloCardInfo?.cardInfo?.projectId}
              projectCode={state.trelloCardInfo?.cardInfo?.projectID}
              SetCommentPage={SetCommentPage}
              listId={state.trelloCardInfo?.cardInfo?.list?.ID}
            />
          </ModalContent>
        </Modal>
        <AlertDialog isOpen={isDeleteOpen} onClose={onDeleteClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Remove Member
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You want to remove {selectedMember?.name}!!
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onDeleteClose} size="sm">
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => {
                    SetCommentPage(1);
                    RemoveMember(selectedMember?.id);
                    onDeleteClose();
                  }}
                  ml={3}
                >
                  Remove
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </DetailContainer>
    </>
  );
}
