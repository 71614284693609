import { API } from "../../Services"
export const customOrders = (query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_CUSTOM_ORDERS",
        })
         API.customOrderList(query).then((res)=>{
            let data = JSON.parse(JSON.stringify(res))
            
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_CUSTOM_ORDERS",
                    payload:{data:data.data.customOrders ?data.data.customOrders:[],totalRecords:res.data.totalRecords},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_CUSTOM_ORDERS",
            })
        })
    }
}