import React, { useState } from "react";
import {
  SettingsDetailDiv,
  SettingsDiv,
  SettingsMenuDiv,
} from "./settingsStyle";

import { Layout, Menu, Button } from "antd";
import { Icons } from "../../Utils";
import CustomFieldsDetail from "./SettingMenuDetail/CustomFieldsDetail";
import LeadStatusDetail from "./SettingMenuDetail/LeadStatusDetail";
import LeadSourceDetail from "./SettingMenuDetail/LeadSourceDetail";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import { useEffect } from "react";
import axios from "axios";
const { Header, Sider, Content } = Layout;

function Settings() {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");
  const items = [
    { label: "Lead Source", key: "1" },
    { label: "Lead Status", key: "2" },
    { label: "Custom Fields", key: "3" },
  ];
       const state = useSelector((state) => state);
       const dispatch = useDispatch();
       const { crmCustomfieldList } = bindActionCreators(
         actionCreators,
         dispatch
       );
  const LeadSource = () => (
    <div>
      <LeadSourceDetail />
    </div>
  );

  useEffect(() => {
    if (selectedMenuItem == 3) 
    {
      crmCustomfieldList();
    }
  }, [selectedMenuItem]);
  
  const LeadStatus = () => (
    <div>
      <LeadStatusDetail />
    </div>
  );

  const CustomFields = () => (
    <div>
      <CustomFieldsDetail data={state.crmCustomfieldList.customFieldList} />
    </div>
  );
  const renderDetailPage = () => {
    switch (selectedMenuItem) {
      case "1":
        return <LeadSource />;
      case "2":
        return <LeadStatus />;
      case "3":
        return <CustomFields />;
      default:
        return null; // You can handle other cases as needed
    }
  };
  return (
    <div>
      <SettingsDiv>
        <SettingsMenuDiv>
          {" "}
          <h2>
            <Icons.Settings />
            Settings
          </h2>
          <Menu
            selectedKeys={[selectedMenuItem]}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={items}
            onClick={({ key }) => setSelectedMenuItem(key)}
          />
        </SettingsMenuDiv>
        <SettingsDetailDiv>{renderDetailPage()}</SettingsDetailDiv>
      </SettingsDiv>
    </div>
  );
}

export default Settings;
