import styled from "styled-components";


export const  DetailHeding = styled.h3`
font-size: 16px;
font-weight: 620;
min-height: 35px;
`;

export const  DetailHeading = styled.span`
font-weight: 600;
    font-size: 20px;
    padding: 10px 5px;
`;

export const  DetailContent = styled.div`
display: flex;
    flex-flow: column;
`;
export const  DetailItem = styled.div`
display: flex;
    justify-content: space-between;
        padding: 8px;`;

export const  Info = styled.div`
    display: flex;
    background: linear-gradient(to right, #bdffd4 , #048204);
    padding:2px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    //color: green;
    color: white;
    

width: 60%;
`;
export const  InfoIconDiv = styled.div`

width: 40%;
display: flex;
// background: linear-gradient(to right, #bdffd4 , #048204);
    padding: 10px;
    align-items: center;
    justify-content: center;
    svg{
        font-size: 35px;
    // color: white;
    color: green;

}
    }
`;

export const  ActionButtons = styled.div`
display: flex;
   
`;

export const  DLable = styled.b`
color:#606060;
display: inline-block;
padding-right: 8px;
font-weight: 600;
`;
export const  DValue = styled.span`
`;
export const  TButton = styled.button`
  background-color: var(--primary-dark-background)  !important;
  line-height: 20px;
  padding: 6px 12px !important;
  font-size: 14px !important;
  color: #fff !important;
  border-radius: 0 !important;
  border: none !important;
  text-align: left !important;
  &:hover{
    
        background-color: #56be54 !important;
    }
  svg {
    color: #fff;
    font-size: 18px;
    // padding-bottom: 2px;
  }
  
`;
export const  AddressDiv = styled.div`
display:flex;
flex-direction: column;
justify-content: space-between;
.primary-info{
    width: 48%;

}
.bill-row{
    display:flex;
    justify-content: space-between;

}


.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel] {
    padding: 4px 4.1rem;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    /* background-image: none; */
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    &:focus {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        border-right-width: 1px;
        outline: 0;
    }
}
.item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .item-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding:1rem 0;
    .item-field {
        flex: 0 0 25%;
        padding: 5px;
       
      }
     
  }
.item-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
  .item-row-price{
    display: flex;
    align-items: center;
    padding:1rem 0;
    flex-wrap: wrap;
    .item-field {
        flex: 0 0 11%;
        padding: 5px;
        label{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 3em;
          }
      }
  }
  
  .item-field label {
    display: block;
    font-weight: bold;
    font-size: 14px;
  }
  .react-tel-input .form-control {
   
    border-radius: 2px; 
    line-height: 25px;
    height: 32px;
    width: 100%;
}

.item-total{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
        .item-div{
            width:100%;
        display: flex;
        padding:5px;
        label{
            padding:0 5px;
            width:40%;
            text-align: end;
        }
        Input{
            width:40%;
        }
    }
    
}
`;
export const FormHeading = styled.span`
font-weight: 700;
    font-size: 18px;
    color: green;`