import React, { useState } from "react";
import { CustomFieldsDetailDiv, FormModalDiv } from "../settingsStyle";
import { Button, Col, Input, Modal, Radio, Row, Select, Space, Table, Tag } from "antd";
import { Icons } from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../Redux";
import { useEffect } from "react";

function CustomFieldsDetail() {
     const [isModalVisible, setIsModalVisible] = useState(false);
     const [formData, setFormData] = useState({});
  
    const data = [
      {
        key: "1",
        // name: "John Brown",
        Model: 32,
        placeholder: "Enter Name...",
        required: "Yes",
        type: "input",
        label: "Name",
        defaultValue: "Pooja Maurya",
      },
      {
        key: "2",
        // name: "Jim Green",
        Model: 42,
        placeholder: "Enter Fathers Name...",
        required: "No",
        type: "input",
        label: "Fathers name",
        defaultValue: "DeoKant Maurya",
      },
      {
        key: "3",
        // name: "Joe Black",
        Model: 32,
        placeholder: "Select Country...",
        required: "Yes",
        type: "dropdown",
        label: "Country",
        defaultValue: "India",
      },
    ];

    const columns = [
      {
        title: "label",
        dataIndex: "label",
        key: "label",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Model",
        dataIndex: "Model",
        key: "Model",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "Type",
      },
      {
        title: "Placeholder",
        dataIndex: "placeholder",
        key: "placeholder",
      },
      {
        title: "Placeholder",
        dataIndex: "placeholder",
        key: "placeholder",
      },
      {
        title: "Default Value",
        dataIndex: "defaultValue",
        key: "defaultValue",
      },
      {
        title: "required",
        dataIndex: "required",
        key: "required",
        render: (required) => (
          <>
            <Tag color={required == "Yes" ? "green" : "volcano"} key={required}>
              {required.toUpperCase()}
            </Tag>
          </>
        ),
      },
      {
        title: <div className="ap-fsm ap-fwmd">Action</div>,
        dataIndex: "id",
        key: "id",
        render: (text) => (
          <div className="ap-fsm">
            <Icons.Edit />
            <Icons.Delete />
          </div>
        ),
      },
    ];

   
    
const openModal = () => {
  setIsModalVisible(true);
};

const handleCancel = () => {
  setIsModalVisible(false);
};

const handleSave = () => {
  // Handle saving the form data here
  setIsModalVisible(false);
};
  return (
    <CustomFieldsDetailDiv>
      <Row justify="space-between">
        <Col>
          <h2>Custom Fields</h2>
        </Col>
        <Col>
          <Button type="primary" ghost onClick={openModal}>
            <Icons.Add /> Add Custom Field
          </Button>
        </Col>
      </Row>
      <Row className="py-4" justify="center">
        <Table columns={columns} dataSource={data} />
      </Row>
      <Modal
        title="Add Custom Field"
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
      >
        <FormModalDiv>
          {columns.map((column) => (
            <div key={column.key} className="p-2">
              {column.title}:{" "}
              {column.dataIndex === "type" ? (
                <Select
                  value={formData[column.dataIndex]}
                  onChange={(value) =>
                    setFormData({ ...formData, [column.dataIndex]: value })
                  }
                >
                  <Select.Option value="input">Input</Select.Option>
                  <Select.Option value="dropdown">Dropdown</Select.Option>
                </Select>
              ) : column.dataIndex === "required" ? ( // Render radio group for "required" field
                <Radio.Group
                  value={formData[column.dataIndex]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [column.dataIndex]: e.target.value,
                    })
                  }
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              ) : (
                <Input
                  value={formData[column.dataIndex]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [column.dataIndex]: e.target.value,
                    })
                  }
                />
              )}
            </div>
          ))}
        </FormModalDiv>
      </Modal>
    </CustomFieldsDetailDiv>
  );
}

export default CustomFieldsDetail;
