import { API } from "../../Services"

export const projectsList = (query) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PROJECTS",
        })
         API.projectsList(query).then((res)=>{
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PROJECTS",
                    payload:Array.isArray(res.data)?{totalRecords:0,projects:[]}:res.data,
                })
            },2000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_PROJECTS",
            })
        })
    }
}