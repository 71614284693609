import { API } from "../../Services";

export const partnersComissionWithdrawalList = (partnerId,query='') =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PARTNER_COMISSION_WITHDRAWAL_LIST",
        })
    
        API.partnersComissionWithdrawalList(partnerId,query).then((res)=>{
            console.log(res);
            let data = JSON.parse(JSON.stringify(res)).data?.withdrawalRequest
          
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PARTNER_COMISSION_WITHDRAWAL_LIST",
                    payload:{data:data,totalRecords:res.data.totalRecords},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_PARTNER_COMISSION_WITHDRAWAL_LIST",
            })
        })
    }
}

