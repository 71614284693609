const partnerDetailsSingleReducer = (state = {
    loading:false,
    details:null
}, action) => {
    switch (action.type) {
        case "FETCH_PARTNER":
            return { ...state ,loading:true,details:null}
        case "RECEIVE_PARTNER":
            return {...state, details:action.payload,loading:false,}
        case "ERROR_PARTNER":
            return { ...state ,details:null,loading:true}
        default:
            return { ...state }
    }
}

export default partnerDetailsSingleReducer;