import styled from "styled-components";



export const  AddressDiv = styled.div`
display:flex;
flex-direction: column;
justify-content: space-between;
.primary-info{
    width: 48%;

}
.bill-row{
    display:flex;
    justify-content: space-between;

}


.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel] {
    padding: 4px 4.1rem;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    /* background-image: none; */
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    &:focus {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        border-right-width: 1px;
        outline: 0;
    }
}
`;
export const FormHeading = styled.span`
font-weight: 700;
    font-size: 18px;
    color: green;`