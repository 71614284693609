
const partnersComissionWithdrawalListReducer = (state = {
    loading:false,
    comission:[],totalRecords:0
}, action) => {
    switch (action.type) {
        case "FETCH_PARTNER_COMISSION_WITHDRAWAL_LIST":
            return { ...state ,loading:true,comission:[],totalRecords:0}
        case "RECEIVE_PARTNER_COMISSION_WITHDRAWAL_LIST":
            return {...state, comission:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords}
        case "ERROR_PARTNER_COMISSION_WITHDRAWAL_LIST":
            return { ...state ,comission:[],loading:false,totalRecords:0}
        default:
            return { ...state }
    }
}

export default partnersComissionWithdrawalListReducer;