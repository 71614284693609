import { API } from "../../Services";

export const partnerDetailsSingle = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_PARTNER",
        })
         API.partnerDetailsSingle(id).then((res)=>{
                console.log(res)
            let data = JSON.parse(JSON.stringify(res))?.data
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_PARTNER",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_PARTNER",
            })
        })
   
}
}