 import { axiosInstance } from ".."



const updateTrelloCheckItem = async (checklistID,data) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.post(`/internal/pm/updateCheckItem/${checklistID}`,data).then((res) => { 
            console.log(res)
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
  }
  
  export default updateTrelloCheckItem;