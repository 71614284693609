
import { axiosInstance } from ".."

const trelloSearchCardList = async (memberId,boardId,listId,searchin,quarry,page,dueDate,orderby,order) =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`internal/projects/list?order=${order}&orderBy=${orderby}&memberID=${memberId}&listID=${listId}&boardID=${boardId}&query=${quarry}&searchin=${searchin}&page=${page}&dueDate=${dueDate||''}&limit=10`).then((res) => { 
            if(res.status === 200){
                console.log(res)
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default trelloSearchCardList;