import { API } from "../../Services";

export const trelloCommentList = (id,limit,page) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_TRELLO_COMMENTS",
        })
    
        API.trelloCommentList(id,limit,page).then((res)=>{
          //  console.log(page);
            let data = JSON.parse(JSON.stringify(res)).data.comments
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_TRELLO_COMMENTS",
                    payload:{data,totalRecords:res.data.totalRecords},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_TRELLO_COMMENTS",
            })
        })
    }
}