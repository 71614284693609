import { axiosInstance } from ".."

const trelloBoards = async () =>{

    return new Promise(async (resolve,reject)=>{
        axiosInstance.get(`/internal/Trello/boardList`).then((res) => { 
            console.log(res)
            if(res.status === 200){
                return resolve(res)
            }
            return resolve(res)
        }).catch((err) => {
            return reject(err)
        })
    })
}

export default trelloBoards;