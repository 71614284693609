import { API } from "../../Services";

export const trelloMembersList = (id) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_TRELLO_MEMBERS",
        })
    
        API.trelloMembersList(id).then((res)=>{
            console.log(res);
            let data = JSON.parse(JSON.stringify(res)).data
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_TRELLO_MEMBERS",
                    payload:data,
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_TRELLO_MEMBERS",
            })
        })
    }
}